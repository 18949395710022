import {
    CreateSearchMutation,
    CreateSearchOldMutation,
} from '@domains/clipboard/graphql/mutations/CreateSearchMutation';
import { compareSavedSearchValuesWithAttributes } from '@domains/search/components/SaveSearchResults/compareSavedSearchesValuesWithAttributes';
import { getFilteredLocations } from '@domains/search/components/SaveSearchResults/getFilteredLocations';
import { MapDrawingContext } from '@domains/search/contexts/MapDrawingContext';
import { CREATE_UNCONFIRMED_SEARCH_MUTATION } from '@domains/search/graphql/mutations/createUnconfirmedSearchMutation';
import { getFilterLocationsInput } from '@domains/search/helpers/getFilterLocationsInput';
import { isCreateSearchError } from '@domains/search/helpers/isCreateSearchError';
import type { CreateSearchInput, CreateSearchResponse } from '@domains/search/types/createSearchType';
import type { SearchCriteriaInput } from '@domains/search/types/searchCriteriaInput';
import type { SearchStatus } from '@domains/search/types/searchStatus';
import { SEARCH_STATUS } from '@domains/search/types/searchStatus';
import { SavedSearchesContext } from '@domains/shared/contexts/SavedSearchesContext/SavedSearchesContext';
import { SAVED_SEARCHES_TYPE_NAMES } from '@domains/shared/contexts/SavedSearchesContext/savedSearchesTypeName';
import { logError } from '@domains/shared/helpers/logger';
import { useUrlFilters } from '@domains/shared/hooks/useFilters/useUrlFilters';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { GetAllSearchesForUser } from '@type/favorites/allSearchesForUser';
import type { FieldsMetadataExperimentsVariants } from '@type/search/fieldsMetadataExperimentsVariants';
import { ESTATE } from '@type/search/filters/estate';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation } from 'urql';

interface UseSaveSearches {
    saveCurrentSearch: () => Promise<void>;
    saveUnconfirmedSearch: (email: string) => Promise<void>;
    searchStatus: SearchStatus;
    searchURL?: string;
}

type Toast = 'error' | 'info';

interface SaveSearchError {
    type: 'SAVE_SEARCH_ERROR';
    toast: Toast;
    message: string;
}

type SaveSearch = (input: SearchCriteriaInput) => Promise<void>;

const createSaveSearchError = (message: string, toast: Toast = 'error'): SaveSearchError => ({
    type: 'SAVE_SEARCH_ERROR',
    toast,
    message,
});

export const isSaveSearchError = (error: unknown): error is SaveSearchError => {
    return !!(error && (error as SaveSearchError).type === 'SAVE_SEARCH_ERROR');
};

export const validateSaveSearch =
    (t: Translator) =>
    ({
        filterAttributes,
        filterLocations,
        searchStatus,
    }: {
        filterAttributes: SearchCriteriaInput['filterAttributes'];
        filterLocations: SearchCriteriaInput['filterLocations'];
        searchStatus: SearchStatus;
    }): SaveSearchError | undefined => {
        if (filterLocations.byDomainId && filterLocations.byDomainId.length > 20) {
            return createSaveSearchError(t('frontend.search.save-search-results.too-many-locations'), 'info');
        }

        if (filterAttributes.estate === ESTATE.investment) {
            return createSaveSearchError(t('frontend.search.save-search-results.investment-saved'));
        }

        if (searchStatus !== SEARCH_STATUS.notSaved) {
            switch (searchStatus) {
                case SEARCH_STATUS.saving:
                case SEARCH_STATUS.saved: {
                    return createSaveSearchError(t('frontend.search.save-search-results.already-saved'));
                }
                default: {
                    return createSaveSearchError(t('frontend.search.save-search-results.create-search-error'));
                }
            }
        }
    };

const handleSaveError = (error: Error): Promise<void> => {
    logError('Error occurred during creation of saved search', { error });

    return Promise.reject(error);
};

export const useSaveSearches = ({
    fieldsMetadataExperimentsVariants,
}: {
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants;
}): UseSaveSearches => {
    const [t] = useTranslations();
    const {
        featureFlags: { isOldSaveSearchQueryEnabled },
    } = useSiteSettings();
    const { isFetchingSavedSearches, savedSearches, addToSavedSearches } = useContext(SavedSearchesContext);
    const { filterAttributes, locations, searchURL } = useUrlFilters({ fieldsMetadataExperimentsVariants });
    const [{ fetching }, createSavedSearch] = useMutation<CreateSearchResponse, CreateSearchInput>(
        CreateSearchMutation,
    );
    const [, createUnconfirmedSearch] = useMutation(CREATE_UNCONFIRMED_SEARCH_MUTATION);

    // Old save query mutation
    const [{ fetching: isFetchingOld }, createSavedSearchOld] = useMutation<CreateSearchResponse, CreateSearchInput>(
        CreateSearchOldMutation,
    );

    const [searchStatus, setSearchStatus] = useState<SearchStatus>(SEARCH_STATUS.blocked);
    const { customAreaPolygon, clearCustomAreaPolygon } = useContext(MapDrawingContext);

    const isAnyFetchingInProgress = isFetchingSavedSearches || fetching || isFetchingOld;

    useEffect(() => {
        if (!savedSearches || isAnyFetchingInProgress) {
            return;
        }

        const isSaved = compareSavedSearchValuesWithAttributes(
            savedSearches.map(({ content }) => content),
            filterAttributes,
            getFilteredLocations(locations),
            customAreaPolygon ? [{ byGeoJson: JSON.stringify(customAreaPolygon) }] : [],
        );

        setSearchStatus(isSaved ? SEARCH_STATUS.saved : SEARCH_STATUS.notSaved);
    }, [isAnyFetchingInProgress, filterAttributes, locations, savedSearches, customAreaPolygon]);

    // Due to deployment searchPage on storia old query for save searches must be used.
    // It should be deleted after adjusting notifications to storia as well.
    const saveSearchPromise = useCallback(
        (
            timestamp: number,
            filterAttributes: SearchCriteriaInput['filterAttributes'],
            filterLocations: SearchCriteriaInput['filterLocations'],
        ) => {
            setSearchStatus(SEARCH_STATUS.saving);

            if (isOldSaveSearchQueryEnabled) {
                return createSavedSearchOld(
                    {
                        filterAttributes: {
                            content: { filterAttributes, filterLocations },
                            timestamp,
                            isActive: true,
                        },
                    },
                    { additionalTypenames: SAVED_SEARCHES_TYPE_NAMES },
                );
            }

            const result = createSavedSearch(
                {
                    filterAttributes: {
                        timestamp,
                        content: { filterAttributes, filterLocations },
                        isActive: true,
                        frequencyType: 'INSTANT',
                        customName: customAreaPolygon
                            ? t('frontend.clipboard.map-save-search.default-title')
                            : undefined,
                    },
                },
                { additionalTypenames: SAVED_SEARCHES_TYPE_NAMES },
            );

            clearCustomAreaPolygon();

            return result;
        },
        [
            isOldSaveSearchQueryEnabled,
            createSavedSearch,
            customAreaPolygon,
            t,
            createSavedSearchOld,
            clearCustomAreaPolygon,
        ],
    );

    const saveSearch: SaveSearch = useCallback(
        (input) => {
            const { filterAttributes, filterLocations } = input;
            const timestamp = Date.now();
            const locationsWithGeometry = customAreaPolygon
                ? { byGeometry: [{ byGeoJson: JSON.stringify(customAreaPolygon) }] }
                : filterLocations;

            const validationError = validateSaveSearch(t)({
                filterAttributes,
                filterLocations: locationsWithGeometry,
                searchStatus,
            });

            if (validationError) {
                return Promise.reject(validationError);
            }

            return saveSearchPromise(timestamp, filterAttributes, locationsWithGeometry)
                .then((response) => {
                    const newSavedSearch: GetAllSearchesForUser = {
                        timestamp,
                        content: { filterAttributes, filterLocations: locationsWithGeometry },
                    };

                    if (isCreateSearchError(response.data)) {
                        if (response.data.createSearch.error === 'content is duplicated') {
                            /**
                             * We need to save the current set of filters in the context to block the button.
                             * The timestamp is required to match the type, but isn't actually valid. The proper
                             * value already exists in the backend.
                             */
                            addToSavedSearches(newSavedSearch);
                        }
                        throw new Error(response.data.createSearch.error);
                    }
                    if (response.error) {
                        throw new Error(response.error.message);
                    }

                    addToSavedSearches(newSavedSearch);
                })
                .catch(handleSaveError);
        },
        [addToSavedSearches, customAreaPolygon, saveSearchPromise, searchStatus, t],
    );

    const saveCurrentSearch: UseSaveSearches['saveCurrentSearch'] = () => {
        const filterLocations = getFilterLocationsInput(locations);

        return saveSearch({ filterAttributes, filterLocations });
    };

    const saveUnconfirmedSearch: UseSaveSearches['saveUnconfirmedSearch'] = (email) => {
        const filterLocations = getFilterLocationsInput(locations);

        return createUnconfirmedSearch(
            {
                search: {
                    content: { filterAttributes, filterLocations },
                    email,
                    timestamp: Date.now(),
                    isActive: true,
                    frequencyType: 'INSTANT',
                },
            },
            { additionalTypenames: SAVED_SEARCHES_TYPE_NAMES },
        ).then((response) => {
            if (response.error || isCreateSearchError(response.data)) {
                throw new Error(response.error?.message);
            }
        });
    };

    return {
        saveUnconfirmedSearch,
        saveCurrentSearch,
        searchStatus: isFetchingSavedSearches ? SEARCH_STATUS.blocked : searchStatus,
        searchURL,
    };
};
