import { AddToFavorites } from '@domains/shared/components/AdvertItem/AddToFavorites/AddToFavorites';
import { useAddToFavorites } from '@domains/shared/hooks/useAddToFavorites';
import { InteractiveIsland } from '@widgets/AdvertCard/Navigation';
import type { JSX } from 'react';

type Props = Parameters<typeof useAddToFavorites>[0] & {
    shouldUseNexusTheme: boolean;
};

/**
 * Subcomponent to limit re-renders in parent `AdvertCard` component.
 * `useAddToFavorites` is really problematic.
 */
export const AddToFavoritesWrapper = ({ shouldUseNexusTheme, ...hookProps }: Props): JSX.Element => {
    const { addToFavoritesProps } = useAddToFavorites(hookProps);

    return (
        <InteractiveIsland>
            <AddToFavorites shouldUseNexusTheme={shouldUseNexusTheme} {...addToFavoritesProps} />
        </InteractiveIsland>
    );
};
