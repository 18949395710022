import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const StyledPicture = styled.picture`
    width: 100%;
`;

export const MapImage = styled.img`
    display: block;
    width: 100%;
    border-radius: ${theme.radii[4]};
    object-fit: cover;
    aspect-ratio: 328 / 112;

    @media ${theme.mediaQueries.mdMin} {
        aspect-ratio: 254 / 87;
    }

    @media ${theme.mediaQueries.lgMin} {
        aspect-ratio: 296 / 312;
    }
`;
