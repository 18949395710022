import type { AdvertOwner } from '@type/ad/attributes/legacyAdvertOwner';
import type { AdContactDetails } from '@type/contactForm/advertContactDetails';

export const getAdContactDetails = (owner: AdvertOwner, agencyName = ''): AdContactDetails => {
    const { imageUrl, name, phones, type } = owner;

    if (!name) {
        return { name: agencyName, type, phones, imageUrl };
    }

    return { name, type, phones, imageUrl };
};
