import { SEARCH_CONFIG } from '@config/search/searchConfig';
import { InvestmentsWidget } from '@domains/search/components/InvestmentsWidget/InvestmentsWidget';
import { Listing } from '@domains/search/components/Listing/Listing';
import { ListingPanel } from '@domains/search/components/ListingPanel/ListingPanel';
import { ConnectedMapViewButton } from '@domains/search/components/MapViewButton/ConnectedMapViewButton';
import { LazyPagination } from '@domains/search/components/Pagination/LazyPagination';
import { RedirectedFromInactiveAdMessage } from '@domains/search/components/RedirectedFromInactiveAdMessage/RedirectedFromInactiveAdMessage';
import { SaveSearchPrompt } from '@domains/search/components/SaveSearchPrompt/SaveSearchPrompt';
import { EXPERIMENT } from '@domains/search/consts/experiment';
import { useDisplayNearbyAdverts } from '@domains/search/hooks/useDisplayNearbyAdverts';
import { useIsSaveSearchButtonVisible } from '@domains/search/hooks/useIsSaveSearchButtonVisible';
import type { Search } from '@domains/search/types/search';
import { AdvertSlot } from '@domains/shared/components/Advertising/AdvertSlot';
import { useBaxterAdvertising } from '@domains/shared/components/Advertising/useBaxterAdvertising';
import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { NoSearchResults } from '@domains/shared/components/NoSearchResults/NoSearchResults';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { SearchFormValuesContext } from '@domains/shared/contexts/SearchFormValuesContext';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useExperiments } from '@lib/experiments/client/ExperimentsProvider';
import type { SetSimilarAdsPromptId } from '@type/favorites/similarAdsPromptId';
import type { FieldsMetadataExperimentsVariants } from '@type/search/fieldsMetadataExperimentsVariants';
import type { Estate } from '@type/search/filters/estate';
import type { SearchingFilters } from '@type/search/searchingFilters';
import type { SortingOption } from '@type/sorting/option';
import { buildSearchUrl } from '@widgets/search/buildSearchUrl';
import dynamic from 'next/dynamic';
import type { JSX, RefObject } from 'react';
import { useContext, useEffect, useState } from 'react';

import {
    Container,
    Content,
    Link,
    ListingPanelContainer,
    MobileInvestmentsWrapper,
    RedirectedFromInactiveAdMessageContainer,
    Sidebar,
    StickyMapButtonContainer,
} from './ListingView.theme';

const LazyListingWithNearbyAdverts = dynamic(
    () => import('@domains/search/components/ListingWithNearbyAdverts/ListingWithNearbyAdverts'),
    {
        ssr: false,
    },
);

const { shouldShowSaveSearchPrompt } = SEARCH_CONFIG;

interface Props {
    data: Search;
    estate: Estate;
    sortingOption: SortingOption;
    pageTitle: string;
    pageHeading: string | null;
    saveSearchPromptRef: RefObject<HTMLDivElement>;
    filterAttributes: Partial<Omit<SearchingFilters, 'locations' | 'page' | 'limit'>>;
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants;
    mapImages: string[];
    setSimilarAdsPromptId?: SetSimilarAdsPromptId;
}

export const ListingView = ({
    data,
    estate,
    sortingOption,
    pageTitle,
    pageHeading,
    saveSearchPromptRef,
    filterAttributes,
    fieldsMetadataExperimentsVariants,
    mapImages,
    setSimilarAdsPromptId,
}: Props): JSX.Element => {
    const { searchAds, searchAdsRandomPromoted, searchAdsRandomInvestments } = data || {};
    const { items = [], pagination, recommendationLink } = searchAds || {};
    const { totalResults, totalPages } = pagination || {};
    const promotedItems = searchAdsRandomPromoted?.items || [];

    const [t] = useTranslations();
    const { lang } = useSiteSettings();
    const { isDesktop } = useContext(RWDContext);
    const { isVariantEnabled } = useExperiments();

    const { filteringQueryParams } = useContext(SearchFormValuesContext);
    const [isRedirectedFromInactiveAd, setIsRedirectedFromInactiveAd] = useState(false);
    const isSaveSearchButtonVisible = useIsSaveSearchButtonVisible();
    useBaxterAdvertising();

    const { shouldDisplayNearbyAdverts, initialSearchArea, locationDomainIdsToExclude } =
        useDisplayNearbyAdverts(searchAds);

    const shouldStickyMapButton = isVariantEnabled(EXPERIMENT.eure27419, 'b');

    useEffect(() => {
        if (window?.location.hash.includes('#from404')) {
            setIsRedirectedFromInactiveAd(true);
        }
    }, []);

    const noResultsLink =
        items.length === 0 && recommendationLink
            ? buildSearchUrl({
                  lang,
                  transaction: recommendationLink.transaction,
                  estate: recommendationLink.estate,
                  location: recommendationLink.location,
              })
            : null;

    const newSearchLink = noResultsLink ? (
        <LocalLink href={noResultsLink} passHref>
            <Link>{t('frontend.search.no-results.other-ads')}</Link>
        </LocalLink>
    ) : null;

    return (
        <Container>
            {isRedirectedFromInactiveAd ? (
                <RedirectedFromInactiveAdMessageContainer>
                    <RedirectedFromInactiveAdMessage />
                </RedirectedFromInactiveAdMessageContainer>
            ) : null}

            <Content>
                <ListingPanelContainer>
                    <ListingPanel
                        totalResults={totalResults}
                        pageHeading={pageHeading}
                        sortingOption={sortingOption}
                        mapImages={mapImages}
                    />
                </ListingPanelContainer>

                {items.length === 0 ? (
                    <NoSearchResults clickableElement={newSearchLink} data-cy="no-search-results" />
                ) : null}

                {promotedItems.length > 0 ? (
                    <Listing
                        title="frontend.search.listing.promoted-title"
                        items={promotedItems}
                        isPromotedSection
                        shouldDisableTopBorder
                        shouldRenderGoToPromotedLink
                        cyContainer="search.listing.promoted"
                        cyTitle="search.listing.promoted.title"
                        setSimilarAdsPromptId={setSimilarAdsPromptId}
                    />
                ) : null}

                {items.length > 0 ? (
                    <Listing
                        title={filteringQueryParams?.isPromoted ? 'frontend.search.listing.promoted-title' : undefined}
                        items={items}
                        shouldDisableTopBorder={!!isDesktop}
                        shouldRenderAds
                        cyContainer="search.listing.organic"
                        cyTitle={filteringQueryParams?.isPromoted ? 'search.listing.promoted.title' : undefined}
                        setSimilarAdsPromptId={setSimilarAdsPromptId}
                    />
                ) : null}

                {shouldDisplayNearbyAdverts && !!initialSearchArea ? (
                    <LazyListingWithNearbyAdverts
                        pageHeading={pageHeading}
                        initialSearchArea={initialSearchArea}
                        estate={estate}
                        filterAttributes={filterAttributes}
                        sortingOption={sortingOption}
                        locationDomainIdsToExclude={locationDomainIdsToExclude}
                    />
                ) : null}

                <div role="navigation" data-cy="search-list-pagination">
                    {shouldShowSaveSearchPrompt && isSaveSearchButtonVisible ? (
                        <SaveSearchPrompt
                            fieldsMetadataExperimentsVariants={fieldsMetadataExperimentsVariants}
                            saveSearchPromptRef={saveSearchPromptRef}
                            pageTitle={pageTitle}
                            sortingOption={sortingOption}
                        />
                    ) : null}
                    <LazyPagination numberOfPages={totalPages} />
                </div>
            </Content>

            {isDesktop ? (
                <Sidebar role="complementary">
                    {shouldStickyMapButton ? (
                        <StickyMapButtonContainer data-testid="search-page-sidebar-sticky-map-container">
                            <ConnectedMapViewButton images={mapImages} />
                        </StickyMapButtonContainer>
                    ) : (
                        <ConnectedMapViewButton images={mapImages} />
                    )}

                    {searchAdsRandomInvestments?.items?.length ? (
                        <InvestmentsWidget items={searchAdsRandomInvestments.items} />
                    ) : null}

                    <AdvertSlot id="baxter-l-right-first" />
                    <AdvertSlot id="baxter-l-right-second" />
                    <AdvertSlot id="baxter-l-right-third" />
                </Sidebar>
            ) : null}

            {!isDesktop && searchAdsRandomInvestments?.items?.length ? (
                <MobileInvestmentsWrapper>
                    <InvestmentsWidget items={searchAdsRandomInvestments.items} startIndex={items?.length} />
                </MobileInvestmentsWrapper>
            ) : null}
        </Container>
    );
};
