import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';

import { StyledImage } from '../GallerySection.theme';

export const ImageSlide = ({
    advertLink,
    imageUrl,
    trackingSet,
}: {
    advertLink: string;
    imageUrl: string;
    trackingSet: Record<string, unknown>;
}): JSX.Element => {
    const { trackEvent } = useTracking();

    const handleOnClick = (): void => {
        trackEvent('ad_click', {
            ...trackingSet,
        });
    };

    return (
        <LocalLink href={advertLink} passHref prefetch={false}>
            <a onClick={handleOnClick}>
                <StyledImage alt="" src={imageUrl} data-cy="listing-item-image-source" />
            </a>
        </LocalLink>
    );
};
