import { getIdsFromQueryAndMapIdsByParentId } from '@domains/search/helpers/getIdsFromQueryAndMapIdsByParentId';
import { logWarn } from '@domains/shared/helpers/logger';
import type { GetAllSearchesForUser } from '@type/favorites/allSearchesForUser';
import type { FilterLocation } from '@type/location/filterLocation';
import { LOCATION_TYPE } from '@type/search/locationType';
import type { Client } from 'urql';

interface MappedIds {
    id: string;
    originalId?: string;
    urlPath?: string;
}

export const getSavedSearchesWithNewLocations = async (
    savedSearchesWithSphereLocations: GetAllSearchesForUser[],
    urqlClient: Client,
): Promise<GetAllSearchesForUser[]> => {
    const domainIdExistsOnFirstItemLength =
        savedSearchesWithSphereLocations[0]?.content?.filterLocations?.byDomainId?.length;
    if (domainIdExistsOnFirstItemLength && domainIdExistsOnFirstItemLength > 0) {
        // We have saved searches domain location, do nothing
        return savedSearchesWithSphereLocations;
    }
    const inputIds: string[] = getFilterLocationsByGeoAttributes(savedSearchesWithSphereLocations).flatMap(
        (locationArray) => locationArray?.map((location) => location.id || ''),
    );

    if (inputIds.length === 0) {
        return getOutputSearches(savedSearchesWithSphereLocations, null);
    }

    const mappedSphereLocationToDomain = await getIdsFromQueryAndMapIdsByParentId({
        clientQuery: urqlClient.query,
        ids: inputIds,
        source: LOCATION_TYPE.sphere,
        target: LOCATION_TYPE.domainId,
        includeOriginalIds: true,
        trigger: 'savedSearch',
    });

    if (mappedSphereLocationToDomain === null) {
        logWarn('[getSavedSearchesWithNewLocations] Failed to map Sphere locations to domain');

        return getOutputSearches(savedSearchesWithSphereLocations, null);
    }

    const outputIds = getMappedAndUnmappedLocations(inputIds, mappedSphereLocationToDomain);

    return getOutputSearches(savedSearchesWithSphereLocations, outputIds);
};

const getFilterLocationsByGeoAttributes = (
    savedSearchesWithSphereLocations: GetAllSearchesForUser[],
): Partial<FilterLocation>[][] =>
    savedSearchesWithSphereLocations.map((savedSearch: GetAllSearchesForUser) => {
        if (savedSearch.content.filterLocations?.byDomainId?.length) {
            // Avoid unnecessary calls by geo attribute when domain id is known
            return [];
        }

        return savedSearch.content.filterLocations?.byGeoAttributes || [];
    });

/**
 * iterate through array of old location arrays in order to re-create
 * the original multiple locations structure but with domain IDs
 *
 * it's like the opposite of the flat() function
 */
const getStructuredLocationsArray = (
    filteredLocations: Partial<FilterLocation>[][],
    outputIds: string[],
): { domainId: string }[][] => {
    let mappedIdsIndex = 0;

    return filteredLocations.map((locationsArray) => {
        if (locationsArray) {
            return locationsArray
                .map(() => {
                    mappedIdsIndex++;

                    return {
                        domainId: outputIds[mappedIdsIndex - 1],
                    };
                })
                .filter((location) => location.domainId !== '');
        }

        return [];
    });
};

const getOutputSearches = (
    savedSearchesWithSphereLocations: GetAllSearchesForUser[],
    outputIds: string[] | null,
): GetAllSearchesForUser[] => {
    const newLocationsArray =
        outputIds === null
            ? /**
               * all byDomainId keys should be empty arrays if:
               * 1) GQL error occurs or
               * 2) there are no specified locations
               */
              savedSearchesWithSphereLocations.map(() => [])
            : getStructuredLocationsArray(
                  getFilterLocationsByGeoAttributes(savedSearchesWithSphereLocations),
                  outputIds,
              );

    return savedSearchesWithSphereLocations.map((savedSearch, index) => {
        return {
            ...savedSearch,
            content: {
                ...savedSearch.content,
                filterLocations: {
                    byDomainId: newLocationsArray[index],
                    byGeometry: savedSearch.content.filterLocations.byGeometry || [],
                },
            },
        };
    });
};

// This is gonna return an array of corresponding domain IDs.
const getMappedAndUnmappedLocations = (inputIds: string[], mappedLocations: MappedIds[]): string[] => {
    const mappedOriginalIds = new Set(mappedLocations.map((location) => location.originalId));

    return inputIds.map((inputId) => {
        if (mappedOriginalIds.has(inputId)) {
            for (const mappedLocation of mappedLocations) {
                if (mappedLocation.originalId === inputId) {
                    return mappedLocation.id;
                }
            }
        }

        logWarn("[getSavedSearchesWithNewLocations] One of the location IDs couldn't be mapped by backend", {
            inputId,
        });

        // Return an empty string for any location ID that couldn't be translated by backend.
        return '';
    });
};
