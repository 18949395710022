import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const DesktopContentWrapper = styled.div`
    display: none;

    @media ${theme.mediaQueries.lgMin} {
        display: block;
    }
`;
