import type { ParsedUrlQuery } from 'node:querystring';

import { getFilterAttributes } from '@domains/search/components/SaveSearchResults/getFilterAttributes';
import { getSearchCriteria } from '@domains/search/helpers/getSearchCriteria';
import { SearchFormValuesContext } from '@domains/shared/contexts/SearchFormValuesContext';
import { logError } from '@domains/shared/helpers/logger';
import type { UseFilters } from '@domains/shared/hooks/useFilters/useFilters';
import type { FieldsMetadataExperimentsVariants } from '@type/search/fieldsMetadataExperimentsVariants';
import type { Estate } from '@type/search/filters/estate';
import type { RoomsNumber } from '@type/search/filters/roomsNumber';
import type { Transaction } from '@type/search/filters/transaction';
import type { SearchingFilters } from '@type/search/searchingFilters';
import { parseFilteringQueryParams } from '@widgets/search/parseFilteringQueryParams';
import { parseQueryParamsToSearchingFilters } from '@widgets/search/parseQueryParamsToSearchingFilters';
import { useRouter } from 'next/router';
import { useContext, useMemo } from 'react';

const parseFiltersFromUrl = (
    query: ParsedUrlQuery,
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants,
): {
    filterAttributes: Partial<SearchingFilters>;
    locations: SearchingFilters['locations'];
    roomsNumber: RoomsNumber[];
} => {
    const { estate, transaction, roomsNumber } = getSearchCriteria({
        urlSegments: query?.searchingCriteria as string[],
        fieldsMetadataExperimentsVariants,
    });

    const { locations, ...values } = parseQueryParamsToSearchingFilters({
        fieldsMetadataExperimentsVariants,
        queryParams: query,
        paramsScope: estate && transaction ? { estate, transaction } : undefined,
    });

    const filterAttributes = getFilterAttributes(
        parseFilteringQueryParams(values, estate as Estate, transaction as Transaction),
    );

    return {
        filterAttributes,
        locations,
        roomsNumber,
    };
};

const FALLBACK_EMPTY_FILTERS: ReturnType<UseFilters> = {
    filterAttributes: {},
    locations: [],
    searchURL: undefined,
};

const DEFAULT_ROOMS_NUMBER: RoomsNumber[] = [];

export const useUrlFilters: UseFilters = ({ fieldsMetadataExperimentsVariants }) => {
    const { query } = useRouter();

    const searchFormValues = useContext(SearchFormValuesContext);

    const { filterAttributes, locations } = useMemo(() => {
        try {
            const { filterAttributes, locations, roomsNumber } = parseFiltersFromUrl(
                query,
                fieldsMetadataExperimentsVariants,
            );

            const domainIds = searchFormValues?.locationsObjects?.map(({ id }) => id);
            const roomsNumberFromFilterAttributes = filterAttributes.roomsNumber || DEFAULT_ROOMS_NUMBER;

            return {
                filterAttributes: {
                    ...filterAttributes,
                    roomsNumber: [...roomsNumberFromFilterAttributes, ...roomsNumber],
                    market: searchFormValues?.market || 'ALL', // correct market is saved in context
                },
                locations: locations || domainIds || FALLBACK_EMPTY_FILTERS.locations,
                roomsNumber,
            };
        } catch (error) {
            logError('Error when parsing filters from URL', { error });

            return FALLBACK_EMPTY_FILTERS;
        }
    }, [query, searchFormValues, fieldsMetadataExperimentsVariants]);

    return {
        filterAttributes,
        locations,
        searchURL: typeof window === 'undefined' ? '' : window.location.href,
    };
};
