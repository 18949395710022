import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';

export const Title = styled.span`
    display: block;
    margin: 0 0 24px;
    color: ${(props): string => props.theme.deprecated.atoms.heading.h2.color};
    font-size: ${SIZE.p1};
    font-weight: ${WEIGHT.bold};
    line-height: 1.1;

    @media (min-width: ${BREAKPOINT.md}) {
        font-size: ${SIZE.x5}px;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
`;
