import { AddToFavoritesWrapper } from '@domains/search/components/AddToFavoritesWrapper/AddToFavoritesWrapper';
import { EXPERIMENT } from '@domains/search/consts/experiment';
import type { AdvertListItem } from '@domains/search/types/advertListItem';
import { Desktop } from '@domains/shared/components/Responsive/Desktop';
import { Mobile } from '@domains/shared/components/Responsive/Mobile';
import { useExperiments } from '@lib/experiments/client/ExperimentsProvider';
import type { SetSimilarAdsPromptId } from '@type/favorites/similarAdsPromptId';
import { Address } from '@widgets/AdvertCard/Address';
import { Anchor } from '@widgets/AdvertCard/Navigation';
import { SpecsList } from '@widgets/AdvertCard/SpecsList';
import { Title } from '@widgets/AdvertCard/Title';
import { CompareAdsButton } from '@widgets/CompareAds';
import type { JSX } from 'react';

import { ConnectedPrice } from '../components/ConnectedPrice/ConnectedPrice';
import { Description } from '../components/Description/Description';
import { SellerInfoWrapper } from '../components/SellerInfoWrapper/SellerInfoWrapper';
import { extractLocationDetails } from '../helpers/extractLocationDetails';
import {
    ActionButtonsWrapper,
    DescriptionWrapper,
    Header,
    MobileCompareAdsButtonWrapper,
    SecondaryInfoSection,
} from './PriceFirstInfoSection.theme';

export const PriceFirstInfoSection = ({
    item,
    isNearbySection = false,
    fullAdvertLink,
    setSimilarAdsPromptId,
    fullTrackingSet,
    isPromotedSection,
}: {
    item: AdvertListItem;
    isNearbySection?: boolean;
    fullAdvertLink: string;
    setSimilarAdsPromptId?: SetSimilarAdsPromptId;
    fullTrackingSet: Record<string, unknown>;
    isPromotedSection: boolean;
}): JSX.Element => {
    const { address, radius, locations } = extractLocationDetails(item.location);
    const isForRent = item.transaction === 'RENT';

    const { isVariantEnabled } = useExperiments();
    const isEURE25548VariantB = isVariantEnabled(EXPERIMENT.eure25548, 'b');

    return (
        <>
            <Header data-testid="listing-item-header" isCompareAdsButtonVisible={isEURE25548VariantB}>
                <ConnectedPrice item={item} />
                <ActionButtonsWrapper>
                    {isEURE25548VariantB ? (
                        <Desktop>
                            <CompareAdsButton />
                        </Desktop>
                    ) : null}
                    <AddToFavoritesWrapper
                        id={item.id}
                        isPromoted={!!item.isPromoted}
                        isNearbySection={isNearbySection}
                        shouldUseNexusTheme
                        setSimilarAdsPromptId={setSimilarAdsPromptId}
                    />
                </ActionButtonsWrapper>
            </Header>

            <Anchor href={fullAdvertLink} data-cy="listing-item-link" data-testid="listing-item-link">
                <Title data-cy="listing-item-title">{item.title}</Title>
            </Anchor>

            <SecondaryInfoSection>
                <Address radius={radius} street={address.street} locations={locations} />
            </SecondaryInfoSection>

            <SpecsList
                rooms={item.roomsNumber}
                area={item.areaInSquareMeters}
                floor={item.floorNumber}
                pricePerMeter={isForRent ? null : item.pricePerSquareMeter}
            />

            {item.shortDescription && item.shortDescription.length > 0 ? (
                <DescriptionWrapper>
                    <Description description={item.shortDescription} adId={item.id} />
                </DescriptionWrapper>
            ) : null}

            {isEURE25548VariantB ? (
                <Mobile>
                    <MobileCompareAdsButtonWrapper>
                        <CompareAdsButton />
                    </MobileCompareAdsButtonWrapper>
                </Mobile>
            ) : null}

            <SellerInfoWrapper
                advertLink={fullAdvertLink}
                agency={item.agency}
                pushedUpAt={item.pushedUpAt}
                trackingSet={fullTrackingSet}
                isNearbySection={isNearbySection}
                isPrivateOwner={item.isPrivateOwner}
                isPromotedSection={isPromotedSection}
            />
        </>
    );
};
