import type { JSX } from 'react';

import { ImageLoadingSlide } from '../components/ImageLoadingSlide';
import { ImageSlide } from '../components/ImageSlide';

export const createSlidesJsxElements = (
    bufferedImageUrls: (string | number)[],
    advertLink: string,
    trackingSet: Record<string, unknown>,
): JSX.Element[] => {
    return bufferedImageUrls.map((urlOrNumber) => {
        if (typeof urlOrNumber === 'number') {
            return <ImageLoadingSlide key={urlOrNumber} />;
        }

        return (
            <ImageSlide key={urlOrNumber} advertLink={advertLink} imageUrl={urlOrNumber} trackingSet={trackingSet} />
        );
    });
};
