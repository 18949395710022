import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const HighlightedAdvertLink = styled.a`
    display: flex;
    align-items: center;
    height: ${theme.sizes[40]};
    padding-inline: ${theme.space[24]};
    border-radius: ${theme.radii[4]};
    background-color: ${theme.colors['background-brand-primary']};
    color: ${theme.colors['text-global-inverse']};
    font-size: ${theme.fontSizes.p3};
    font-weight: ${theme.fontWeights.bold};
    text-decoration: none;
`;
