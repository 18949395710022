import { SITE_CONFIG } from '@config/siteConfig';
import { getDisplayedAdsIndexRange } from '@domains/search/helpers/getDisplayedAdsIndexRange';
import { AdvertSlot } from '@domains/shared/components/Advertising/AdvertSlot';
import { useBaxterAdvertising } from '@domains/shared/components/Advertising/useBaxterAdvertising';
import { NexusListingSorting } from '@domains/shared/components/ListingSorting/NexusListingSorting';
import { Desktop } from '@domains/shared/components/Responsive/Desktop';
import { Mobile } from '@domains/shared/components/Responsive/Mobile';
import { usePaginationParameters } from '@domains/shared/hooks/usePaginationParameters/usePaginationParameters';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { SortingOption } from '@type/sorting/option';
import type { JSX } from 'react';

import { ConnectedMapViewButton } from '../MapViewButton/ConnectedMapViewButton';
import {
    AdditionalInfoWrapper,
    AdsCountInfo,
    Heading,
    InfoContainer,
    ListingInfo,
    ListingTextInfoWrapper,
    SortByWrapper,
    SortingInfoLink,
    SortLabel,
    Wrapper,
} from './ListingPanel.theme';

interface Props {
    pageHeading: string | null;
    totalResults: number;
    sortingOption: SortingOption;
    mapImages: string[];
}

export const ListingPanel = ({ pageHeading, totalResults, sortingOption, mapImages }: Props): JSX.Element => {
    const [t] = useTranslations();
    useBaxterAdvertising();

    const { page, limit } = usePaginationParameters();
    const displayedAdsIndexRange = getDisplayedAdsIndexRange({ page, limit, totalResults });

    return (
        <Wrapper>
            <InfoContainer>
                <ListingInfo>
                    <ListingTextInfoWrapper>
                        {pageHeading ? <Heading data-cy="search-listing.heading">{pageHeading}</Heading> : null}
                        <AdditionalInfoWrapper>
                            <AdsCountInfo>
                                {t('frontend.search.listing-panel.ads-number', {
                                    displayedAdsIndexRange,
                                    totalResults: totalResults.toString(),
                                })}
                            </AdsCountInfo>
                            <SortingInfoLink
                                target="_blank"
                                rel="nofollow noreferrer"
                                href={SITE_CONFIG.helpCenterSortingInfoLinks}
                            >
                                {t('frontend.search.listing-panel.sorting-info-link')}
                            </SortingInfoLink>
                        </AdditionalInfoWrapper>
                    </ListingTextInfoWrapper>

                    <Desktop>
                        <SortByWrapper>
                            <SortLabel>{t('frontend.shared.listing-sorting.sort-by')}</SortLabel>
                            <NexusListingSorting sortingOption={sortingOption} />
                        </SortByWrapper>
                    </Desktop>
                </ListingInfo>

                <Mobile>
                    <ConnectedMapViewButton images={mapImages} />
                </Mobile>
            </InfoContainer>

            <Mobile>
                <AdvertSlot id="baxter-l-scr" />
            </Mobile>
        </Wrapper>
    );
};
