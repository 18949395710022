import type { GenericGraphQLError } from '@lib/graphql/error';
import type { GetAllSearchesForUser } from '@type/favorites/allSearchesForUser';

// Keeps as constant to avoid object reference check fail, e.g. in useEffect
const ERROR_INTERNAL = { __typename: 'ErrorInternal', message: 'Custom SaveSearch ErrorInternal' } as const;

// This normalizer can be removed, when the query is normalized and returns the correct __typename
export const normalizeSavedSearchesArrayToTypeName = <
    T extends GetAllSearchesForUser[] | GenericGraphQLError | undefined | null,
>(
    items: T,
): { __typename: 'SavedSearches'; items: GetAllSearchesForUser[] } | GenericGraphQLError => {
    if (Array.isArray(items)) {
        return { items, __typename: 'SavedSearches' };
    }
    if (items && typeof items === 'object' && '__typename' in items) {
        return items;
    }

    return ERROR_INTERNAL;
};
