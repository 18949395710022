import type { UnconfirmedSearchInput } from '@domains/search/types/createUnconfirmedSearch';
import type { WithTypename } from '@domains/shared/types/withTypename';
import { gql } from '@urql/core';

interface CreateUnconfirmedSearchData {
    createUnconfirmedSearch: WithTypename<{ success: boolean } | { error: string }>;
}

interface CreateUnconfirmedSearchVariables {
    search: UnconfirmedSearchInput;
}

export const CREATE_UNCONFIRMED_SEARCH_MUTATION = gql<CreateUnconfirmedSearchData, CreateUnconfirmedSearchVariables>`
    mutation CreateUnconfirmedSearch($search: CreateUnconfirmedSearch!) {
        createUnconfirmedSearch(search: $search) {
            ... on OperationSearchSuccess {
                success
            }
            ... on OperationSearchError {
                error
            }
        }
    }
`;
