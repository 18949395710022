import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import ChevronLeftIcon from '@nexus/lib-react/dist/core/Icon/icons/default/ChevronLeft';
import ChevronRightIcon from '@nexus/lib-react/dist/core/Icon/icons/default/ChevronRight';
import type { JSX } from 'react';

import { Button, Container, Indicator } from './Pagination.theme';

interface Props {
    page: number;
    total: number;
    onPreviousClick: () => void;
    onNextClick: () => void;
}

export const Pagination = ({ page, total, onPreviousClick, onNextClick }: Props): JSX.Element => {
    const [t] = useTranslations();

    return (
        <Container>
            <Button
                aria-label={t('frontend.pagination-controls.previous-page')}
                disabled={page === 1}
                onClick={onPreviousClick}
            >
                <ChevronLeftIcon />
            </Button>

            <Indicator>
                {t('frontend.global.pagination.page-n-of-total', {
                    n: page.toString(),
                    total: total.toString(),
                })}
            </Indicator>

            <Button
                aria-label={t('frontend.pagination-controls.next-page')}
                disabled={page === total}
                onClick={onNextClick}
            >
                <ChevronRightIcon />
            </Button>
        </Container>
    );
};
