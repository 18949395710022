import type { SavedSearchFilters } from '@domains/search/components/SaveSearchResults/types';
import type { SearchingFilters } from '@type/search/searchingFilters';

export const compareSavedSearchValuesWithAttributes = (
    savedSearches: SavedSearchFilters[],
    currentFiltersSelected: Partial<SearchingFilters>,
    currentLocationsSelected: { domainId: string }[],
    currentGeometrySelected: { byGeoJson: string }[],
): boolean => {
    const compareLocation = areLocationsEqualByDomain(currentLocationsSelected, currentGeometrySelected.length > 0);
    const compareSavedFilters = areFiltersEqual(currentFiltersSelected);
    const compareGeometryLocations = areGeometryCoordinatesEqual(currentGeometrySelected);
    const compare = ({ filterAttributes, filterLocations }: SavedSearchFilters): boolean =>
        compareSavedFilters(filterAttributes) &&
        (compareLocation(filterLocations) || compareGeometryLocations(filterLocations));

    return savedSearches.some(compare);
};

type Primitive = number | string | boolean;
const arePrimitiveValuesArraysEqual = (arrayA: Primitive[], arrayB: Primitive[]): boolean => {
    const sortedArrayA = [...arrayA].sort((a, b) => String(a).localeCompare(String(b)));
    const sortedArrayB = [...arrayB].sort((a, b) => String(a).localeCompare(String(b)));

    return sortedArrayA.join(',') === sortedArrayB.join(',');
};

/**
 * Check if both filters objects have same values in skipping non values
 */
const areFiltersEqual =
    (filtersA: Partial<SearchingFilters>) =>
    (filtersB: Partial<SearchingFilters>): boolean => {
        const appliedFiltersA = getAppliedFilters(filtersA);
        const appliedFiltersB = getAppliedFilters(filtersB);

        const isEqualNumberOfKeys = Object.keys(appliedFiltersA).length === Object.keys(appliedFiltersB).length;
        /**
         * Object equal function from library like Lodash could be better alternative if available
         */

        return (
            isEqualNumberOfKeys &&
            Object.entries(appliedFiltersA).every(([key, value]) => {
                const valueB = appliedFiltersB[key as keyof typeof appliedFiltersB];

                if (Array.isArray(value) && Array.isArray(valueB)) {
                    /**
                     * Casting is required to silent error with locations being possible and array of objects.
                     * This is mistyped and need refactor os split of search types with savedSearch types
                     */
                    return arePrimitiveValuesArraysEqual(valueB as Primitive[], value as Primitive[]);
                }

                if ([valueB, value].every((possiblyNumber) => !Number.isNaN(Number(possiblyNumber)))) {
                    return Number(value) === Number(valueB);
                }

                return valueB === value;
            })
        );
    };

const isValidNonValue = (attributeValue: unknown): boolean => {
    const isAttributeEmptyArray = Array.isArray(attributeValue) && attributeValue.length === 0;
    const isAttributeZeroString = attributeValue === '0' || attributeValue === 0;
    const isAttributeAllValue = attributeValue === 'ALL';
    const isEmptyString = attributeValue === '';
    const isAttributeNil = attributeValue === undefined || attributeValue === null;

    return isAttributeEmptyArray || isAttributeZeroString || isAttributeAllValue || isEmptyString || isAttributeNil;
};

const getAppliedFilters = (filters: Partial<SearchingFilters>): Partial<SearchingFilters> => {
    return Object.entries(filters).reduce((appliedFilters, [key, value]) => {
        if (isValidNonValue(value)) {
            return appliedFilters;
        }

        // GraphQL add this to objects
        if (key === '__typename') {
            return appliedFilters;
        }

        return {
            ...appliedFilters,
            [key]: value,
        };
    }, {} as Partial<SearchingFilters>);
};

const areLocationsEqualByDomain =
    (currentLocationsSelected: { domainId: string }[], isGeometrySearch: boolean) =>
    (savedLocations: SavedSearchFilters['filterLocations']): boolean => {
        let areLocationsSame = false;

        const savedSearchLocations = savedLocations?.byDomainId || [];
        const hasAnySavedSearchLocations = savedSearchLocations.length > 0;
        const hasAnyFormLocations = currentLocationsSelected.length > 0;
        const isLocationNeverSelected = !hasAnyFormLocations && savedSearchLocations.length === 0;

        if (isGeometrySearch) {
            // domainId will be present in the new save search as we have the same url for the map so, we need to skip validation in this case.
            return false;
        }

        if (isLocationNeverSelected) {
            return true;
        }

        if (
            hasAnyFormLocations &&
            hasAnySavedSearchLocations &&
            /**
             * Arrays must be same length to match all elements
             */
            savedSearchLocations.length === currentLocationsSelected.length
        ) {
            areLocationsSame = savedSearchLocations.every((savedLocation: { domainId: string }): boolean => {
                /**
                 * Check if every element from saved search exist in currently selected search
                 * this plus same length condition ensure that arrays contains the same locations Ids
                 */
                return !!currentLocationsSelected.some((location) => savedLocation.domainId === location.domainId);
            });
        }

        return areLocationsSame;
    };

const areGeometryCoordinatesEqual =
    (currentGeometrySelected: { byGeoJson: string }[]) =>
    (savedLocations: SavedSearchFilters['filterLocations']): boolean => {
        if (currentGeometrySelected.length === 0) {
            // skip validation in case save search is not from the map drawing.
            return false;
        }

        let areGeometryCoordinatesEqual = false;
        const savedSearchLocationsByGeometry = savedLocations?.byGeometry;

        if (currentGeometrySelected.length === savedSearchLocationsByGeometry?.length) {
            areGeometryCoordinatesEqual = savedSearchLocationsByGeometry?.every(
                (savedGeometryLocation: { byGeoJson: string }): boolean => {
                    return currentGeometrySelected.every(
                        (currentGeometry): boolean => currentGeometry.byGeoJson === savedGeometryLocation.byGeoJson,
                    );
                },
            );
        }

        return areGeometryCoordinatesEqual;
    };
