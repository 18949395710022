import type { AdvertAgency } from '@type/ad/advertAgency';

export const getSellerImage = (
    agency: AdvertAgency,
): {
    url: string | undefined;
    isVisible: boolean;
} => {
    const shouldDisplayAgencyLogo = !!agency?.imageUrl && !!agency?.name && agency.brandingVisible;

    const image = {
        url: agency?.imageUrl,
        isVisible: shouldDisplayAgencyLogo,
    };

    return image;
};
