import { Skeleton } from '@domains/shared/components/Skeleton/Skeleton';
import dynamic from 'next/dynamic';

import { Container, PageSizeContainer } from './BasePagination.theme';

export const LazyPagination = dynamic(() => import('@domains/search/components/Pagination/BasePagination'), {
    loading: () => (
        <Container>
            <Skeleton height={40} width={350} />
            <PageSizeContainer>
                <Skeleton height={40} width={70} />
                <Skeleton height={40} width={70} style={{ marginLeft: 8 }} />
            </PageSizeContainer>
        </Container>
    ),
    ssr: true,
});
