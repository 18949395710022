import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { SIZE } from '@lib/styles/partials/typography';
import { theme } from '@lib/styles/themes/nexus';
import type { CSSProperties } from 'react';

export const Title = styled.span<{ shouldDisableTopBorder?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding-top: 16px;
    border-top: ${({ theme }): CSSProperties['borderTop'] =>
        `1px solid ${theme.deprecated.domains.search.listing.title.borderColor}`};
    color: ${(props): string => props.theme.deprecated.atoms.heading.h3.color};
    font-size: ${SIZE.x4}px;
    font-weight: 700;
    line-height: 1.1;

    @media (min-width: ${BREAKPOINT.md}) {
        padding-top: ${({ shouldDisableTopBorder }): CSSProperties['paddingTop'] =>
            shouldDisableTopBorder ? '0' : '32px'};
        border-top: ${({ theme, shouldDisableTopBorder }): CSSProperties['borderTop'] =>
            shouldDisableTopBorder ? 'none' : `1px solid ${theme.deprecated.domains.search.listing.title.borderColor}`};
        font-size: ${SIZE.x5}px;
    }
`;

export const Subtitle = styled.div`
    padding-bottom: 32px;
`;

export const GoToResults = styled.button`
    margin-left: 4px;
    border: 0;
    outline: 0;
    background: none;
    color: ${theme.colors['text-global-primary']};
    font-size: ${theme.fontSizes[16]};
    font-weight: ${theme.fontWeights[400]};
    line-height: ${theme.lineHeights[20]};
    text-align: right;
    text-decoration: underline;
    cursor: pointer;
`;
