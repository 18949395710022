import { PROPERTY_EVALUATION_LANDING_PAGE_URL } from '@domains/propertyEvaluation/consts/routes';
import {
    ActionsColumn,
    Banner,
    Button,
    Content,
    Subtitle,
    TextColumn,
} from '@domains/search/components/PropertyPriceEvaluationBanner/PropertyPriceEvaluationBanner.theme';
import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { EXPERIMENT } from '@domains/shared/consts/experiment';
import { useIntersection } from '@domains/shared/hooks/useIntersection/useIntersection';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useExperiments } from '@lib/experiments/client/ExperimentsProvider';
import { useTracking } from '@lib/tracking/useTracking';
import { Label1 } from '@nexus/lib-react/dist/core/Typography';
import type { JSX, MouseEvent, MouseEventHandler } from 'react';
import { useRef } from 'react';

export const PropertyPriceEvaluationBanner = (): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();

    const { isVariantEnabled } = useExperiments();
    const isAlternativeWordingExperimentEnabled = isVariantEnabled(EXPERIMENT.eure26607, 'b');

    const containerRef = useRef<HTMLDivElement | null>(null);
    useIntersection(containerRef, (_, observer) => {
        const { current } = containerRef;
        trackEvent('property_price_evaluation_impression', {
            touch_point_page: 'listing',
            touch_point_button: 'listing_entry_point',
        });

        if (current && observer) {
            // once trackEvent is called, there is no need for this observer
            observer.unobserve(current);
            observer.disconnect();
        }
    });

    const handleLinkClick: MouseEventHandler<HTMLAnchorElement> = (event: MouseEvent) => {
        event.stopPropagation();
        trackEvent('property_price_evaluation_start', {
            touch_point_page: 'listing',
            touch_point_button: 'listing_entry_point',
        });
    };

    return (
        <div data-cy="property-evaluation-banner-listing-item" ref={containerRef}>
            <Banner data-cy="property-evaluation-banner">
                <Content>
                    <TextColumn>
                        <Label1 role="heading">{t('frontend.search.property-evaluation-banner.header')}</Label1>
                        <Subtitle role="note">{t('frontend.search.property-evaluation-banner.subheader')}</Subtitle>
                    </TextColumn>
                    <ActionsColumn>
                        <LocalLink href={PROPERTY_EVALUATION_LANDING_PAGE_URL} passHref>
                            <a onClick={handleLinkClick} role="link" target="_blank">
                                <Button variant="secondary" size="small">
                                    {isAlternativeWordingExperimentEnabled
                                        ? t('frontend.search.property-evaluation-banner.alternative-cta-button-label')
                                        : t('frontend.search.property-evaluation-banner.cta-button-label')}
                                </Button>
                            </a>
                        </LocalLink>
                    </ActionsColumn>
                </Content>
            </Banner>
        </div>
    );
};
