import type { PrimaryImageOfPageSchemaMarkup } from '@config/schemaMarkup/type';
import type { AdvertListItem } from '@domains/search/types/advertListItem';

export const getPrimaryImageOfPage = (
    advertListItems: AdvertListItem[],
): Pick<PrimaryImageOfPageSchemaMarkup, 'url' | 'width' | 'height'> | undefined => {
    const urlImageOfFirstAdvert = advertListItems?.[0]?.images?.[0]?.medium ?? null;

    if (!urlImageOfFirstAdvert) return;

    return {
        url: urlImageOfFirstAdvert,
        width: '280',
        height: '208',
    };
};
