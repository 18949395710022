import type { JSX } from 'react';
import { forwardRef } from 'react';

import { MapBackground } from '../components/MapBackground';
import { TriggerButton } from '../components/TriggerButton';
import { Container } from './MapViewButton.theme';

interface Props {
    images: string[];
}

export const MapViewButton = forwardRef<HTMLButtonElement, Props>(function MapViewButton(props, ref): JSX.Element {
    const { images } = props;

    return (
        <Container>
            <MapBackground images={images} />
            <TriggerButton ref={ref} />
        </Container>
    );
});
