import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const StyledParagraph = styled.p`
    display: -webkit-box;
    flex-shrink: initial;
    margin: 0;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

export const InvestmentLink = styled.a`
    color: ${theme.colors['text-brand-alternative']};
    text-decoration: underline;
`;
