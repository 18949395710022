import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const Button = styled.button`
    display: inline-flex;
    align-items: center;
    padding: 0;
    border: 0;
    background: none;
    color: ${theme.colors['text-global-primary']};

    &:disabled {
        opacity: 0.2;
    }

    &:not(:disabled) {
        cursor: pointer;
    }
`;

export const Indicator = styled.span`
    color: ${theme.colors['text-global-secondary']};
    text-align: center;
`;
