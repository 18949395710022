import { useFiltersTrackingSet } from '@domains/search/hooks/useCommonFilterTrackingProperties';
import { useInvestmentsTrackingSet } from '@domains/search/hooks/useInvestmentsTrackingSet';
import { createBasicAdvertCardTrackingSet } from '@domains/shared/helpers/createBasicAdvertCardTrackingSet';
import { useTracking } from '@lib/tracking/useTracking';
import type { Pagination } from '@type/pagination/base';

interface Options {
    isNearbySection: boolean;
    isPromotedSection: boolean;
    isSpecialOfferToggleOn: boolean;
    isRelatedAdsParent?: boolean;
    pinTypeOrigin?: 'single' | 'grouped' | 'maplist';
    pagination?: Pagination;
}

export const useListingAdvertCardTracking = ({
    item,
    index,
    options,
}: {
    item: Parameters<typeof generateFullTrackingSet>[0]['item'];
    index: number;
    options: Options;
}): {
    fullTrackingSet: Record<string, unknown>;
    handleContainerClick: () => void;
    handleContainerContextMenu: () => void;
} => {
    const { trackEvent } = useTracking();

    const fullTrackingSet = generateFullTrackingSet({
        item,
        index,
        investmentsTrackingSet: useInvestmentsTrackingSet(),
        filtersTrackingSet: useFiltersTrackingSet(),
        options,
    });

    const handleContainerClick = (): void => {
        trackEvent('ad_click', {
            ...fullTrackingSet,
        });
    };

    const handleContainerContextMenu = (): void => {
        trackEvent('ad_click', {
            ...fullTrackingSet,
            touch_point_button: `${fullTrackingSet.touch_point_button}_new_tab`,
        });
    };

    return {
        fullTrackingSet,
        handleContainerClick,
        handleContainerContextMenu,
    };
};

const generateFullTrackingSet = ({
    item,
    index,
    filtersTrackingSet,
    investmentsTrackingSet,
    options,
}: {
    item: Parameters<typeof createBasicAdvertCardTrackingSet>[0]['item'];
    index: number;
    filtersTrackingSet: Record<string, unknown>;
    investmentsTrackingSet: Record<string, unknown> | null;
    options: Options;
}): Record<string, unknown> => {
    const basicTrackingSet = createBasicAdvertCardTrackingSet({
        item,
        index: index + 1,
        isNearbySection: options.isNearbySection,
        isPromotedSection: options.isPromotedSection,
        isSpecialOfferToggleOn: options.isSpecialOfferToggleOn,
        isRelatedAdsParent: options.isRelatedAdsParent,
    });

    const mapTrackingSet = options.pinTypeOrigin
        ? {
              listing_format: 'map',
              touch_point_button: buildTouchPointButton(options),
              page_nb: options.pagination?.page ?? null,
              page_count: options.pagination?.totalPages ?? null,
              result_count: options.pagination?.totalResults ?? null,
              results_per_page: options.pagination?.itemsPerPage ?? null,
          }
        : {};

    return {
        ...basicTrackingSet,
        ...filtersTrackingSet,
        ...investmentsTrackingSet,
        ...mapTrackingSet,
    };
};

const buildTouchPointButton = (options: Options): string => {
    const tpbArray = ['map'];
    if (options.isPromotedSection) {
        tpbArray.push('promoted_ads');
    }
    if (options.pinTypeOrigin && options.pinTypeOrigin !== 'maplist') {
        tpbArray.push(options.pinTypeOrigin);
    }

    return tpbArray.join('_');
};
