import { Skeleton } from '@domains/shared/components/Skeleton/Skeleton';
import type { JSX } from 'react';

import { BaseSearchFormSkeletonContainer } from './SearchForm.skeleton.theme';

export const SearchFormSkeleton = (): JSX.Element => {
    return (
        <BaseSearchFormSkeletonContainer>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 32, paddingBottom: '35px' }}>
                <Skeleton height={48} />
                <div style={{ gridColumn: '2 / 4' }}>
                    <Skeleton height={48} />
                </div>
            </div>

            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gap: 32,
                    paddingBottom: '34px',
                }}
            >
                <Skeleton height={48} />
                <Skeleton height={48} />
                <Skeleton height={48} />
            </div>

            <div style={{ display: 'grid', gridTemplateColumns: '160px 160px 240px', gap: 24, justifyContent: 'end' }}>
                <Skeleton height={48} />
                <Skeleton height={48} />
                <Skeleton height={48} />
            </div>
        </BaseSearchFormSkeletonContainer>
    );
};
