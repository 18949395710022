import type { Feature, MultiPolygon, Polygon, Properties } from '@turf/helpers';
import type { PropsWithChildren } from 'react';
import { createContext, useCallback, useState } from 'react';
import { useLocalStorage, useMount } from 'react-use';

export type CustomArea = Polygon | Feature<Polygon | MultiPolygon, Properties> | null;

interface MapDrawingContextReturn {
    setCustomAreaPolygon(area: CustomArea): void;
    clearCustomAreaPolygon(): void;
    customAreaPolygon: CustomArea;
}
export const MapDrawingContext = createContext<MapDrawingContextReturn>({
    setCustomAreaPolygon: () => undefined,
    clearCustomAreaPolygon: () => undefined,
    customAreaPolygon: null,
});

export const MAP_DRAWING_LOCAL_STORAGE_KEY = 'SAVE_SEARCH_MAP_DRAWING_COORDINATES';
export const SAVE_SEARCH_AFTER_REDIRECT_EVENT = 'onSaveSearchAfterRedirect';

export const MapDrawingContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [customAreaPolygon, setCustomAreaPolygon] = useState<CustomArea>(null);
    const [customAreaFromLocalStorage, setInLocalStorage, removeFromLocalStorage] =
        useLocalStorage<CustomArea>(MAP_DRAWING_LOCAL_STORAGE_KEY);

    useMount(() => {
        if (!customAreaPolygon && customAreaFromLocalStorage) {
            setCustomAreaPolygon(customAreaFromLocalStorage);
        }
    });

    const clearCustomAreaPolygon = useCallback((): void => {
        removeFromLocalStorage();
    }, [removeFromLocalStorage]);

    const setCustomArea = useCallback(
        (area: CustomArea): void => {
            setCustomAreaPolygon(area);
            setInLocalStorage(area);
        },
        [setCustomAreaPolygon, setInLocalStorage],
    );

    return (
        <MapDrawingContext.Provider
            value={{
                setCustomAreaPolygon: setCustomArea,
                clearCustomAreaPolygon,
                customAreaPolygon,
            }}
        >
            {children}
        </MapDrawingContext.Provider>
    );
};
