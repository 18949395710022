import { BREAKPOINTS } from '@domains/search/consts/breakpoints';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Wrapper } from '@widgets/AdvertCard/Wrapper';

const CARD_MIN_WIDTH = '295px';

export const Container = styled.article`
    position: relative;
    min-width: ${CARD_MIN_WIDTH};
    overflow: hidden;
    border-radius: ${theme.radii[4]};
    list-style: none;
`;

export const StyledWrapper = styled(Wrapper)`
    display: grid;
    grid-template-rows: 246px auto;

    @media ${theme.mediaQueries.lgMin} {
        grid-template-columns: 400px auto;
        grid-template-rows: unset;
    }

    @media ${BREAKPOINTS['1440min']} {
        grid-template-columns: 400px auto;
        grid-template-rows: unset;
    }
`;

export const InfoSection = styled.div`
    min-width: 0;
    padding: ${theme.space[16]};
`;
