import type { OwnerType } from '@type/ad/ownerType';
import { OWNER_TYPE } from '@type/ad/ownerType';

const OWNER_TYPE_VALUES: string[] = Object.values(OWNER_TYPE);

export const checkIsOwnerType = (value: unknown): value is OwnerType => {
    if (typeof value !== 'string') {
        return false;
    }

    return OWNER_TYPE_VALUES.includes(value);
};
