import { RWDContext } from '@domains/shared/contexts/RWDContext';
import type { FC, PropsWithChildren } from 'react';
import { useContext } from 'react';

import { DesktopContentWrapper } from './Desktop.theme';

export const Desktop: FC<PropsWithChildren> = ({ children }) => {
    const { isDesktop } = useContext(RWDContext);

    return isDesktop === null || isDesktop === true ? <DesktopContentWrapper>{children}</DesktopContentWrapper> : null;
};
