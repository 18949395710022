import { MapViewButton } from '@domains/search/components/MapViewButton/views/MapViewButton';
import { useInvestmentsTrackingSet } from '@domains/search/hooks/useInvestmentsTrackingSet';
import { useTrackImpression } from '@domains/shared/hooks/useTrackImpression/useTrackImpression';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';
import { useRef } from 'react';

export const ConnectedMapViewButton = ({ images }: { images: string[] }): JSX.Element => {
    const { trackEvent } = useTracking();
    const elementRef = useRef<HTMLButtonElement>(null);
    const investmentExtraFilters = useInvestmentsTrackingSet();

    useTrackImpression(elementRef, () => {
        trackEvent('listing_map_view_button_impression', {
            ...investmentExtraFilters,
            listing_format: 'list',
            touch_point_button: 'listing',
        });
    });

    return <MapViewButton ref={elementRef} images={images} />;
};
