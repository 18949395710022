import { GallerySection } from '@domains/search/components/NexusGallerySection/GallerySection';
import { useListingAdvertCardTracking } from '@domains/search/hooks/useListingAdvertCardTracking';
import type { AdvertListItem } from '@domains/search/types/advertListItem';
import { SpecialOfferHeader } from '@domains/shared/components/AdvertItem/SpecialOfferHeader/SpecialOfferHeader';
import {
    checkTimeSinceAdCreationWithinLimit,
    getDaysSinceAdCreation,
} from '@domains/shared/helpers/advertCreationTime';
import { generateAdvertLink } from '@domains/shared/helpers/generateAdvertLink';
import { useAdvertImpressionsTracking } from '@domains/shared/hooks/useAdvertImpressionsTracking';
import type { Theme } from '@emotion/react';
import type { StyledComponent } from '@emotion/styled';
import type { SetSimilarAdsPromptId } from '@type/favorites/similarAdsPromptId';
import type { Pagination } from '@type/pagination/base';
import { ESTATE } from '@type/search/filters/estate';
import type { Wrapper } from '@widgets/AdvertCard/Wrapper';
import type { ClassAttributes, ComponentProps, ForwardRefExoticComponent, HTMLAttributes, JSX } from 'react';
import { useRef } from 'react';

import { Container, InfoSection, StyledWrapper } from './AdvertCard.theme';
import { RelatedAdvertsSection } from './components/RelatedAdvertsSection/RelatedAdvertsSection';
import { PriceFirstInfoSection } from './structure/PriceFirstInfoSection';

interface Props {
    index: number;
    item: AdvertListItem;
    pinTypeOrigin?: 'single' | 'grouped' | 'maplist';
    shouldLazyLoadGallery?: boolean;
    isNearbySection?: boolean;
    isPromotedSection?: boolean;
    isSpecialOfferToggleOn?: boolean;
    setSimilarAdsPromptId?: SetSimilarAdsPromptId;
    pagination?: Pagination;
    wrapper?:
        | ForwardRefExoticComponent<
              Omit<
                  {
                      theme?: Theme;
                      as?: React.ElementType;
                  } & ClassAttributes<HTMLDivElement> &
                      HTMLAttributes<HTMLDivElement>,
                  'ref'
              >
          >
        | StyledComponent<ComponentProps<typeof Wrapper>>;
}

export const AdvertCard = ({
    index,
    item,
    pinTypeOrigin,
    shouldLazyLoadGallery,
    isNearbySection = false,
    isPromotedSection = false,
    isSpecialOfferToggleOn = false,
    setSimilarAdsPromptId,
    pagination,
    wrapper: Wrapper = StyledWrapper,
}: Props): JSX.Element => {
    const { fullTrackingSet, handleContainerClick, handleContainerContextMenu } = useListingAdvertCardTracking({
        item,
        index,
        options: {
            isNearbySection,
            isPromotedSection,
            isSpecialOfferToggleOn,
            isRelatedAdsParent: !!item.relatedAds,
            pinTypeOrigin,
            pagination,
        },
    });

    const containerRef = useRef<HTMLElement | null>(null);
    useAdvertImpressionsTracking(containerRef, { ...fullTrackingSet, ad_impressions: null });

    const daysSinceAdCreation = getDaysSinceAdCreation(item.dateCreatedFirst);
    const shouldShowAdvertCreationBadge = checkTimeSinceAdCreationWithinLimit(daysSinceAdCreation);

    const isInvestment = item.estate === ESTATE.investment;
    const hasOpenDays = !!item.openDays;

    const fullAdvertLink = generateAdvertLink({
        isInvestment,
        slug: item.slug,
    });

    return (
        <Container ref={containerRef} data-cy="listing-item">
            {item.specialOffer ? (
                <SpecialOfferHeader
                    discountType={item.specialOffer.__typename}
                    discountValue={item.specialOffer.discountValue}
                    displayAdditionalTitlePart={!item.specialOffer.details.appliesToAllUnits && isInvestment}
                />
            ) : null}

            <Wrapper as="section" onClick={handleContainerClick} onContextMenu={handleContainerContextMenu}>
                <GallerySection
                    item={item}
                    shouldLazyLoadGallery={shouldLazyLoadGallery}
                    trackingSet={fullTrackingSet}
                    shouldShowAdvertCreationBadge={shouldShowAdvertCreationBadge}
                    daysSinceAdCreation={daysSinceAdCreation}
                    advertLink={fullAdvertLink}
                    shouldSpaceOpenDays={hasOpenDays}
                />
                <InfoSection>
                    <PriceFirstInfoSection
                        item={item}
                        isNearbySection={isNearbySection}
                        fullAdvertLink={fullAdvertLink}
                        setSimilarAdsPromptId={setSimilarAdsPromptId}
                        fullTrackingSet={fullTrackingSet}
                        isPromotedSection={isPromotedSection}
                    />
                </InfoSection>
            </Wrapper>

            {item.relatedAds ? (
                <RelatedAdvertsSection
                    relatedAds={item.relatedAds}
                    developmentId={item.developmentId}
                    developmentTitle={item.developmentTitle}
                    developmentUrl={item.developmentUrl}
                    trackingSet={fullTrackingSet}
                />
            ) : null}
        </Container>
    );
};
