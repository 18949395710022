import { BaxterAdvertising } from '@domains/search/components/Listing/components/Advertising/BaxterAdvertising';
import { useBaxterAdvertising } from '@domains/shared/components/Advertising/useBaxterAdvertising';
import type { JSX } from 'react';

interface Props {
    position: number;
}

export const Advertising = ({ position }: Props): JSX.Element => {
    useBaxterAdvertising();

    return <BaxterAdvertising position={position} />;
};
