import type { Estate } from '@type/search/filters/estate';
import { ESTATE } from '@type/search/filters/estate';
import type { SearchingFilters } from '@type/search/searchingFilters';
import dynamic from 'next/dynamic';
import type { ComponentType, FC } from 'react';
import { memo } from 'react';

import type { SeoComponentProps } from './types';

const LAZY_FLAT_SELL = dynamic(() => import('@domains/search/components/AdsDescriptionSection/data/flatSell'), {
    ssr: true,
});
const LAZY_FLAT_RENT = dynamic(() => import('@domains/search/components/AdsDescriptionSection/data/flatRent'), {
    ssr: true,
});
const LAZY_HOUSE_SELL = dynamic(() => import('@domains/search/components/AdsDescriptionSection/data/houseSell'), {
    ssr: true,
});
const LAZY_HOUSE_RENT = dynamic(() => import('@domains/search/components/AdsDescriptionSection/data/houseRent'), {
    ssr: true,
});

type Key = 'FLAT-SELL' | 'FLAT-RENT' | 'HOUSE-SELL' | 'HOUSE-RENT';

const MAPPING: Record<Key, ComponentType<SeoComponentProps>> = {
    'FLAT-SELL': LAZY_FLAT_SELL,
    'FLAT-RENT': LAZY_FLAT_RENT,
    'HOUSE-SELL': LAZY_HOUSE_SELL,
    'HOUSE-RENT': LAZY_HOUSE_RENT,
};

interface Props {
    location: string | null;
    filterAttributes: Partial<Omit<SearchingFilters, 'limit' | 'locations' | 'page'>>;
    page?: number;
}

const checkIsValidEstateType = (estate?: Estate | null): estate is 'HOUSE' | 'FLAT' => {
    return estate === ESTATE.house || estate === ESTATE.flat;
};

const AdsDescriptionSectionBase: FC<Props> = ({ location, filterAttributes, page }) => {
    const { ownerTypeSingleSelect, distanceRadius, estate, transaction, ...restFilterAttributes } = filterAttributes;

    if (
        !checkIsValidEstateType(estate) ||
        !transaction ||
        !location ||
        (page && page !== 1) ||
        // we don't want to return data if there are active filters other than the following: market, estate, transaction
        Object.keys(restFilterAttributes).length !== 1 ||
        restFilterAttributes.market !== 'ALL'
    ) {
        return null;
    }

    const key: Key = `${estate}-${transaction}`;
    const SeoComponent = MAPPING[key];

    if (!SeoComponent) {
        return null;
    }

    const parsedLocation = location.split('/').slice(-1)[0];

    return <SeoComponent location={parsedLocation} />;
};

export const AdsDescriptionSection = memo(AdsDescriptionSectionBase);
