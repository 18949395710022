import { MapDrawingContextProvider } from '@domains/search/contexts/MapDrawingContext';
import { getSearchPageTouchPointButtonValue } from '@domains/search/helpers/getSearchPageTouchPointButtonValue';
import type { SearchPageProps, SearchPageTracking } from '@domains/search/SearchPage';
import { AdsProvider } from '@domains/shared/components/AdsProvider';
import { SavedAdsContextProvider } from '@domains/shared/contexts/SavedAdsContext/SavedAdsContext';
import { SavedSearchesContextProvider } from '@domains/shared/contexts/SavedSearchesContext/SavedSearchesContext';
import { SearchFormValuesContext } from '@domains/shared/contexts/SearchFormValuesContext';
import { ViewTypeContextProvider } from '@domains/shared/contexts/ViewTypeContext';
import type { LaquesisPageProps } from '@lib/experiments/types/laquesisPageProps';
import { PageProviders } from '@lib/pages/contexts/PageProviders/PageProviders';
import type { SearchPageVariant } from '@type/search/pageVariant';
import type { SearchFormValues } from '@type/search/searchFormValues';
import { useRouter } from 'next/router';
import type { JSX } from 'react';
import { useMemo } from 'react';

interface Props extends LaquesisPageProps {
    filteringQueryParams: SearchPageProps['filteringQueryParams'];
    tracking: SearchPageTracking;
    searchFormValues: SearchFormValues;
    children: JSX.Element;
    targetingArguments?: unknown;
    urlViewType: SearchPageVariant;
}

export const WithProviders = ({
    experiments,
    filteringQueryParams,
    laquesisResult,
    tracking,
    searchFormValues,
    targetingArguments,
    children,
    urlViewType,
}: Props): JSX.Element => {
    const { query } = useRouter();

    const defaultTrackingData = useMemo(() => {
        const trackingSet: Record<string, unknown> = {
            selected_locations_id: tracking.listing?.selected_locations_id,
        };

        if (urlViewType === 'map') {
            Object.assign(trackingSet, {
                page_nb: null,
                page_count: null,
                result_count: null,
                results_per_page: null,
                ad_impressions: null,
            });
        }

        return {
            ...tracking.listing,
            ...tracking.search,
            ...trackingSet,
            listing_format: urlViewType,
            touch_point_button: getSearchPageTouchPointButtonValue(urlViewType, query?.utm_source, query?.utm_medium),
            only_special_offers: filteringQueryParams?.hasDiscount ? 1 : 0,
            touch_point_page: 'listing',
        };
    }, [
        filteringQueryParams?.hasDiscount,
        query?.utm_medium,
        query?.utm_source,
        tracking.listing,
        tracking.search,
        urlViewType,
    ]);

    return (
        <PageProviders
            defaultTrackingData={defaultTrackingData}
            experiments={experiments}
            laquesisResult={laquesisResult}
            trackPageName={query.fromNoEstate === 'true' ? 'listing_no_estate_provided' : 'listing'}
        >
            <MapDrawingContextProvider>
                <SavedAdsContextProvider>
                    <SavedSearchesContextProvider>
                        <SearchFormValuesContext.Provider value={searchFormValues}>
                            <ViewTypeContextProvider urlViewType={urlViewType}>
                                <AdsProvider targetingArguments={targetingArguments}>{children}</AdsProvider>
                            </ViewTypeContextProvider>
                        </SearchFormValuesContext.Provider>
                    </SavedSearchesContextProvider>
                </SavedAdsContextProvider>
            </MapDrawingContextProvider>
        </PageProviders>
    );
};
