import type { SchemaMarkupData } from '@config/schemaMarkup/type';
import { SITE_CONFIG } from '@config/siteConfig';
import type { AdvertListItem } from '@domains/search/types/advertListItem';
import { checkIsNonNullable } from '@domains/shared/helpers/checkIsNonNullable';
import { getStringifySchemaMarkup } from '@domains/shared/helpers/schemaMarkup/getStringifySchemaMarkup';
import { useMobileRecognition } from '@domains/shared/hooks/useMobileRecognition/useMobileRecognition';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import Head from 'next/head';
import type { JSX } from 'react';
import { useEffect, useState } from 'react';

interface Metadata {
    pageTitle: string;
    pageDescription: string;
    canonicalURL: string;
    adsToPreload: AdvertListItem[];
    shouldEnableIndexingByMetaRobots: boolean;
    schemaMarkupData: SchemaMarkupData | null;
}

interface AdsImagesMetadata {
    medium: {
        breakpoint: string;
        url: string;
    };
    large: {
        breakpoint: string;
        url: string;
    };
}

const getAdsImagesToPreload = (ads?: AdvertListItem[]): AdsImagesMetadata[] | undefined => {
    if (!ads || ads.length === 0) {
        return;
    }

    const images = ads.map(({ images }) => (images ? images[0] : null)).filter(checkIsNonNullable);

    return images.map(({ medium, large }) => ({
        medium: {
            breakpoint: `(max-width: ${BREAKPOINT.sm})`,
            url: medium,
        },
        large: {
            breakpoint: `(min-width: ${BREAKPOINT.sm})`,
            url: large,
        },
    }));
};

export const SearchPageHead = ({
    pageTitle,
    pageDescription,
    canonicalURL,
    adsToPreload,
    shouldEnableIndexingByMetaRobots,
    schemaMarkupData,
}: Metadata): JSX.Element => {
    const { links } = SITE_CONFIG.metadata;

    const defaultCanonical = links.find(({ rel }) => rel === 'canonical')?.href;
    const finalCanonicalUrl = `${defaultCanonical}${canonicalURL.slice(1)}`;
    const preloadedImages = getAdsImagesToPreload(adsToPreload);
    const { isAppleDevice } = useMobileRecognition();
    const [isApple, setIsApple] = useState(false);
    const stringifySchemaMarkup = getStringifySchemaMarkup(schemaMarkupData);

    useEffect(() => {
        if (!navigator) {
            return;
        }

        if (isAppleDevice(navigator.userAgent, navigator.platform)) {
            setIsApple(true);
        }
    }, [isAppleDevice]);

    return (
        <Head>
            <title>{pageTitle}</title>
            <link rel="canonical" href={finalCanonicalUrl} key="link_canonical" />
            <meta property="og:title" content={pageTitle} key="meta_property_og:title" />
            <meta name="description" content={pageDescription} key="meta_name_description" />
            <meta property="og:description" content={pageDescription} key="meta_property_og:description" />

            {/**
             * 'maximum-scale' parameter works differently on different browsers. It allows pinch-zoom on safari but disables this functionality on android chrome.
             * To fix the issue with zooming in on search form on apple devices the meta tag its being added conditionally.
             * Thanks to this solution bug on safari is fixed and pinch zoom is still available on both systems.
             */}
            {isApple ? (
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
            ) : null}
            <meta
                name="robots"
                content={`${shouldEnableIndexingByMetaRobots ? 'index' : 'noindex'}, follow`}
                key="meta_name_robots"
            />
            {stringifySchemaMarkup ? (
                <script
                    key="search-page-schema-markup"
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: stringifySchemaMarkup }}
                />
            ) : null}
            {preloadedImages
                ? preloadedImages.map(({ medium }) => (
                      <link
                          key={medium.url}
                          href={medium.url}
                          rel="preload"
                          as="image"
                          data-cy="preloaded-search-page-image"
                      />
                  ))
                : null}
        </Head>
    );
};
