import { isSaveSearchError } from '@domains/search/hooks/useSaveSearches/useSaveSearches';
import { toast } from '@domains/shared/components/Toast/toast';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { TrackEvent } from '@lib/tracking/types';
import { useTracking } from '@lib/tracking/useTracking';
import { useCallback } from 'react';

type TrackEventExtraData = Parameters<TrackEvent>[1];

interface UseSaveSearchOptions {
    trackingExtraData?: TrackEventExtraData;
}

interface SuccessEffectsOptions {
    trackingExtraData: TrackEventExtraData;
}

type UseSaveSearchEffects = (options?: UseSaveSearchOptions) => {
    triggerSuccessEffects(options?: SuccessEffectsOptions): void;
    triggerErrorEffects(error: unknown): void;
    triggerSaveAttemptEffects(trackingExtraData?: TrackEventExtraData): void;
};
const DEFAULT_TRACKING_EXTRA_DATA = {};
export const useSaveSearchEffects: UseSaveSearchEffects = ({ trackingExtraData } = DEFAULT_TRACKING_EXTRA_DATA) => {
    const { trackEvent } = useTracking();
    const [t] = useTranslations();

    const triggerTrackingEvent = useCallback(
        (eventName: string, extraData: TrackEventExtraData = {}) => {
            trackEvent(eventName, {
                ...trackingExtraData,
                ...extraData,
            });
        },
        [trackEvent, trackingExtraData],
    );

    const handleSuccessSave = useCallback<ReturnType<UseSaveSearchEffects>['triggerSuccessEffects']>(
        (options) => {
            toast.success(t('frontend.search.save-search-results.create-search-success'));
            triggerTrackingEvent('favourite_search_save_success', options?.trackingExtraData);
        },
        [t, triggerTrackingEvent],
    );

    const handleErrorSave = useCallback<ReturnType<UseSaveSearchEffects>['triggerErrorEffects']>(
        (error: unknown) => {
            if (isSaveSearchError(error)) {
                toast[error.toast](error.message);

                return;
            }

            toast.error(t('frontend.search.save-search-results.create-search-error'));
        },
        [t],
    );

    const triggerSaveEffects = useCallback<ReturnType<UseSaveSearchEffects>['triggerSaveAttemptEffects']>(
        (trackingExtraData?: TrackEventExtraData) => {
            triggerTrackingEvent('favourite_search_save', trackingExtraData || {});
        },
        [triggerTrackingEvent],
    );

    return {
        triggerSuccessEffects: handleSuccessSave,
        triggerErrorEffects: handleErrorSave,
        triggerSaveAttemptEffects: triggerSaveEffects,
    };
};
