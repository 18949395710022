import { getSavedSearchesWithNewLocations } from '@domains/shared/contexts/SavedSearchesContext/helpers/getSavedSearchesWithNewLocations';
import { useShouldPauseQueryForLogoutUser } from '@domains/shared/hooks/useShouldPauseQueryForLogoutUser/useShouldPauseQueryForLogoutUser';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import type { UseAssertGraphQlPropsGeneric } from '@lib/graphql/hooks/useAssertGraphqlResponse';
import { useAssertGraphqlResponse } from '@lib/graphql/hooks/useAssertGraphqlResponse';
import { normalizeForbiddenAccessQuery } from '@lib/graphql/normalizeForbiddenAccessQuery';
import type { GetAllSearchesForUser } from '@type/favorites/allSearchesForUser';
import type { FC, PropsWithChildren } from 'react';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useClient, useQuery } from 'urql';

import { GET_SAVED_SEARCHES_QUERY } from './graphql/queries/GetSavedSearchesQuery';
import { normalizeSavedSearchesArrayToTypeName } from './helpers/normalizeSavedSearchesArrayToTypeName';
import { SAVED_SEARCHES_TYPE_NAMES } from './savedSearchesTypeName';

type PartialUseAssertGraphQlPropsGeneric = Pick<
    UseAssertGraphQlPropsGeneric,
    'fetching' | 'graphqlError' | 'logErrorPrefix' | 'onTypeMismatch' | 'operation' | 'onUserError' | 'pause'
>;

interface SavedSearchesContextProps {
    addToSavedSearches: (savedSearch: GetAllSearchesForUser) => void;
    isFetchingSavedSearches: boolean;
    removeFromSavedSearches: (id: number) => void;
    savedSearches: GetAllSearchesForUser[] | null;
}

export const SavedSearchesContext = createContext<SavedSearchesContextProps>({
    addToSavedSearches: () => undefined,
    isFetchingSavedSearches: false,
    removeFromSavedSearches: () => undefined,
    savedSearches: null,
});

const USER_SAVED_SEARCHES_CONTEXT = { additionalTypenames: SAVED_SEARCHES_TYPE_NAMES };
const EXPECTED_SAVED_SEARCHES_TYPENAME = ['SavedSearches'] as const;
const EXPECTED_SAVED_SEARCHES_OLD_TYPENAME = ['SavedSearches'] as const;
const SAVED_SEARCHES_DEFAULT_DATA: GetAllSearchesForUser[] = [];

export const SavedSearchesContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const {
        featureFlags: { isOldSaveSearchQueryEnabled },
    } = useSiteSettings();
    const [savedSearches, setSavedSearches] = useState<GetAllSearchesForUser[] | null>(null);
    const urqlClient = useClient();
    const shouldPauseQuery = useShouldPauseQueryForLogoutUser();
    const [{ data, error: graphqlError, fetching, operation }] = useQuery({
        query: GET_SAVED_SEARCHES_QUERY,
        pause: shouldPauseQuery,
        context: USER_SAVED_SEARCHES_CONTEXT,
        variables: { isOldSaveSearchQueryEnabled: !!isOldSaveSearchQueryEnabled },
    });

    const commonUserDataConfig: PartialUseAssertGraphQlPropsGeneric = {
        fetching,
        graphqlError,
        logErrorPrefix: '[SavedSearchesContext]',
        onTypeMismatch: 'DO_NOTHING', // We have to do nothing as instead of typename, we receive here an error 'Forbidden Access'
        operation,
        pause: shouldPauseQuery,
        onUserError: 'CHECK_AND_DO_NOTHING',
    };
    const normalizedData = useMemo(
        // We have to use useMemo to avoid multiple error logging
        () => ({
            allSearchesForUser: normalizeSavedSearchesArrayToTypeName(
                normalizeForbiddenAccessQuery(data?.allSearchesForUser, graphqlError),
            ),
            allSearchesForUserOld: normalizeSavedSearchesArrayToTypeName(
                normalizeForbiddenAccessQuery(data?.allSearchesForUserOld, graphqlError),
            ),
        }),
        [data, graphqlError],
    );
    const allSavedSearches = useAssertGraphqlResponse({
        ...commonUserDataConfig,
        data: normalizedData.allSearchesForUser,
        expectedTypenames: EXPECTED_SAVED_SEARCHES_TYPENAME,
        pause: shouldPauseQuery,
        onTypeMismatch: isOldSaveSearchQueryEnabled ? 'DO_NOTHING' : 'LOG_ERROR',
    });
    const allSavedSearchesOld = useAssertGraphqlResponse({
        ...commonUserDataConfig,
        data: normalizedData.allSearchesForUserOld,
        expectedTypenames: EXPECTED_SAVED_SEARCHES_OLD_TYPENAME,
        pause: shouldPauseQuery,
        onTypeMismatch: isOldSaveSearchQueryEnabled ? 'LOG_ERROR' : 'DO_NOTHING',
    });

    const joinedSavedSearchesResponse: GetAllSearchesForUser[] =
        allSavedSearches?.items || allSavedSearchesOld?.items || SAVED_SEARCHES_DEFAULT_DATA;

    useEffect(() => {
        if (fetching) {
            return;
        }
        if (joinedSavedSearchesResponse.length === 0) {
            setSavedSearches(joinedSavedSearchesResponse);

            return;
        }

        let isMounted = true;
        getSavedSearchesWithNewLocations(joinedSavedSearchesResponse, urqlClient).then(
            (savedSearchesWithNewLocation) => {
                if (!isMounted) {
                    return;
                }
                setSavedSearches(savedSearchesWithNewLocation);
            },
        );

        return (): void => {
            isMounted = false;
        };
    }, [fetching, joinedSavedSearchesResponse, urqlClient]);

    const addToSavedSearches = useCallback((savedSearches: GetAllSearchesForUser) => {
        setSavedSearches((previousState) => [...(previousState || []), savedSearches]);
    }, []);

    const removeFromSavedSearches = useCallback((id: number) => {
        setSavedSearches((previousState) =>
            previousState ? previousState.filter((item) => item.timestamp !== id) : previousState,
        );
    }, []);

    const value = useMemo(
        () => ({
            addToSavedSearches,
            isFetchingSavedSearches: fetching,
            removeFromSavedSearches,
            savedSearches,
        }),
        [addToSavedSearches, fetching, removeFromSavedSearches, savedSearches],
    );

    return <SavedSearchesContext.Provider value={value}>{children}</SavedSearchesContext.Provider>;
};
