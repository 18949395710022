import type { FieldsMetadataExperimentsVariants } from '@type/search/fieldsMetadataExperimentsVariants';
import dynamic from 'next/dynamic';
import type { ReactElement } from 'react';

const LazySaveSearchNotLoggedInModal = dynamic(
    () => import('@domains/search/components/SaveSearchNotLoggedInModal/SaveSearchNotLoggedInModal'),
    { ssr: false },
);

const LazyRegistrationModalWrapper = dynamic(
    () => import('@domains/search/components/RegistrationModal/RegistrationModalWrapper'),
    { ssr: false },
);

export type ModalVariant = 'NotLoggedInModal' | 'RegistrationModal' | null;

interface SaveSearchModalProps {
    currentModal: ModalVariant;
    onClose(): void;
    onRegisterModalOpen(): void;
    afterLoginRedirectPath: string;
    extraTrackingData?: Record<string, unknown>;
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants;
}

export const SaveSearchModal = ({
    currentModal,
    onClose,
    onRegisterModalOpen,
    extraTrackingData,
    afterLoginRedirectPath,
    fieldsMetadataExperimentsVariants,
}: SaveSearchModalProps): ReactElement | null => {
    if (!currentModal) {
        return null;
    }

    const commonProps = {
        isOpen: true,
        afterLoginRedirectPath,
        trackingData: extraTrackingData,
    };

    const content =
        currentModal === 'NotLoggedInModal' ? (
            <LazySaveSearchNotLoggedInModal
                {...commonProps}
                fieldsMetadataExperimentsVariants={fieldsMetadataExperimentsVariants}
                showRegistrationModal={onRegisterModalOpen}
                onClose={onClose}
            />
        ) : (
            <LazyRegistrationModalWrapper
                {...commonProps}
                fieldsMetadataExperimentsVariants={fieldsMetadataExperimentsVariants}
                closeModal={onClose}
            />
        );

    return content;
};
