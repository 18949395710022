import styled from '@emotion/styled';

export const StyledList = styled.ul`
    margin: 22px 0;
    padding: 0;
    list-style: none;

    & > li + li {
        margin-top: 16px;
    }
`;
