import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Banner as NexusBanner } from '@nexus/lib-react/dist/core/Banner';
import { Button as NexusButton } from '@nexus/lib-react/dist/core/Button';
import { P3 } from '@nexus/lib-react/dist/core/Typography';

const ROW_LAYOUT_BREAKPOINT = theme.mediaQueries.smMin;

export const TextColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-self: flex-start;
`;

export const ActionsColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media ${ROW_LAYOUT_BREAKPOINT} {
        width: auto;
        margin-left: auto;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;

    @media ${ROW_LAYOUT_BREAKPOINT} {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`;

export const Subtitle = styled(P3)`
    max-width: 260px;
    margin-bottom: ${theme.space['16']};

    @media ${ROW_LAYOUT_BREAKPOINT} {
        display: inline-block;
        max-width: unset;
        margin-bottom: 0;
    }
`;

export const Banner = styled(NexusBanner)`
    @media ${ROW_LAYOUT_BREAKPOINT} {
        display: inline-block;
        margin-bottom: 0;
    }
`;

export const Button = styled(NexusButton)`
    width: auto;

    @media ${ROW_LAYOUT_BREAKPOINT} {
        width: auto;
    }
`;
