import type { Estate } from '@type/search/filters/estate';
import { ESTATE } from '@type/search/filters/estate';
import type { LocationsObjects } from '@type/search/location/dataItems';
import type { SearchingFilters } from '@type/search/searchingFilters';

interface ShouldGenerateSchemaMarkupProps {
    filterAttributes: Partial<Omit<SearchingFilters, 'locations' | 'limit'>>;
    locationsObjects: LocationsObjects[] | null;
}

const EXPECTED_ESTATES: Set<Estate> = new Set([
    ESTATE.flat,
    ESTATE.house,
    ESTATE.studioFlat,
    ESTATE.hall,
    ESTATE.garage,
    ESTATE.terrain,
]);
const EXPECTED_PROPERTIES = ['market', 'estate', 'transaction', 'page'];
const OPTIONAL_PROPERTIES = new Set(['distanceRadius', 'roomsNumber', 'ownerTypeSingleSelect']);

export const shouldGenerateSchemaMarkup = ({
    filterAttributes,
    locationsObjects,
}: ShouldGenerateSchemaMarkupProps): boolean => {
    const isValidLocation = locationsObjects === null || locationsObjects.length === 0 || locationsObjects.length === 1;
    const isValidEstate = !!(filterAttributes.estate && EXPECTED_ESTATES.has(filterAttributes.estate));
    const isFirstPage = filterAttributes.page === 1;
    const properties = Object.keys(filterAttributes);
    const hasAllExpectedProperties = EXPECTED_PROPERTIES.every((property) => properties.includes(property));
    const hasNoUnexpectedProperties = properties.every(
        (property) => EXPECTED_PROPERTIES.includes(property) || OPTIONAL_PROPERTIES.has(property),
    );

    return isValidLocation && isValidEstate && isFirstPage && hasAllExpectedProperties && hasNoUnexpectedProperties;
};
