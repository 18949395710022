import Loading from '@nexus/lib-react/dist/core/Loading/DotsLoader';
import type { JSX } from 'react';

import { ImageLoadingSlideWrapper, LoaderWrapper } from '../GallerySection.theme';

export const ImageLoadingSlide = (): JSX.Element => {
    return (
        <ImageLoadingSlideWrapper>
            <LoaderWrapper>
                <Loading size={24} />
            </LoaderWrapper>
        </ImageLoadingSlideWrapper>
    );
};
