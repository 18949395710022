import type { SegmentType } from '@type/search/segmentTypes';
import type { URLSegment } from '@type/search/urlSegments';
import { getSegmentsByType } from '@widgets/search/getSegment';

export const findSegmentByLabel = <T = string>(segments: string[], segmentType: SegmentType): T | null => {
    // Get all possible segments
    const segmentsForPlatform = getSegmentsByType(segmentType);

    if (!segmentsForPlatform) {
        return null;
    }

    for (const segment of segments) {
        const result: URLSegment | undefined = segmentsForPlatform.find(
            (element: URLSegment) => element.label === segment,
        );

        if (result) {
            return result.value as T;
        }
    }

    return null;
};
