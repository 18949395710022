import type { AdvertListItem } from '@domains/search/types/advertListItem';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useContext, useMemo } from 'react';

const MOBILE_INITIAL_RESULTS = 3;
const DESKTOP_INITIAL_RESULTS = 10;
const FEATURED_DEVELOPMENT_SECTION_START_INDEX = 5;

export const useListingChunks = (
    items: AdvertListItem[],
): {
    firstChunk: AdvertListItem[];
    secondChunk: AdvertListItem[] | undefined;
    secondChunkStartIndex: number;
    resultsRenderedOnInit: number;
} => {
    const { isDesktop } = useContext(RWDContext);
    const { featureFlags } = useSiteSettings();
    const { isFeaturedDevelopmentVASEnabled } = featureFlags;

    const { firstChunk, secondChunk } = useMemo(() => {
        const shouldIncludeAll = isDesktop || !isFeaturedDevelopmentVASEnabled;

        return {
            firstChunk: shouldIncludeAll ? items : items.slice(0, FEATURED_DEVELOPMENT_SECTION_START_INDEX),
            secondChunk: shouldIncludeAll ? undefined : items.slice(FEATURED_DEVELOPMENT_SECTION_START_INDEX),
        };
    }, [isDesktop, isFeaturedDevelopmentVASEnabled, items]);

    const resultsRenderedOnInit = isDesktop ? DESKTOP_INITIAL_RESULTS : MOBILE_INITIAL_RESULTS;

    return {
        firstChunk,
        secondChunk,
        secondChunkStartIndex: firstChunk.length,
        resultsRenderedOnInit,
    };
};
