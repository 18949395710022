import { logError, logWarn } from '@domains/shared/helpers/logger';
import { checkIsUnauthorizedError } from '@lib/graphql/checkIsUnauthorizedError';
import type { FilterOutGraphqlErrorParams } from '@lib/graphql/filterOutGraphqlError';
import { filterOutGraphqlError } from '@lib/graphql/filterOutGraphqlError';
import type { GraphQLError } from 'graphql';
import type { CombinedError, OperationContext } from 'urql';

type LogExtraData = Record<string, unknown> | (() => Record<string, unknown>);

const getLogExtraDataValue = (data?: LogExtraData): Record<string, unknown> | undefined => {
    if (typeof data === 'function') {
        return data();
    }

    return data;
};
const getLogFetchOptions = (fetchOptions?: OperationContext['fetchOptions']): RequestInit | undefined => {
    if (typeof fetchOptions === 'function') {
        return fetchOptions();
    }

    return fetchOptions;
};

/**
 * A helper to abstract logic connected with GraphQL error logging and errors filtering.
 *
 * By default, it throws an exception on network error, to change that behaviour,
 * set a custom value to `onNetworkError` option.
 *
 * To be used only in a server application (e.g. in getServerSideProps)!
 *
 * @deprecated Please use 'assertGraphqlResponse' and follow 'Migration to type union response approach' defined in `frontend/lib/graphql/README.md`.
 */
export const handleSsrQueryGraphQlErrors = (
    graphQlError: CombinedError | undefined,
    {
        logErrorPrefix = '[handleSsrQueryGraphQlErrors]',
        logExtraData,
        errorsToFilterOut = [],
        onNetworkError = 'THROW_EXCEPTION',
        urqlOptions,
    }: {
        logErrorPrefix?: string;
        logExtraData?: LogExtraData;
        errorsToFilterOut?: FilterOutGraphqlErrorParams[];
        onNetworkError?: 'DO_NOTHING' | 'THROW_EXCEPTION' | 'DO_NOTHING_AND_SKIP_UNAUTHORIZED_ERROR';
        urqlOptions?: Partial<OperationContext>;
    } = {},
): GraphQLError[] => {
    if (!graphQlError) {
        return [];
    }
    const fetchOptions = urqlOptions?.fetchOptions;

    // Check network error
    if (graphQlError.networkError) {
        // Network error occurred. Log to stderr and do not throw an error - page still should render
        if (onNetworkError !== 'DO_NOTHING_AND_SKIP_UNAUTHORIZED_ERROR' || !checkIsUnauthorizedError(graphQlError)) {
            // Use a warn level to keep an error in Sentry Breadcrumbs but do not duplicate it with "thrown" one
            const logMethod = onNetworkError === 'THROW_EXCEPTION' ? logWarn : logError;

            logMethod(`${logErrorPrefix} Network error occurred`, {
                ...getLogExtraDataValue(logExtraData),
                error: graphQlError,
                fetchOptions: getLogFetchOptions(fetchOptions),
            });
        }
        if (onNetworkError === 'THROW_EXCEPTION') {
            const error = new Error(`${logErrorPrefix} Network error occurred - ${graphQlError.networkError.message}`);
            error.stack = graphQlError.networkError.stack || error.stack;
            // Throw a global error when the core request couldn't be fetched
            throw error;
        }
    }

    // Non-core specific errors
    let errors = graphQlError.graphQLErrors ?? [];

    // -> Filter out accepted (expected) errors (like 403 when fetching a user data when it is unlogged)
    for (const errorToFilterOut of errorsToFilterOut) {
        errors = filterOutGraphqlError(errors, errorToFilterOut);
    }

    if (errors.length > 0) {
        // Some graphql errors occurs. Log to stderr and go further
        logError(`${logErrorPrefix} GraphQL error occurred`, {
            ...getLogExtraDataValue(logExtraData),
            error: errors,
            fetchOptions: getLogFetchOptions(fetchOptions),
        });
    }

    return errors;
};
