import { areLocations } from '@domains/search/hooks/useSaveSearches/areLocations';
import type { FormValues } from '@type/search/searchFormValues';

const areLocationsIds = (locations: unknown): locations is string[] => {
    return Array.isArray(locations) && locations.every((id) => typeof id === 'string');
};

export const getFilteredLocations = (locations: FormValues['locations']): { domainId: string }[] => {
    if (areLocations(locations)) {
        return locations.map(({ id }) => ({ domainId: id }));
    }

    if (areLocationsIds(locations)) {
        return locations.map((id) => ({ domainId: id }));
    }

    if (typeof locations === 'string' && locations.length > 0) {
        return [{ domainId: locations }];
    }

    return [];
};
