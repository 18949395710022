import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

// FIXME: borders-brand-tertiary is available - change this when updated.
const ASIDE_INFO_CONTENT_BORDER_COLOR = '#D3D5D9';

export const InfoContainer = styled.section`
    display: flex;
    flex-direction: column;
    padding: ${theme.space[16]};
    border-top: 1px solid ${ASIDE_INFO_CONTENT_BORDER_COLOR};
    background-color: ${theme.colors['background-global-primary']};
    gap: ${theme.space[16]};
`;

export const InfoHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${theme.space[16]};
`;
