import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Container = styled.div`
    display: flex;
    align-items: center;
    gap: ${theme.sizes[12]};
`;

export const ImageWrapper = styled.img<{ isSmall?: boolean }>`
    display: block;
    width: ${({ isSmall }): string => (isSmall ? theme.sizes[40] : 'auto')};
    max-width: ${({ isSmall }): string => (isSmall ? 'initial' : theme.sizes[72])};
    height: ${theme.sizes[40]};
    border-radius: ${theme.radii[2]};
    object-fit: contain;
    background-color: ${theme.colors['background-global-primary']};
`;

export const TextWrapper = styled.div`
    display: grid;
    justify-items: start;
    min-width: 0;
    margin: 0;
    color: ${theme.colors['text-global-secondary']};
    font-size: ${theme.fontSizes.p5};
    font-weight: ${theme.fontWeights[600]};
`;

export const OwnerName = styled.div`
    display: block;
    width: 100%;
    overflow: hidden;
    background: none;
    color: ${theme.colors['text-global-secondary']};
    font-size: ${theme.fontSizes.p5};
    font-weight: ${theme.fontWeights[700]};
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const OwnerType = styled.div`
    display: block;
    color: ${theme.colors['text-global-secondary']};
    font-weight: ${theme.fontWeights[400]};
`;

export const AsideInfoContainer = styled.div`
    align-self: flex-end;
    margin-left: auto;
`;
