import { CURRENT_SITE_CODE } from '@config/siteCode';
import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { theme } from '@lib/styles/themes/nexus';
import type { JSX } from 'react';

import { MapImage, StyledPicture } from './MapBackground.theme';

const { staticAssetsPrefix } = getPublicEnvConfig();
const BACKGROUND_DIRECTORY = `${staticAssetsPrefix}/images/search/map/${CURRENT_SITE_CODE}`;

export const MapBackground = ({ images }: { images: string[] }): JSX.Element => {
    const [t] = useTranslations();

    /** Local image to limit static maps API requests */
    const fallbackDefaultSource = `${BACKGROUND_DIRECTORY}/backgroundRectangleShort.webp`;

    return (
        <StyledPicture>
            {getSources(images).map(([media, source]) => (
                <source key={media} media={media} srcSet={source} data-testid="map-background-source" />
            ))}
            <MapImage src={fallbackDefaultSource} alt={t('frontend.search.form.map-button')} />
        </StyledPicture>
    );
};

const getSources = (images: string[]): [string, string][] => {
    let sources: Record<string, string> = {};

    // When API couldn't get images, e.g. when error happened.
    if (images.length < 3) {
        sources = {
            [theme.mediaQueries.lgMin]: `${BACKGROUND_DIRECTORY}/backgroundSquareEmptyBottom.webp`,
        };
    }

    // Dynamic preview for all viewports.
    if (images.length === 3) {
        sources = {
            [theme.mediaQueries.md]: images[0],
            [`${theme.mediaQueries.mdMin} and ${theme.mediaQueries.lg}`]: images[1],
            [theme.mediaQueries.lgMin]: images[2],
        };
    }

    return Object.entries(sources);
};
