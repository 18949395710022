import type { Investment } from '@domains/search/types/investment';
import { Skeleton } from '@domains/shared/components/Skeleton/Skeleton';
import { useIntersection } from '@domains/shared/hooks/useIntersection/useIntersection';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import dynamic from 'next/dynamic';
import type { JSX } from 'react';
import { useRef, useState } from 'react';

import { Content, Title } from './InvestmentsWidget.theme';

const INVESTMENT_CONTAINER_SKELETON = (
    <>
        <Skeleton height={250} />
        <Skeleton height={250} style={{ marginTop: 16 }} />
        <Skeleton height={250} style={{ marginTop: 16 }} />
    </>
);

const LazyInvestmentContainer = dynamic(() => import('./components/InvestmentsContainer'), {
    loading: () => INVESTMENT_CONTAINER_SKELETON,
});

interface Props {
    items: Investment[];
    startIndex?: number;
}

export const InvestmentsWidget = ({ items, startIndex = 0 }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { featureFlags } = useSiteSettings();
    const { isFeaturedDevelopmentVASEnabled } = featureFlags;

    const containerRef = useRef<HTMLDivElement>(null);
    const [shouldShowInvestments, setShouldShowInvestments] = useState(false);

    const titleKey = isFeaturedDevelopmentVASEnabled
        ? 'frontend.search.featured-developments-section.title'
        : 'frontend.search.investments-widget.title';

    useIntersection(
        containerRef,
        () => {
            setShouldShowInvestments(true);
        },
        { threshold: 0.2 },
    );

    return (
        <section ref={containerRef}>
            <Title>{t(titleKey)}</Title>
            <Content>
                {shouldShowInvestments ? (
                    <LazyInvestmentContainer startIndex={startIndex} items={items} />
                ) : (
                    INVESTMENT_CONTAINER_SKELETON
                )}
            </Content>
        </section>
    );
};
