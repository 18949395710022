import { CONTACT_FORM_TYPE } from '@domains/shared/consts/contactFormType';
import { listenForPlatformEvent, unmountPlatformEvent } from '@lib/events';
import type { CustomEventData } from '@type/contactModal/customEventData';
import { ContactModal } from '@widgets/contactForm/ContactModal';
import { ContactPersonBanner } from '@widgets/contactForm/ContactPersonBanner';
import type { JSX } from 'react';
import { useEffect, useState } from 'react';

export const ContactModalController = (): JSX.Element | null => {
    const [shouldShowModal, setShouldShowModal] = useState(false);
    const [contactModalVariant, setContactModalVariant] = useState<'listingGallery' | 'agency'>('listingGallery');
    const [advertContactDetails, setAdvertContactDetails] = useState<CustomEventData>();

    const handleModalOpen = ({ detail }: CustomEvent<CustomEventData>): void => {
        setAdvertContactDetails(detail);
        setShouldShowModal(true);
    };

    const handleModalClose = (): void => {
        if (contactModalVariant === 'agency') {
            setContactModalVariant('listingGallery');

            return;
        }

        setContactModalVariant('listingGallery');
        setShouldShowModal(false);
    };

    const handlePhoneNumberClick = (): void => {
        if (advertContactDetails?.ownerType === 'agency') {
            setContactModalVariant('agency');
        }
    };

    useEffect(() => {
        listenForPlatformEvent('openContactModalOnSearchPage', handleModalOpen);

        return (): void => {
            unmountPlatformEvent('openContactModalOnSearchPage', handleModalOpen);
        };
    }, []);

    if (!shouldShowModal || !advertContactDetails) {
        return null;
    }

    return (
        <ContactModal
            beforeContactForm={
                <ContactPersonBanner
                    advertId={advertContactDetails.advertId}
                    contactDetails={advertContactDetails.contactDetails}
                    ownerType={advertContactDetails.ownerType}
                    advertiserType={advertContactDetails.advertiserType}
                    agencyLogoUrl={advertContactDetails.agency?.imageUrl}
                    contactFormVariant="listingGallery"
                    onPhoneNumberClick={handlePhoneNumberClick}
                    additionalTrackingData={{ contact_form_type: CONTACT_FORM_TYPE.listingPage }}
                />
            }
            variant={contactModalVariant}
            {...advertContactDetails}
            onDimiss={handleModalClose}
            additionalTrackingData={{ contact_form_type: CONTACT_FORM_TYPE.listingPage }}
        />
    );
};
