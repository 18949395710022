import type { GraphQLError } from 'graphql';

interface ByCodeParams {
    codeToFilter: number;
    pathToFilter: string[];
}

interface ByStringParams {
    pathToFilter: string[];
    stringToFilter: string;
}

export type FilterOutGraphqlErrorParams = ByCodeParams | ByStringParams;

const checkDoesMessageOrStatusCodeMatch = (filterParams: FilterOutGraphqlErrorParams, error: GraphQLError): boolean => {
    if ('stringToFilter' in filterParams) {
        return error.message.includes(filterParams.stringToFilter);
    }

    // checking for legacy top level error
    // It could be "...StatusCode:..." or "...code:..." depending on the implementation
    return error.message.toLowerCase().endsWith(`code: ${filterParams.codeToFilter}`);
};

/**
 * Filters out a GraphQL error
 * that is not an actual error but an expected behavior for the frontend
 */
export const filterOutGraphqlError = (
    errors: GraphQLError[],
    filterParams: FilterOutGraphqlErrorParams,
): GraphQLError[] => {
    const { pathToFilter } = filterParams;

    return errors.filter((error) => {
        if (!checkDoesMessageOrStatusCodeMatch(filterParams, error)) {
            return true;
        }

        const errorPath = error.path || [];
        if (errorPath.length !== pathToFilter.length) {
            return true;
        }

        return errorPath.some((path, index) => path !== pathToFilter[index]);
    });
};
