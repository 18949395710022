import type { AdvertAgency } from '@type/ad/advertAgency';
import type { AdContactDetails } from '@type/contactForm/advertContactDetails';
import dynamic from 'next/dynamic';
import type { JSX } from 'react';

import { ContactFormWrapper } from '../ContactFormWrapper';
import type { ContactFormProps } from '../types/contactForm';
import type { ContactModalVariant } from '../types/contactModal';
import { ContactModalWrapper } from './ContactModal.theme';

const LazyAgencyContactInfoModal = dynamic(
    () => import('@widgets/contactForm/ContactModal/components/AgencyContactInfoModal'),
    {
        ssr: false,
    },
);

type Props = Omit<ContactFormProps, 'variant'> & {
    agency: AdvertAgency;
    contactDetails: AdContactDetails;
    variant: ContactModalVariant;
    afterContactForm?: JSX.Element | null;
    beforeContactForm?: JSX.Element | null;
    onDimiss(): void;
    onSubmit?(): void;
    additionalTrackingData?: Record<string, unknown>;
};

export const ContactModal = ({ agency, contactDetails, variant, onDimiss, ...restProps }: Props): JSX.Element => {
    if (variant === 'agency') {
        return (
            <LazyAgencyContactInfoModal
                agency={agency}
                contactDetails={contactDetails}
                isVisible
                onDismiss={onDimiss}
            />
        );
    }

    const isAskForMissingInfo = variant === 'contactFromMissingInfo';
    const isSuggestPrice = variant === 'suggestPriceContact';
    const isAskAboutPrice = variant === 'askAboutPriceContact';
    const contactFormVariant = variant === 'listingGallery' ? 'listingGallery' : 'default';

    return (
        <ContactModalWrapper onDismiss={onDimiss} isVisible shouldBeNarrow={isAskAboutPrice}>
            <ContactFormWrapper
                askForMissingInfo={isAskForMissingInfo}
                askAboutPrice={isAskAboutPrice}
                isModal={true}
                shouldDisplayGdprInline={true}
                suggestPrice={isSuggestPrice}
                variant={contactFormVariant}
                {...restProps}
            />
        </ContactModalWrapper>
    );
};

export default ContactModal;
