import { SITE_CONFIG } from '@config/siteConfig';
import { EXPERIMENT } from '@domains/search/consts/experiment';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { usePhoneNumber } from '@domains/shared/hooks/usePhoneNumber/usePhoneNumber';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { dispatchPlatformEvent } from '@lib/events';
import { useExperiments } from '@lib/experiments/client/ExperimentsProvider';
import { usePhoneRevealStatistics } from '@lib/tracking/usePhoneRevealStatistics';
import { useTracking } from '@lib/tracking/useTracking';
import CallIcon from '@nexus/lib-react/dist/core/Icon/icons/default/Call';
import EmailIcon from '@nexus/lib-react/dist/core/Icon/icons/default/Email';
import type { AdvertAgency } from '@type/ad/advertAgency';
import type { JSX } from 'react';
import { useContext, useState } from 'react';

import type { AdvertContactData } from '../types/data';
import {
    ContactSlideContent,
    ContactSlideText,
    ContactSlideWrapper,
    StyledContactButton,
    StyledEmailContactButton,
} from './ContactSlide.theme';

const { phoneNumbers } = SITE_CONFIG;
const { country, countryCode } = phoneNumbers;

export const ContactSlide = ({
    advertId,
    image,
    contactData,
    trackingSet,
    agency,
    isMapView,
}: {
    advertId: number;
    image: string | undefined;
    contactData: AdvertContactData | undefined;
    trackingSet: Record<string, unknown>;
    agency?: AdvertAgency;
    isMapView?: boolean;
}): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const { isDesktop } = useContext(RWDContext);
    const { updateShowPhoneStatistics } = usePhoneRevealStatistics();

    const { isVariantEnabled } = useExperiments();
    const isEURE27170Variant =
        isVariantEnabled(EXPERIMENT.eure27170, 'b') || isVariantEnabled(EXPERIMENT.eure27170, 'c');
    const isFixedWidth = isVariantEnabled(EXPERIMENT.eure27170, 'c') && isMapView;

    const [shouldShowPhoneNumber, setShouldShowPhoneNumber] = useState(false);

    const [phone] = contactData?.contactDetails.phones || [];

    const { phoneNumber } = usePhoneNumber({
        number: phone ?? '',
        country,
        nationalCountryCode: countryCode,
    });

    const handleCallButtonClick = (): void => {
        if (!phone) {
            return;
        }

        const commonTrackingData = {
            ...trackingSet,
            touch_point_button: 'listing_gallery',
        };

        if (!isDesktop || shouldShowPhoneNumber) {
            window.open(`tel: ${phone}`, '_self');
            trackEvent('reply_phone_call', commonTrackingData);
        } else {
            setShouldShowPhoneNumber(true);
            trackEvent('reply_phone_show', commonTrackingData);
            updateShowPhoneStatistics({
                advertId,
            });
        }
    };

    const handleContactButtonClick = (): void => {
        const contactDataWithAdvertId = {
            advertId,
            agency,
            ...contactData,
        };

        dispatchPlatformEvent('openContactModalOnSearchPage', contactDataWithAdvertId);

        trackEvent('reply_message_form_click', {
            ...trackingSet,
            touch_point_button: 'listing_gallery',
        });
    };

    const desktopPhoneButton =
        isEURE27170Variant && isMapView
            ? t('frontend.search.slide-with-contact-overlay.shorten-show-number')
            : t('frontend.search.slide-with-contact-overlay.show-number');

    const phoneButtonCopy = isDesktop ? desktopPhoneButton : t('frontend.search.slide-with-contact-overlay.call');

    return (
        <ContactSlideWrapper image={image}>
            <ContactSlideContent>
                <ContactSlideText>
                    <span>{t('frontend.search.slide-with-contact-overlay.headline')}</span>
                    {phone ? (
                        <StyledContactButton
                            prefixIcon={CallIcon}
                            background="inverse"
                            size="small"
                            variant="secondary"
                            disabled={!contactData}
                            onClick={handleCallButtonClick}
                            isFixedWidth={isFixedWidth}
                        >
                            {shouldShowPhoneNumber ? phoneNumber : phoneButtonCopy}
                        </StyledContactButton>
                    ) : null}

                    <StyledEmailContactButton
                        prefixIcon={EmailIcon}
                        background="inverse"
                        size="small"
                        variant="secondary"
                        disabled={!contactData}
                        onClick={handleContactButtonClick}
                        isFixedWidth={isFixedWidth}
                    >
                        {t('frontend.search.slide-with-contact-overlay.message')}
                    </StyledEmailContactButton>
                </ContactSlideText>
            </ContactSlideContent>
        </ContactSlideWrapper>
    );
};
