import { getMapSphereLocationsQuery } from '@domains/search/graphql/queries/getMapSphereLocationsQuery';
import { MAP_LOCATION_SERVICE_OBJECTS_QUERY } from '@domains/search/graphql/queries/MapLocationServiceObjects';
import type { MappingResult } from '@domains/search/types/MappingResult';
import { MAPPING_KEY } from '@domains/search/types/MappingResult';
import { handleSsrQueryGraphQlErrors } from '@lib/graphql/ssr/handleSsrQueryGraphQlErrors';
import type { GetUrqlOptionsContext } from '@lib/graphql/ssr/helpers/getBasicUrqlOptions';
import type { ExecuteQueryCookiesOptions, ExecuteSsrQuery } from '@lib/graphql/types';
import type { LocationTypeValue } from '@type/search/locationType';
import { LOCATION_TYPE } from '@type/search/locationType';
import type { DocumentNode } from 'graphql/index';
import type { AnyVariables, Client, OperationContext, OperationResult, TypedDocumentNode } from 'urql';

interface MappedIds {
    id: string;
    originalId?: string;
    urlPath?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- "any" - type compatible with useQuery and this function is a wrapper
const executeQuery = <Data = any, Variables extends AnyVariables = AnyVariables>(
    executeSsrQuery: ExecuteSsrQuery | undefined,
    clientQuery: Client['query'] | undefined,
    options: {
        context?: Partial<OperationContext>;
        query: DocumentNode | TypedDocumentNode<Data, Variables>;
        variables: Variables;
    } & ExecuteQueryCookiesOptions,
): Promise<OperationResult<Data> & { basicUrqlOptions?: GetUrqlOptionsContext }> => {
    if (clientQuery) {
        return clientQuery(options.query, options.variables, options.context).toPromise();
    }
    if (executeSsrQuery) {
        return executeSsrQuery(options);
    }
    throw new TypeError('[getIdsFromQueryAndMapIdsByParentId] Missing URQL client');
};

/* Map IDs to the correct type corresponding with the A/B test group */
export const getIdsFromQueryAndMapIdsByParentId = async ({
    executeSsrQuery,
    clientQuery,
    ids,
    source,
    target,
    includeOriginalIds = false,
    trigger,
    referringURL,
}: {
    executeSsrQuery?: ExecuteSsrQuery;
    clientQuery?: Client['query'];
    ids: string | string[];
    source: string;
    target: LocationTypeValue;
    includeOriginalIds?: boolean;
    trigger?: string;
    referringURL?: string;
}): Promise<MappedIds[] | null> => {
    /* Each source requires either a different query or a separate filter */
    const mappingQuery =
        target === LOCATION_TYPE.domainId ? getMapSphereLocationsQuery(source) : MAP_LOCATION_SERVICE_OBJECTS_QUERY;

    const idToUse = source !== LOCATION_TYPE.urlPath && !Array.isArray(ids) ? [ids] : ids;

    const { basicUrqlOptions, data, error } = await executeQuery<MappingResult>(executeSsrQuery, clientQuery, {
        query: mappingQuery,
        variables: {
            locationsToMap: idToUse,
        },
    });

    handleSsrQueryGraphQlErrors(error, {
        logErrorPrefix: '[SearchPage][getIdsFromQueryAndMapIdsByParentId]',
        urqlOptions: basicUrqlOptions,
        onNetworkError: 'DO_NOTHING',
        logExtraData: {
            idsToMap: idToUse,
            ...(trigger ? { trigger } : {}),
            ...(referringURL ? { referringURL } : {}),
        },
    });

    const queryName = target === 'domain' ? MAPPING_KEY.mapSphereLocations : MAPPING_KEY.mapLocationServiceObjects;

    if (!data || error) {
        return null;
    }

    const { mappedLocations } = data[queryName];

    const isMappedResponseEmpty = !Array.isArray(mappedLocations) || mappedLocations.length === 0;

    if (isMappedResponseEmpty) {
        return null;
    }

    const idParentKey = target === 'domain' ? 'locationServiceObject' : 'sphereLocationObject';
    const originalIdParentKey = target === 'domain' ? 'sphereLocationObject' : 'locationServiceObject';

    return mappedLocations.map((item) => ({
        id: item[idParentKey].id as string,
        ...(includeOriginalIds && { originalId: item[originalIdParentKey].id as string }),
        ...(typeof item[idParentKey].urlPath === 'string' && { urlPath: item[idParentKey].urlPath }),
    }));
};
