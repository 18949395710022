import styled from '@emotion/styled';
import { LAYER } from '@lib/styles/partials/layers';
import { theme } from '@lib/styles/themes/nexus';
import { Container } from '@nexus/lib-react/dist/core/Layout';

export const FormBackground = styled.div`
    display: none;
    z-index: ${LAYER.x1};
    width: 100%;
    height: auto;
    background-color: ${theme.colors['background-global-primary']};

    @media ${theme.mediaQueries.lgMin} {
        display: flex;
        padding: ${theme.space[24]} 0;
    }
`;

export const FormContainer = styled(Container)`
    padding: 0 ${theme.space[8]};

    @media ${theme.mediaQueries.xlMin} {
        padding: 0 ${theme.space[4]};
    }
`;
