import { EXPERIMENT } from '@domains/search/consts/experiment';
import { useMapTracking } from '@domains/search/hooks/useMapTracking';
import { ViewTypeContext } from '@domains/shared/contexts/ViewTypeContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useExperiments } from '@lib/experiments/client/ExperimentsProvider';
import MapViewIcon from '@nexus/lib-react/dist/core/Icon/icons/default/MapView';
import type { JSX } from 'react';
import { forwardRef, useContext } from 'react';

import { StyledButton } from './TriggerButton.theme';

export const TriggerButton = forwardRef<HTMLButtonElement>(function TriggerButton(_, ref): JSX.Element {
    const [t] = useTranslations();
    const { updateViewType } = useContext(ViewTypeContext);

    const { trackViewChange } = useMapTracking();

    const { isVariantEnabled } = useExperiments();
    const shouldUseSearchMap = isVariantEnabled(EXPERIMENT.eure20006, 'b');
    const sendEventToHotjar = (): void => {
        if (shouldUseSearchMap) {
            window.hj?.('event', 'new_map');
        }
    };

    const handleClick = (): void => {
        updateViewType('map');
        sendEventToHotjar();
        trackViewChange({
            listing_format: 'map',
            touch_point_button: 'map_top_listing',
            touch_point_page: 'map',
        });
    };

    return (
        <StyledButton
            ref={ref}
            prefixIcon={MapViewIcon}
            dataAttributes={{ cy: 'search.map.go-to-list-button-listingPanel' }}
            variant="secondary"
            onClick={handleClick}
        >
            {t('frontend.search.listing.see-on-map')}
        </StyledButton>
    );
});
