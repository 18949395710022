import { gql } from '@urql/core';

export const CreateSearchMutation = gql`
    mutation CreateSearch($filterAttributes: CreateSearch!) {
        createSearch(search: $filterAttributes) {
            ... on OperationSearchSuccess {
                success
            }
            ... on OperationSearchError {
                error
            }
        }
    }
`;

export const CreateSearchOldMutation = gql`
    mutation CreateSearchOld($filterAttributes: CreateSearch!) {
        createSearchOld(search: $filterAttributes) {
            ... on OperationSearchSuccess {
                success
            }
            ... on OperationSearchError {
                error
            }
        }
    }
`;
