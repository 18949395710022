import type { AdvertListItem } from '@domains/search/types/advertListItem';
import { getDiscountedMoney } from '@domains/shared/helpers/price/getDiscountedPrice';
import { Price } from '@widgets/AdvertCard/Price';
import type { JSX } from 'react';

export const ConnectedPrice = ({ item }: { item: AdvertListItem }): JSX.Element => {
    const {
        estate,
        transaction,
        developmentId,
        specialOffer,
        totalPrice,
        priceFromPerSquareMeter,
        rentPrice,
        hidePrice,
    } = item;

    const isInvestment = estate === 'INVESTMENT';
    const isInvestmentUnit = !!developmentId;
    const isPriceHidden = !!hidePrice;

    if (isInvestment || isInvestmentUnit) {
        const priceSource = isInvestment ? priceFromPerSquareMeter : totalPrice;
        const discount = specialOffer?.discountValue ?? 0;
        const beforeDiscountPrice = discount ? priceSource : null;
        const maybeDiscountedPrice = priceSource ? getDiscountedMoney(priceSource, discount) : null;

        return (
            <Price
                total={maybeDiscountedPrice}
                beforeDiscount={beforeDiscountPrice}
                direction={isInvestment ? 'vertical' : 'horizontal'}
                isEstimated={isInvestment}
                isHidden={isPriceHidden}
                isPerMeter={isInvestment}
            />
        );
    }

    if (transaction === 'RENT') {
        return <Price total={totalPrice} rent={rentPrice} isHidden={isPriceHidden} />;
    }

    return <Price total={totalPrice} isHidden={isPriceHidden} />;
};
