export const getDisplayedAdsIndexRange = ({
    page,
    limit,
    totalResults,
}: {
    page: number;
    limit: number;
    totalResults: number;
}): string => {
    const startNumber = (page - 1) * limit + 1;
    const endNumber = startNumber + limit - 1;

    if (totalResults < endNumber) {
        return `${startNumber}-${totalResults}`;
    }

    return `${startNumber}-${endNumber}`;
};
