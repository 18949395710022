import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { LAYER } from '@lib/styles/partials/layers';
import { theme } from '@lib/styles/themes/nexus';
import { Container as NexusContainer } from '@nexus/lib-react/dist/core/Layout';

/** Component to negate default padding for shared MainLayout component */
export const NegativeMainLayoutSpacer = styled.div`
    margin-top: -72px;
`;

interface ContentProps {
    shouldMoveToTop: boolean;
}

export const Content = styled.div<ContentProps>`
    position: relative;
    margin-top: ${({ shouldMoveToTop }): string => (shouldMoveToTop ? '-72px' : '0')};
`;

interface SearchModalChildrenProps {
    isSearchFormModalVisible: boolean;
}

export const MobileSearchFormOverlay = styled.div<SearchModalChildrenProps>`
    visibility: ${({ isSearchFormModalVisible }): string => (isSearchFormModalVisible ? 'visible' : 'hidden')};
    position: fixed;
    z-index: ${LAYER.x5};
    inset: 0;
    transition:
        visibility ease-in-out 0.3s,
        opacity ease-in-out 0.3s;
    transition-delay: 0.1s;
    opacity: ${({ isSearchFormModalVisible }): string => (isSearchFormModalVisible ? '1' : '0')};
    background-color: ${({ theme }): string => theme.deprecated.domains.search.overlay.backgroundColor};
`;

export const MainLayoutWrapper = styled.div<SearchModalChildrenProps>`
    display: ${({ isSearchFormModalVisible }): string => (isSearchFormModalVisible ? 'none' : 'block')};
    position: relative;
`;

export const ListingViewContainer = styled.div<{ hasTopPadding: boolean }>`
    position: relative;
    z-index: ${LAYER.x0};

    @media (min-width: ${BREAKPOINT.md}) {
        padding-top: ${({ hasTopPadding }): string => (hasTopPadding ? '48px' : '0px')};
    }
`;

export const NexusBreadcrumbsWrapper = styled.div`
    margin-top: -72px;
    border-bottom: 1px solid ${theme.colors['borders-global-tertiary']};
    background: ${theme.colors['background-global-primary']};

    @media ${theme.mediaQueries.lgMin} {
        border: none;
    }
`;

export const StyledNexusContainer = styled(NexusContainer)`
    display: flex;
    flex-direction: column;
    gap: ${theme.space[8]};
    padding: ${theme.space[12]} ${theme.space[8]};

    @media ${theme.mediaQueries.xlMin} {
        padding-right: ${theme.space[4]};
        padding-left: ${theme.space[4]};
    }

    @media ${theme.mediaQueries.lgMin} {
        padding-top: ${theme.space[16]};
        padding-bottom: ${theme.space[16]};
    }
`;

export const Subcontainer = styled.div`
    width: 100%;
`;
