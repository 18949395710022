import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Button } from '@nexus/lib-react/dist/core/Button';
import type { CSSProperties } from 'react';

export const ContactSlideWrapper = styled.div<{ image: string | undefined }>`
    position: relative;
    width: 100%;
    height: 100%;
    background-image: ${({ image }): string => (image ? `url(${image})` : 'none')};
    background-position: 50% 50%;
    background-size: cover;
`;

export const ContactSlideContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(8px);

    &::after {
        content: '';
        display: block;
        position: absolute;
        opacity: 0.7;
        background-color: ${theme.colors['background-brand-primary']};
        inset: 0;
    }
`;

export const ContactSlideText = styled.div`
    display: flex;
    position: relative;
    z-index: 2;
    flex-direction: column;
    align-items: center;
    color: ${theme.colors.$01_white};
    font-size: ${theme.fontSizes[14]};
    font-style: normal;
    font-weight: ${theme.fontWeights[700]};
    gap: ${theme.space[8]};
`;

export const StyledContactButton = styled(Button)<{ isFixedWidth?: boolean }>`
    width: ${({ isFixedWidth }): CSSProperties['width'] => (isFixedWidth ? '160px' : '100%')};
    background-color: transparent;
`;

export const StyledEmailContactButton = styled(StyledContactButton)`
    margin-bottom: 24px;
`;
