import SaveSearchFloatingButton from '@domains/search/components/SaveSearchFloatingButton/SaveSearchFloatingButton';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { FieldsMetadataExperimentsVariants } from '@type/search/fieldsMetadataExperimentsVariants';
import type { SortingOption } from '@type/sorting/option';
import type { JSX, RefObject } from 'react';

import { ButtonWrapper, Container, Description, Heading } from './SaveSearchPrompt.theme';

interface Props {
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants;
    saveSearchPromptRef: RefObject<HTMLDivElement>;
    pageTitle: string;
    sortingOption: SortingOption;
}

export const SaveSearchPrompt = ({ fieldsMetadataExperimentsVariants, saveSearchPromptRef }: Props): JSX.Element => {
    const [t] = useTranslations();

    return (
        <Container ref={saveSearchPromptRef}>
            <div>
                <Heading>{t('frontend.search.listing.save-search-prompt.heading')}</Heading>
                <Description>{t('frontend.search.listing.save-search-prompt.description')}</Description>
            </div>
            <ButtonWrapper>
                <SaveSearchFloatingButton
                    fieldsMetadataExperimentsVariants={fieldsMetadataExperimentsVariants}
                    variant="SAVE"
                    size="medium"
                    trackingData={{ touch_point_button: 'listing_footer_button' }}
                    shouldShowClicktip={false}
                />
            </ButtonWrapper>
        </Container>
    );
};
