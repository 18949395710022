import { getFilteredLocations } from '@domains/search/components/SaveSearchResults/getFilteredLocations';
import type { FilterLocationsInput } from '@domains/search/types/searchCriteriaInput';
import type { Location } from '@type/search/searchingFilters';

export const getFilterLocationsInput = (
    locations: string | string[] | Location[] | null | undefined,
): FilterLocationsInput => {
    return {
        byDomainId: getFilteredLocations(locations),
    };
};
