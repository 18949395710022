import type { ComponentProps, JSX } from 'react';
import { forwardRef } from 'react';

import { Container } from './Wrapper.theme';

type Props = ComponentProps<typeof Container>;

export const Wrapper = forwardRef<HTMLDivElement, Props>(function Wrapper({ children, ...props }, ref): JSX.Element {
    return (
        <Container ref={ref} {...props}>
            {children}
        </Container>
    );
});
