import { FILTER_ATTRIBUTES_FRAGMENT } from '@domains/search/graphql/fragments/FilterAttributesFragment';
import type { GenericGraphQLError } from '@lib/graphql/error';
import type { GetAllSearchesForUser } from '@type/favorites/allSearchesForUser';
import { gql } from '@urql/core';

/**
 * Please note, either it looks like, this query is not yet normalized in GQL error handling
 */
interface SavedSearchesResponse {
    allSearchesForUser?: GetAllSearchesForUser[] | GenericGraphQLError;
    allSearchesForUserOld?: GetAllSearchesForUser[] | GenericGraphQLError;
}

export const GET_SAVED_SEARCHES_QUERY = gql<SavedSearchesResponse, { isOldSaveSearchQueryEnabled: boolean }>`
    query GetAllSearchesForUser($isOldSaveSearchQueryEnabled: Boolean!) {
        allSearchesForUser: getAllSearchesForUser @skip(if: $isOldSaveSearchQueryEnabled) {
            __typename
            content {
                filterAttributes {
                    ...FilterAttributesFragment
                }
                filterLocations {
                    byGeoAttributes {
                        cityId
                        districtId
                        id
                        lat
                        lon
                        regionId
                        streetId
                        subregionId
                    }
                    byDomainId {
                        domainId
                    }
                    byGeometry {
                        byGeoJson
                    }
                }
            }
            timestamp
        }
        allSearchesForUserOld: getAllSearchesForUserOld @include(if: $isOldSaveSearchQueryEnabled) {
            __typename
            content {
                filterAttributes {
                    ...FilterAttributesFragment
                }
                filterLocations {
                    byGeoAttributes {
                        id
                    }
                }
            }
            timestamp
        }
    }
    ${FILTER_ATTRIBUTES_FRAGMENT}
`;
