import { STICKY_HEADER_HEIGHT } from '@domains/search/consts/stickyHeaderHeight';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { WEIGHT } from '@lib/styles/partials/typography';
import { theme } from '@lib/styles/themes/nexus';
import { Container as NexusContainer } from '@nexus/lib-react/dist/core/Layout';

export const Container = styled(NexusContainer)`
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 100%;
    padding: ${theme.sizes[24]} ${theme.sizes[16]};

    @media ${theme.mediaQueries.lgMin} {
        grid-gap: ${theme.space[32]} ${theme.space[16]};
        grid-template-columns: 1fr 296px;
        width: 1280px;
        padding: ${theme.space[40]} ${theme.space[8]};
    }

    @media ${theme.mediaQueries.xlMin} {
        padding-right: ${theme.space[4]};
        padding-left: ${theme.space[4]};
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const Sidebar = styled.div`
    display: flex;
    flex-direction: column;

    & > * + * {
        margin-top: 24px;
    }
`;

export const ListingPanelContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: ${theme.space[8]};
`;

export const RedirectedFromInactiveAdMessageContainer = styled.div`
    grid-column: 1/-1;
`;

export const Link = styled.a`
    display: block;
    padding: 8px 0;
    color: ${({ theme }): string => theme.deprecated.domains.search.noSearchResults.link.color};
    font-weight: ${WEIGHT.semibold};
    text-decoration: none;
    &:hover,
    &:focus {
        text-decoration: underline;
    }

    @media (min-width: ${BREAKPOINT.md}) {
        display: inline;
        padding: 0;
    }
`;

export const MobileInvestmentsWrapper = styled.div`
    margin-top: ${theme.sizes[56]};
    margin-bottom: ${theme.sizes[16]};
    padding-bottom: ${theme.sizes[32]};
    border-bottom: 1px solid ${theme.colors['borders-global-tertiary']};
`;

export const StickyMapButtonContainer = styled.div`
    position: sticky;
    z-index: ${theme.zIndices.sticky};
    top: ${STICKY_HEADER_HEIGHT};
    margin-right: -4px;
    padding: ${theme.space[16]} 0;
    background-color: ${theme.colors['background-global-secondary']};
`;
