import { CONTACT_FORM_TYPE } from '@domains/shared/consts/contactFormType';
import { ADVERT_GALLERY_QUERY } from '@domains/shared/graphql/queries/AdvertGalleryQuery';
import { getAdContactDetails } from '@domains/shared/helpers/getAdContactDetails';
import { useTracking } from '@lib/tracking/useTracking';
import type { AdvertAgency } from '@type/ad/advertAgency';
import type { JSX } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'urql';

import { ContactSlide } from '../components/ContactSlide';
import { createBufferedImageUrls } from '../helpers/createBufferedImageUrls';
import { createSlidesJsxElements } from '../helpers/createSlidesJsxElements';
import type { AdvertContactData, Image } from '../types/data';

export const useGallerySlides = ({
    trackingSet,
    images,
    advertId,
    advertLink,
    totalPossibleImages,
    agency,
    isMapView,
}: {
    trackingSet: Record<string, unknown>;
    images: Image[];
    advertId: number;
    advertLink: string;
    totalPossibleImages: number;
    agency?: AdvertAgency;
    isMapView?: boolean;
}): {
    slides: JSX.Element[];
    activeSlide: number;
    handleMiniGallerySlide: (slideIndex: number) => void;
} => {
    const { trackEvent } = useTracking();
    const shouldPauseGalleryDataQuery = useRef(true);
    const [activeSlide, setActiveSlide] = useState(0);

    // FIXME: Correctly type `data`.
    const [{ data, fetching }] = useQuery({
        query: ADVERT_GALLERY_QUERY,
        pause: shouldPauseGalleryDataQuery.current,
        variables: {
            id: advertId,
        },
    });

    const galleryTracking = useMemo(() => {
        return {
            ...trackingSet,
            contact_form_type: CONTACT_FORM_TYPE.listingPage,
        };
    }, [trackingSet]);

    // FIXME: This function can be pure and moved outside.
    const createGallerySlides = useCallback(
        (images: Image[], contactData: AdvertContactData | undefined = undefined): JSX.Element[] => {
            const imageSize = 'medium';
            const mainImage = images[0] ? images[0][imageSize] : null;

            if (totalPossibleImages === 0 || !mainImage) {
                return [];
            }

            const bufferedImageUrls = createBufferedImageUrls(images, totalPossibleImages, imageSize);
            const slides = createSlidesJsxElements(bufferedImageUrls, advertLink, trackingSet);

            // FIXME: This should be probably encapsulated in `createSlidesJsxElements` which is responsible for JSX creation.
            slides.push(
                <ContactSlide
                    key="contact-slide"
                    advertId={advertId}
                    contactData={contactData}
                    trackingSet={galleryTracking}
                    image={mainImage}
                    agency={agency}
                    isMapView={isMapView}
                />,
            );

            return slides;
        },
        [totalPossibleImages, advertLink, trackingSet, advertId, galleryTracking, agency, isMapView],
    );

    const [slides, setSlides] = useState(() => {
        return createGallerySlides(images);
    });

    useEffect(() => {
        if (fetching || !data) {
            return;
        }

        const { advert } = data;

        if (advert.images) {
            const slides = createGallerySlides(advert.images, {
                advertCategoryId: advert.category.id,
                contactDetails: getAdContactDetails(advert.owner, agency?.name),
                advertiserType: advert.advertiserType,
                ownerType: advert.owner.type,
                openDay: advert.openDay,
                topInformation: advert.topInformation,
                additionalInformation: advert.additionalInformation,
                advertSource: advert.source,
                links: advert.links,
                cityName: advert.location?.address.city?.name,
            });
            setSlides(slides);
        }
    }, [fetching, data, advertLink, galleryTracking, agency, createGallerySlides]);

    const handleMiniGallerySlide = (slideIndex: number): void => {
        const eventName = slideIndex < totalPossibleImages ? 'gallery_open_swipe' : 'gallery_open_swipe_end';
        trackEvent(eventName, galleryTracking);

        if (totalPossibleImages > 0) {
            shouldPauseGalleryDataQuery.current = false;
        }

        setActiveSlide(slideIndex);
    };

    return {
        slides,
        activeSlide,
        handleMiniGallerySlide,
    };
};
