import { SEARCH_CONFIG } from '@config/search/searchConfig';
import { SITE_CONFIG } from '@config/siteConfig';
import { AdsDescriptionSection } from '@domains/search/components/AdsDescriptionSection/AdsDescriptionSection';
import { ContactModalController } from '@domains/search/components/ContactModalController/ContactModalController';
import { NexusMobileListingButtons } from '@domains/search/components/NexusMobileListingButtons/NexusMobileListingButtons';
import { SearchForm } from '@domains/search/components/SearchForm/SearchForm';
import { SearchPageHead } from '@domains/search/components/SearchPageHead/SearchPageHead';
import { StickyMobileHeader } from '@domains/search/components/StickyMobileHeader/StickyMobileHeader';
import { SAVE_SEARCH_AFTER_REDIRECT_EVENT } from '@domains/search/contexts/MapDrawingContext';
import { countFilters } from '@domains/search/helpers/countFilters';
import { WithProviders } from '@domains/search/hoc/WithProviders';
import { useGetSeoSchemaMarkup } from '@domains/search/hooks/useGetSeoSchemaMarkup';
import { ListingView } from '@domains/search/layouts/ListingView/ListingView';
import { MapView } from '@domains/search/layouts/MapView/MapView';
import type { ListingTracking } from '@domains/search/types/listingTracking';
import type { Search } from '@domains/search/types/search';
import type { SearchMapBoundingBoxData } from '@domains/search/types/SearchMapPins';
import type { Targeting } from '@domains/search/types/targeting';
import { AdsProvider } from '@domains/shared/components/AdsProvider';
import { AdvertSlot } from '@domains/shared/components/Advertising/AdvertSlot';
import { useBaxterSlots } from '@domains/shared/components/Advertising/getAdverts';
import { useBaxterAdvertising } from '@domains/shared/components/Advertising/useBaxterAdvertising';
import { Breadcrumbs } from '@domains/shared/components/Breadcrumbs/Breadcrumbs';
import type { Breadcrumb } from '@domains/shared/components/Breadcrumbs/types';
import { Mobile } from '@domains/shared/components/Responsive/Mobile';
import { Toaster } from '@domains/shared/components/Toast/Toaster';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { ViewTypeContext } from '@domains/shared/contexts/ViewTypeContext';
import { getInitialMapArea } from '@domains/shared/helpers/getInitialMapArea';
import { useFavoritesSubscriptionHash } from '@domains/shared/hooks/useFavoritesSubscriptionHash/useFavoritesSubscriptionHash';
import { useIntersection } from '@domains/shared/hooks/useIntersection/useIntersection';
import { useRegisterTracking } from '@domains/shared/hooks/useRegisterTracking/useRegisterTracking';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { FontProvider } from '@domains/shared/theme/FontProvider';
import { BaxterScripts } from '@lib/baxter/BaxterScripts';
import { dispatchPlatformEvent } from '@lib/events';
import { useExperiments } from '@lib/experiments/client/ExperimentsProvider';
import type { LaquesisPageProps } from '@lib/experiments/types/laquesisPageProps';
import { withGraphQLClient } from '@lib/graphql/withGraphQLClient';
import { MainLayout } from '@lib/layouts/MainLayout/MainLayout';
import { ContentBackground } from '@lib/pages/contentBackground';
import { useTracking } from '@lib/tracking/useTracking';
import { useDialogState } from '@nexus/lib-react/dist/core/Dialog';
import type { SimilarAdsPromptId } from '@type/favorites/similarAdsPromptId';
import type { FilterLocations } from '@type/location/filterLocation';
import type { FieldsMetadataExperimentsVariants } from '@type/search/fieldsMetadataExperimentsVariants';
import type { Estate } from '@type/search/filters/estate';
import type { Market } from '@type/search/filters/market';
import type { RoomsNumber } from '@type/search/filters/roomsNumber';
import type { Transaction } from '@type/search/filters/transaction';
import type { GoogleSuggestion } from '@type/search/google';
import type { SearchPageVariant } from '@type/search/pageVariant';
import type { SearchingFilters } from '@type/search/searchingFilters';
import type { SortingOption } from '@type/sorting/option';
import type { SearchTracking } from '@type/tracking/searchTracking';
import { COMPARE_ADS_DIALOG_ID, CompareAdsDialog } from '@widgets/CompareAds';
import { SeoLinksSection as NexusSeoLinksSection, useGetLocationLinks } from '@widgets/search/SeoLinks';
import dynamic from 'next/dynamic';
import type { JSX } from 'react';
import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { EXPERIMENT } from './consts/experiment';
import {
    Content,
    ListingViewContainer,
    MainLayoutWrapper,
    MobileSearchFormOverlay,
    NegativeMainLayoutSpacer,
    NexusBreadcrumbsWrapper,
    StyledNexusContainer,
    Subcontainer,
} from './SearchPage.theme';

const LazyPopularSearchesSection = dynamic(
    () => import('@domains/shared/components/PopularSearchesSection/PopularSearchesSection'),
    { ssr: false },
);

const LazyMobileSearchFormUniversal = dynamic(
    () => import('@domains/search/components/MobileSearchFormUniversal/MobileSearchFormUniversal'),
    { ssr: false },
);

const LazySearchMap = dynamic(() => import('@domains/search/components/SearchMap/SearchMap'), { ssr: true });

export interface SearchPageTracking {
    listing?: ListingTracking;
    search?: SearchTracking;
}

interface Props {
    mapBoundingBox: SearchMapBoundingBoxData | null;
    canonicalURL: string;
    transaction: Transaction;
    estate: Estate;
    market: Market;
    location: string | null;
    data: Search;
    targeting: Targeting;
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants;
    filterLocations: FilterLocations<string | string[] | { id: unknown }[]> | null;
    filterAttributes: Partial<Omit<SearchingFilters, 'locations' | 'page' | 'limit'>>;
    filteringQueryParams: Partial<SearchingFilters> | undefined;
    pageTitle: string;
    pageHeading: string | null;
    pageDescription: string;
    sortingOption: SortingOption;
    urlViewType: SearchPageVariant;
    shouldEnableIndexingByMetaRobots: boolean;
    breadcrumbItems?: Breadcrumb[] | null;
    googleSuggestion?: GoogleSuggestion | null;
    mapPreviewImages: string[];
}

const BaseSearchPage = (props: Props): JSX.Element => {
    const {
        mapBoundingBox,
        canonicalURL,
        transaction,
        estate,
        market,
        location,
        data,
        fieldsMetadataExperimentsVariants,
        filteringQueryParams,
        filterLocations,
        filterAttributes,
        pageTitle,
        pageHeading,
        pageDescription,
        sortingOption,
        shouldEnableIndexingByMetaRobots,
        targeting,
        breadcrumbItems,
        mapPreviewImages,
    } = props;

    const { isDesktop } = useContext(RWDContext);
    const { trackEvent } = useTracking();

    const { isVariantEnabled } = useExperiments();
    const { lang, featureFlags } = useSiteSettings();
    const { isSeoAdsDescriptionEnabled } = featureFlags;

    const { isOpen: isCompareAdsDialogOpen } = useDialogState(COMPARE_ADS_DIALOG_ID);

    const canUseSearchMap = isVariantEnabled(EXPERIMENT.eure20006, 'b');
    const MapViewComponent = canUseSearchMap ? LazySearchMap : MapView;

    const { viewType } = useContext(ViewTypeContext);
    const [isSearchFormModalVisible, setIsSearchFormModalVisible] = useState(false);

    const [currentScrollPosition, setCurrentScrollPosition] = useState(0);

    const [isIntersectingSaveSearchPrompt, setIsIntersectingSaveSearchPrompt] = useState(false);
    const saveSearchPromptRef = useRef<HTMLDivElement>(null);
    const isDesktopOrUnknowResolution = isDesktop === null || isDesktop;
    const { areLocationObjectBreadcrumbsEnabled, isPopularSearchesSectionEnabled } = SEARCH_CONFIG;

    const schemaMarkupData = useGetSeoSchemaMarkup({
        adverts: data.searchAds.items,
        locationsObjects: data.searchAds.locationsObjects,
        transaction,
        pageTitle,
        pageHeading,
        pageDescription,
        location,
        market,
        lang,
        filterAttributes,
        filteringQueryParams,
        estate,
        breadcrumbItems,
    });

    useIntersection(
        saveSearchPromptRef,
        (entry) => {
            setIsIntersectingSaveSearchPrompt(!!entry?.isIntersecting);
        },
        { shouldRunOnIntersectionOnly: false },
    );

    const selectedFiltersNumber = countFilters(filterAttributes, filterLocations);
    /**
     * This is the ID of the ad for which we should display the similar ads prompt.
     * 'null' means that no prompt is visible.
     */
    const [, setSimilarAdsPromptId] = useState<SimilarAdsPromptId>(null);

    const hasPageHeading = !!pageHeading;

    /**
     * Administrative boundaries to be displayed on the map as an initial shape
     */
    const initialArea = useMemo(() => getInitialMapArea(data.searchAds.geometries), [data.searchAds.geometries]);

    useRegisterTracking();

    const isMapView = viewType === 'map';

    const { defaultBoundingBoxForMap } = SEARCH_CONFIG;

    const { dfp } = SITE_CONFIG;

    useBaxterAdvertising();

    let adUnitOverHeader = isMapView ? undefined : dfp.type.searchPage.topBoard;
    adUnitOverHeader = 'true';

    const closeSearchFormModal = useCallback(() => {
        setIsSearchFormModalVisible(false);
    }, [setIsSearchFormModalVisible]);

    const { propertiesForSell, propertiesForRent, propertiesFromPrimaryMarket, relatedSearchesWithNumberOfRooms } =
        useGetLocationLinks({
            location,
            locationData: filteringQueryParams?.locations,
            estate,
            transaction,
            market: filteringQueryParams?.market as Market,
            roomsNumber: filteringQueryParams?.roomsNumber as RoomsNumber[],
            useTypes: filteringQueryParams?.useTypes,
        });

    useEffect(() => {
        dispatchPlatformEvent(SAVE_SEARCH_AFTER_REDIRECT_EVENT);
    }, []);

    useEffect(() => {
        if (!isSearchFormModalVisible) {
            const scrollingDestination = viewType === 'map' && !isDesktop ? 0 : currentScrollPosition;

            window.scrollTo({
                top: scrollingDestination,
            });
        }
    }, [isSearchFormModalVisible, currentScrollPosition, viewType, isDesktop]);

    const handleOpenSearchFormModal = useCallback((): void => {
        setCurrentScrollPosition(document.documentElement.scrollTop);
        setIsSearchFormModalVisible(true);

        trackEvent('more_filters_click', {
            touch_point_button: null,
        });
    }, [trackEvent]);

    useEffect(() => {
        if (isDesktop) {
            setIsSearchFormModalVisible(false);
        }
    }, [isDesktop]);

    useBaxterSlots({ target: 'listingPage', params: targeting });

    // adding ad to observed if needed after login
    useFavoritesSubscriptionHash(setSimilarAdsPromptId);
    const adsToPreload =
        data?.searchAdsRandomPromoted?.items && data.searchAdsRandomPromoted.items.length > 0
            ? data.searchAdsRandomPromoted.items.slice(0, 2)
            : data.searchAds.items.slice(0, 2);

    const shouldDisplayBreadcrumbs = breadcrumbItems && areLocationObjectBreadcrumbsEnabled;
    const isMapMobileView = isMapView && isDesktop === false;

    return (
        <FontProvider>
            <Toaster />
            <BaxterScripts shouldRenderAds />
            <ContactModalController />
            {isCompareAdsDialogOpen ? <CompareAdsDialog /> : null}

            <SearchPageHead
                canonicalURL={canonicalURL}
                pageTitle={pageTitle}
                pageDescription={pageDescription}
                adsToPreload={adsToPreload}
                shouldEnableIndexingByMetaRobots={shouldEnableIndexingByMetaRobots}
                schemaMarkupData={schemaMarkupData}
            />

            <Mobile>
                <MobileSearchFormOverlay isSearchFormModalVisible={isSearchFormModalVisible} />
                <LazyMobileSearchFormUniversal
                    fieldsMetadataExperimentsVariants={fieldsMetadataExperimentsVariants}
                    pageTitle={pageTitle}
                    showFloatingButtonInsteadOfForm={!isSearchFormModalVisible}
                    isIntersectingSaveSearchPrompt={isIntersectingSaveSearchPrompt}
                    sortingOption={sortingOption}
                    closeSearchFormModal={closeSearchFormModal}
                />
            </Mobile>

            <AdsProvider>
                <MainLayoutWrapper isSearchFormModalVisible={isSearchFormModalVisible}>
                    <MainLayout
                        isMinimal
                        hasStickyPageHeader={false}
                        adUnitOverHeader={adUnitOverHeader}
                        contentBackground={ContentBackground.default}
                        shouldDisplayFooter={!isMapView}
                    >
                        <NegativeMainLayoutSpacer>
                            {isMapMobileView ? null : (
                                <NexusBreadcrumbsWrapper>
                                    <StyledNexusContainer>
                                        {shouldDisplayBreadcrumbs ? (
                                            <Breadcrumbs breadcrumbItems={breadcrumbItems} shouldUseNexusTheme />
                                        ) : null}
                                        <Mobile>
                                            <NexusMobileListingButtons
                                                selectedFiltersNumber={selectedFiltersNumber}
                                                sortingOption={sortingOption}
                                                onFiltersOpen={handleOpenSearchFormModal}
                                            />
                                        </Mobile>
                                    </StyledNexusContainer>
                                </NexusBreadcrumbsWrapper>
                            )}

                            <Mobile>
                                <StickyMobileHeader
                                    selectedFiltersNumber={selectedFiltersNumber}
                                    sortingOption={sortingOption}
                                    onFiltersOpen={handleOpenSearchFormModal}
                                />
                            </Mobile>

                            <Content shouldMoveToTop={!!isMapMobileView}>
                                {isDesktopOrUnknowResolution ? (
                                    <SearchForm
                                        fieldsMetadataExperimentsVariants={fieldsMetadataExperimentsVariants}
                                        sortingOption={sortingOption}
                                        pageTitle={pageTitle}
                                        totalResults={data.searchAds.pagination.totalResults}
                                        isMapView={isMapView}
                                    />
                                ) : null}

                                <ListingViewContainer hasTopPadding={!isMapView && !hasPageHeading}>
                                    {isMapView ? (
                                        <MapViewComponent
                                            lang={lang}
                                            filterLocations={filterLocations}
                                            filterAttributes={filterAttributes}
                                            onFiltersOpen={handleOpenSearchFormModal}
                                            sortingOption={sortingOption}
                                            initialBounds={mapBoundingBox?.boundingBox || defaultBoundingBoxForMap}
                                            initialArea={initialArea || null}
                                            handleOpenSearchFormModal={handleOpenSearchFormModal}
                                            pageHeading={pageHeading}
                                            selectedFiltersNumber={selectedFiltersNumber}
                                        />
                                    ) : (
                                        <ListingView
                                            data={data}
                                            estate={estate}
                                            sortingOption={sortingOption}
                                            pageTitle={pageTitle}
                                            pageHeading={pageHeading}
                                            saveSearchPromptRef={saveSearchPromptRef}
                                            filterAttributes={filterAttributes}
                                            fieldsMetadataExperimentsVariants={fieldsMetadataExperimentsVariants}
                                            setSimilarAdsPromptId={setSimilarAdsPromptId}
                                            mapImages={mapPreviewImages}
                                        />
                                    )}

                                    {isMapView ? null : <AdvertSlot id="baxter-l-bottom" />}
                                </ListingViewContainer>

                                {isMapView ? null : (
                                    <StyledNexusContainer>
                                        <Subcontainer>
                                            {isPopularSearchesSectionEnabled ? (
                                                <LazyPopularSearchesSection
                                                    estate={estate}
                                                    transaction={transaction}
                                                    location={location}
                                                    page={filteringQueryParams?.page}
                                                />
                                            ) : null}

                                            {isSeoAdsDescriptionEnabled ? (
                                                <AdsDescriptionSection
                                                    location={location}
                                                    page={data.searchAds.pagination.page}
                                                    filterAttributes={filterAttributes}
                                                />
                                            ) : null}

                                            <NexusSeoLinksSection
                                                searchLinksRelatedLocations={data.searchLinksRelatedLocations?.items}
                                                propertiesForSell={propertiesForSell}
                                                propertiesForRent={propertiesForRent}
                                                propertiesFromPrimaryMarket={propertiesFromPrimaryMarket}
                                                relatedSearchesWithNumberOfRooms={relatedSearchesWithNumberOfRooms}
                                            />
                                        </Subcontainer>
                                    </StyledNexusContainer>
                                )}
                            </Content>
                        </NegativeMainLayoutSpacer>
                    </MainLayout>
                </MainLayoutWrapper>
            </AdsProvider>
        </FontProvider>
    );
};

export type SearchPageProps = Omit<Props, 'viewType'>;

export const SearchPageWithListingWithProviders = (
    props: SearchPageProps & { tracking: SearchPageTracking } & LaquesisPageProps,
): JSX.Element => {
    const {
        experiments,
        laquesisResult,
        urlViewType,
        targeting,
        tracking,
        filteringQueryParams,
        transaction,
        estate,
        location,
        market,
        data,
        googleSuggestion,
    } = props;

    const searchFormValues = useMemo(
        () => ({
            filteringQueryParams,
            transaction,
            estate,
            location,
            market,
            locationString: data?.searchAds?.locationString,
            locationsObjects: data?.searchAds?.locationsObjects,
            googleSuggestion,
        }),
        [
            data?.searchAds?.locationString,
            data?.searchAds?.locationsObjects,
            estate,
            filteringQueryParams,
            location,
            market,
            transaction,
            googleSuggestion,
        ],
    );

    return (
        <WithProviders
            experiments={experiments}
            laquesisResult={laquesisResult}
            filteringQueryParams={filteringQueryParams}
            urlViewType={urlViewType}
            targetingArguments={targeting}
            tracking={tracking}
            searchFormValues={searchFormValues}
        >
            <BaseSearchPage {...props} />
        </WithProviders>
    );
};

// Memo is used to avoid re-renders when service-worker is registered
export const SearchPageWithListing = memo(
    withGraphQLClient({
        component: SearchPageWithListingWithProviders,
    }),
);
