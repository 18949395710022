import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Button } from '@nexus/lib-react/dist/core/Button';

export const StyledButton = styled(Button)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${theme.colors.$01_white};

    @media ${theme.mediaQueries.lgMin} {
        top: 256px;
    }
`;
