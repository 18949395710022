import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 4px;
    margin-bottom: 40px;
    padding: 16px;
    background-color: white;

    @media (min-width: ${BREAKPOINT.md}) {
        flex-direction: row;
        margin-top: 0;
    }
`;

export const Heading = styled.p`
    margin: 0 0 8px;
    font-size: ${SIZE.p2};
    font-weight: ${WEIGHT.bold};

    @media (min-width: ${BREAKPOINT.md}) {
        margin-bottom: 4px;
    }
`;

export const Description = styled.p`
    max-width: 600px;
    margin: 0;
    font-size: ${SIZE.p3};
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;

    @media (min-width: ${BREAKPOINT.md}) {
        margin-top: 0;
    }
`;
