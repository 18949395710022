import { getSeeButtonTrackEventTouchPointButton } from '@domains/search/helpers/getSeeButtonTrackEventTouchPointButton';
import { getSellerImage } from '@domains/search/helpers/getSellerImage';
import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { AdvertAgency } from '@type/ad/advertAgency';
import { InteractiveIsland } from '@widgets/AdvertCard/Navigation';
import { PushedUpAtLabel } from '@widgets/AdvertCard/PushedUpAtLabel';
import { SellerInfo } from '@widgets/AdvertCard/SellerInfo';
import type { JSX, MouseEventHandler, ReactNode } from 'react';

import { HighlightedAdvertLink } from './SellerInfoWrapper.theme';

interface Props {
    advertLink: string;
    agency: AdvertAgency;
    pushedUpAt: string | null;
    trackingSet: Record<string, unknown>;
    isNearbySection: boolean;
    isPrivateOwner: boolean;
    isPromotedSection: boolean;
}

export const SellerInfoWrapper = ({
    advertLink,
    agency,
    pushedUpAt,
    trackingSet,
    isNearbySection,
    isPrivateOwner,
    isPromotedSection,
}: Props): JSX.Element => {
    const hasHighlightedAds = Boolean(agency?.type === 'AGENCY' && agency?.highlightedAds);

    let moreInfo: ReactNode;
    let complementaryInfo: ReactNode;

    if (hasHighlightedAds) {
        moreInfo = <ConnectedPushedUpAtLabel datetime={pushedUpAt} placement="top-start" />;
        complementaryInfo = (
            <ConnectedHighlightedAdvertLink
                advertLink={advertLink}
                trackingSet={trackingSet}
                isNearbySection={isNearbySection}
                isPromotedSection={isPromotedSection}
            />
        );
    } else {
        moreInfo = null;
        complementaryInfo = <ConnectedPushedUpAtLabel datetime={pushedUpAt} placement="top-end" />;
    }

    return (
        <SellerInfo
            name={agency?.name}
            type={agency?.type}
            image={getSellerImage(agency)}
            moreInfo={moreInfo}
            asideInfo={complementaryInfo}
            isPrivate={isPrivateOwner}
        />
    );
};

const ConnectedPushedUpAtLabel = ({
    datetime,
    placement,
}: {
    datetime: string | null;
    placement: 'top-start' | 'top-end';
}): JSX.Element | null => {
    if (!datetime) {
        return null;
    }

    return (
        <InteractiveIsland shouldStopEventPropagation>
            <PushedUpAtLabel datetime={datetime} placement={placement} />
        </InteractiveIsland>
    );
};

const ConnectedHighlightedAdvertLink = ({
    advertLink,
    trackingSet,
    isNearbySection,
    isPromotedSection,
}: {
    advertLink: string;
    trackingSet: Record<string, unknown>;
    isNearbySection: boolean;
    isPromotedSection: boolean;
}): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();

    const handleClick: MouseEventHandler<HTMLAnchorElement> = () => {
        const touchPointButton = getSeeButtonTrackEventTouchPointButton(isPromotedSection, isNearbySection);
        trackEvent('ad_click', { ...trackingSet, touch_point_button: touchPointButton });
    };

    return (
        <InteractiveIsland shouldStopEventPropagation>
            <LocalLink href={advertLink} passHref prefetch={false}>
                <HighlightedAdvertLink onClick={handleClick} aria-hidden>
                    {t('frontend.search.item.see-ad')}
                </HighlightedAdvertLink>
            </LocalLink>
        </InteractiveIsland>
    );
};
