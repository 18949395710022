import { gql } from '@urql/core';

export const MAP_LOCATION_SERVICE_OBJECTS_QUERY = gql`
    query MapLocationServiceObjects($locationsToMap: [String!]!) {
        mapLocationServiceObjects(locationsToMap: $locationsToMap) {
            ... on MappedLocationServiceObjects {
                mappedLocations {
                    sphereLocationObject {
                        id
                        name
                        urlPath
                        parentIds
                    }
                    locationServiceObject {
                        id
                        name
                        fullName
                        parentIds
                    }
                }
                unmappedLocations {
                    id
                    name
                    parentIds
                }
            }
            ... on ErrorInternal {
                message
            }
        }
    }
`;
