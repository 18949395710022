import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { SearchFormValuesContext } from '@domains/shared/contexts/SearchFormValuesContext';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { DropdownOption } from '@type/search/dropdownOption';
import type { FormValues } from '@type/search/searchFormValues';
import { createSearchUrlWithParams } from '@widgets/search/buildSearchUrl';
import type { JSX, MutableRefObject } from 'react';
import { useContext } from 'react';

import { GoToResults, Subtitle, Title } from './Heading.theme';

const createOptionWithoutLabel = (value: string): DropdownOption => {
    return { value, label: '' };
};

interface Props {
    title: string;
    cyTitle: string;
    subtitle?: string | JSX.Element[];
    shouldDisableTopBorder?: boolean;
    shouldRenderGoToPromotedLink?: boolean;
    titleRef?: MutableRefObject<HTMLSpanElement | null>;
}

export const Heading = ({
    title,
    cyTitle,
    subtitle,
    shouldDisableTopBorder,
    shouldRenderGoToPromotedLink,
    titleRef,
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const { lang } = useSiteSettings();

    const searchFormValues = useContext(SearchFormValuesContext);

    const { filteringQueryParams = {}, location, transaction, estate, market } = searchFormValues;

    const isAllPromotedAdsView = filteringQueryParams?.isPromoted;
    const shouldDisplayPromotedLink = !isAllPromotedAdsView && shouldRenderGoToPromotedLink;

    const urlParams = {
        ...filteringQueryParams,
        page: 1,
        estate: createOptionWithoutLabel(estate),
        transaction: createOptionWithoutLabel(transaction),
        market: createOptionWithoutLabel(market),
        location,
        isPromoted: null,
    } as Partial<FormValues>;

    const searchUrlWithparams = createSearchUrlWithParams({
        params: urlParams,
        lang,
    });

    const handleLinkClick = (): void => {
        trackEvent('see_all_promoted_ads', {
            touch_point_button: null,
        });
    };

    return (
        <>
            <Title
                data-cy={cyTitle}
                data-testid="ads-result-title"
                shouldDisableTopBorder={shouldDisableTopBorder}
                ref={titleRef}
            >
                {title}

                {isAllPromotedAdsView ? (
                    <LocalLink href={searchUrlWithparams}>
                        <GoToResults data-cy="search.listing.back-to-full-list" role="link">
                            {t('frontend.search.listing.back-to-full-list')}
                        </GoToResults>
                    </LocalLink>
                ) : null}

                {shouldDisplayPromotedLink ? (
                    <LocalLink href={`${searchUrlWithparams}&isPromoted=true`}>
                        <GoToResults onClick={handleLinkClick} data-cy="search.listing.see-all-promoted" role="link">
                            {t('frontend.search.listing.see-all-promoted')}
                        </GoToResults>
                    </LocalLink>
                ) : null}
            </Title>

            {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
        </>
    );
};
