import {
    checkIsNewAdvert,
    checkTimeSinceAdCreationWithinLimit,
    getDaysSinceAdCreation,
} from '@domains/shared/helpers/advertCreationTime';
import { getAdvertCardTouchPointButton } from '@domains/shared/helpers/getAdvertCardTouchPointButton';
import { getSpecialOfferTypeTrackingValue } from '@domains/shared/helpers/getSpecialOfferTypeTrackingValue';
import type { AdvertAgency } from '@type/ad/advertAgency';
import type { InvestmentEstimatedDelivery } from '@type/ad/investment';
import { OWNER_TYPE } from '@type/ad/ownerType';
import type { SpecialOffer } from '@type/ad/specialOffer';

interface Item {
    id: number;
    agency: AdvertAgency;
    isPrivateOwner: boolean | null;
    dateCreatedFirst: string | null;
    pushedUpAt: string | null;
    specialOffer: SpecialOffer | null;
    investmentEstimatedDelivery: InvestmentEstimatedDelivery | null;
    investmentUnitsNumber: number | null;
}

export const createBasicAdvertCardTrackingSet = ({
    item,
    index,
    isNearbySection,
    isPromotedSection,
    isSpecialOfferToggleOn,
    isRelatedAdsParent,
}: {
    item: Item;
    index: number;
    isNearbySection: boolean;
    isPromotedSection: boolean;
    isSpecialOfferToggleOn: boolean;
    isRelatedAdsParent?: boolean;
}): Record<string, unknown> => {
    const touchPointButton = getAdvertCardTouchPointButton({
        isNearbySection,
        isNew: checkIsNewAdvert(item.dateCreatedFirst),
        isPromotedSection,
        isRelatedAdsParent,
    });

    return {
        ad_id: item.id,
        discount_value: item.specialOffer?.discountValue ?? null,
        finish_date: getFinishDate(item.investmentEstimatedDelivery),
        index,
        is_bumped: !!item.pushedUpAt,
        nb_ads: item.investmentUnitsNumber ?? null,
        only_special_offers: isSpecialOfferToggleOn ? 1 : 0,
        poster_type: getPosterType(item.agency, !!item.isPrivateOwner),
        special_offer_type: getSpecialOfferTypeTrackingValue(item.specialOffer),
        time_since_ad_creation: getTimeSinceCreation(item),
        touch_point_button: touchPointButton,
    };
};

const getFinishDate = (delivery: InvestmentEstimatedDelivery | null): `${number}-${number}` | null => {
    return delivery ? `${delivery.year}-${delivery.quarter}` : null;
};

const getPosterType = (agency: AdvertAgency, isPrivateOwner: boolean): string => {
    return (isPrivateOwner || !agency?.type ? OWNER_TYPE.private : agency.type).toLocaleLowerCase();
};

const getTimeSinceCreation = (item: Item): number | null => {
    const daysSinceAdCreation = getDaysSinceAdCreation(item.dateCreatedFirst);
    const timeSinceAdvertCreation = checkTimeSinceAdCreationWithinLimit(daysSinceAdCreation)
        ? daysSinceAdCreation
        : null;

    return timeSinceAdvertCreation;
};
