// @FIXME: move helper usage places inside this widget and move it to /core directory
import type { Estate } from '@type/search/filters/estate';
import type { Transaction } from '@type/search/filters/transaction';
import type { SearchingFilters } from '@type/search/searchingFilters';

import { getParsedCustomCategories } from '../core/helpers/getParsedCustomCategories';
import type { ParseFilteringQueryParamsProps } from '../types/helpers';

export const parseFilteringQueryParams = (
    searchingParams: Partial<SearchingFilters>,
    estate: Estate,
    transaction: Transaction,
): ParseFilteringQueryParamsProps => {
    const { locations, page, limit, ...params } = searchingParams;

    params.estate = estate;
    params.transaction = transaction;

    return getParsedCustomCategories(params);
};
