import { useState } from 'react';

export const useChunks = <TItem>(
    items: TItem[],
    size = 4,
): {
    currentChunkIndex: number;
    currentChunk: TItem[];
    allChunks: TItem[][];
    setChunk: (which: 'previous' | 'next') => void;
} => {
    const [currentChunkIndex, setCurrentChunkIndex] = useState(0);

    const allChunks = getChunks(items, size);
    const currentChunk = allChunks[currentChunkIndex];

    const setChunk = (which: 'previous' | 'next'): void => {
        setCurrentChunkIndex((current) => {
            switch (which) {
                case 'previous': {
                    return current - 1;
                }

                case 'next': {
                    return current + 1;
                }

                default: {
                    return current;
                }
            }
        });
    };

    return {
        currentChunkIndex,
        currentChunk,
        allChunks,
        setChunk,
    };
};

const getChunks = <TItem>(items: TItem[], size: number): TItem[][] => {
    return items.reduce<TItem[][]>((result, item, index) => {
        const chunkIndex = Math.floor(index / size);

        if (!result[chunkIndex]) {
            result[chunkIndex] = [];
        }

        result[chunkIndex].push(item);

        return result;
    }, []);
};
