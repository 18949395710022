import type { LocationServiceObject } from '@domains/search/types/LocationServiceObject';
import type { SphereLocationObject } from '@domains/search/types/SphereLocationObject';

export const MAPPING_KEY = {
    mapSphereLocations: 'mapSphereLocations',
    mapLocationServiceObjects: 'mapLocationServiceObjects',
};

interface MappedLocation {
    sphereLocationObject: Partial<LocationServiceObject>;
    locationServiceObject: Partial<SphereLocationObject>;
}

export interface MappingResult {
    [key: ObjectValues<typeof MAPPING_KEY>]: {
        mappedLocations: MappedLocation[];
    };
}
