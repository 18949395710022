import { CACHE_PREFIX } from '@domains/shared/consts/cachePrefix';
import { useCallback } from 'react';
import { useLocalStorage } from 'react-use';

interface TooltipDisplayConfig {
    firstHideKey: string;
    secondHideKey: string;
    breakBetweenDisplay?: number;
}
interface TooltipDisplay {
    updateLocalStorage: () => void;
    shouldDisplayTooltip: () => boolean;
}

const BREAK_BETWEEN_DISPLAY = 1_209_600_000; // 14 days (in miliseconds)

export const useTooltipDisplay = ({
    firstHideKey,
    secondHideKey,
    breakBetweenDisplay = BREAK_BETWEEN_DISPLAY,
}: TooltipDisplayConfig): TooltipDisplay => {
    const [firstTooltipHideDate, setFirstHideDate] = useLocalStorage<number>(`${CACHE_PREFIX}${firstHideKey}`);
    const [secondTooltipHideDate, setSecondHideDate] = useLocalStorage<number>(`${CACHE_PREFIX}${secondHideKey}`);

    const updateLocalStorage = useCallback((): void => {
        if (secondTooltipHideDate) {
            return;
        }

        if (firstTooltipHideDate) {
            setSecondHideDate(Date.now());

            return;
        }

        setFirstHideDate(Date.now());
    }, [firstTooltipHideDate, secondTooltipHideDate, setFirstHideDate, setSecondHideDate]);

    const shouldDisplayTooltip = useCallback((): boolean => {
        if (secondTooltipHideDate) {
            return false;
        }

        if (firstTooltipHideDate) {
            return Date.now() - firstTooltipHideDate > breakBetweenDisplay;
        }

        return true;
    }, [breakBetweenDisplay, firstTooltipHideDate, secondTooltipHideDate]);

    return {
        updateLocalStorage,
        shouldDisplayTooltip,
    };
};
