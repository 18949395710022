import { EXPERIMENT } from '@domains/search/consts/experiment';
import type { AdvertListItem } from '@domains/search/types/advertListItem';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useExperiments } from '@lib/experiments/client/ExperimentsProvider';
import { Gallery } from '@widgets/AdvertCard/Gallery';
import { InteractiveIsland } from '@widgets/AdvertCard/Navigation';
import { Tag } from '@widgets/AdvertCard/Tag';
import { ImageSkeleton } from '@widgets/Carousel/ImageSkeleton';
import dynamic from 'next/dynamic';
import type { JSX } from 'react';

import { CarouselWrapper, GalleryWrapper, OpenDays, TagsWrapper } from './GallerySection.theme';
import { useGallerySlides } from './hooks/useGallerySlides';

const LazyGallery = dynamic(() => import('@widgets/AdvertCard/Gallery'), {
    ssr: false,
    loading: () => <ImageSkeleton />,
});

interface Props {
    advertLink: string;
    item: AdvertListItem;
    shouldLazyLoadGallery?: boolean;
    shouldShowAdvertCreationBadge: boolean;
    daysSinceAdCreation: number;
    trackingSet: Record<string, unknown>;
    shouldSpaceOpenDays?: boolean;
    isPromotedSection?: boolean;
    isMapView?: boolean;
}

export const GallerySection = ({
    advertLink,
    item,
    shouldLazyLoadGallery,
    shouldShowAdvertCreationBadge,
    daysSinceAdCreation,
    trackingSet,
    shouldSpaceOpenDays = false,
    isPromotedSection = false,
    isMapView = false,
}: Props): JSX.Element => {
    const { activeSlide, slides, handleMiniGallerySlide } = useGallerySlides({
        trackingSet,
        images: item.images,
        advertId: item.id,
        advertLink,
        totalPossibleImages: item.totalPossibleImages,
        agency: item.agency,
        isMapView,
    });

    const [t] = useTranslations();

    const { isVariantEnabled } = useExperiments();
    const isEURE27170Variant =
        isVariantEnabled(EXPERIMENT.eure27170, 'b') || isVariantEnabled(EXPERIMENT.eure27170, 'c');
    const hasOutermostArrow = isVariantEnabled(EXPERIMENT.eure27170, 'b') && isMapView;
    const shouldHideTags = isEURE27170Variant && isMapView && slides[activeSlide].key === 'contact-slide';

    return (
        <InteractiveIsland shouldStopEventPropagation shouldAffectEntireContainer>
            <GalleryWrapper>
                {shouldHideTags ? null : (
                    <TagsWrapper>
                        {isPromotedSection ? (
                            <Tag isPromoted />
                        ) : (
                            <>
                                {item.isExclusiveOffer ? <Tag isExclusiveOffer /> : null}
                                {item.investmentState ? <Tag investmentState={item.investmentState} /> : null}
                                {shouldShowAdvertCreationBadge ? <Tag dateCreated={daysSinceAdCreation} /> : null}
                            </>
                        )}
                    </TagsWrapper>
                )}

                <CarouselWrapper shouldSpaceOpenDays={shouldSpaceOpenDays}>
                    {shouldLazyLoadGallery ? (
                        <LazyGallery
                            controlledActive={activeSlide}
                            onChange={handleMiniGallerySlide}
                            hasOutermostArrow={hasOutermostArrow}
                        >
                            {slides}
                        </LazyGallery>
                    ) : (
                        <Gallery
                            controlledActive={activeSlide}
                            onChange={handleMiniGallerySlide}
                            hasOutermostArrow={hasOutermostArrow}
                        >
                            {slides}
                        </Gallery>
                    )}
                </CarouselWrapper>

                {item.openDays ? (
                    <OpenDays>
                        {t('frontend.search.item.open-day')}: {item.openDays}
                    </OpenDays>
                ) : null}
            </GalleryWrapper>
        </InteractiveIsland>
    );
};
