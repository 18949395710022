import type { AdvertListItem } from '@domains/search/types/advertListItem';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';
import { useRef } from 'react';

import { useChunks } from '../../hooks/useChunks';
import { InvestmentInfoBar } from '../InvestmentInfoBar/InvestmentInfoBar';
import { MiniAdvertsGrid } from '../MiniAdvertsGrid/MiniAdvertsGrid';
import { Pagination } from '../Pagination/Pagination';
import { InfoContainer, InfoHeader } from './RelatedAdvertsSection.theme';

export const RelatedAdvertsSection = ({
    relatedAds,
    developmentId,
    developmentTitle,
    developmentUrl,
    trackingSet,
}: {
    relatedAds: AdvertListItem[];
    developmentId: number;
    developmentTitle: string;
    developmentUrl: string;
    trackingSet: Record<string, unknown>;
}): JSX.Element => {
    const { trackEvent } = useTracking();
    const { currentChunkIndex, currentChunk, allChunks, setChunk } = useChunks(relatedAds);
    const relatedAdvertsImpressionListRef = useRef<number[]>([]);

    const handleAdvertImpression = (advertId: number): void => {
        if (relatedAdvertsImpressionListRef.current.includes(advertId)) {
            return;
        }

        trackEvent('ad_impressions', {
            ...trackingSet,
            touch_point_button: 'combined_ads_list',
            ad_id: advertId,
        });

        relatedAdvertsImpressionListRef.current.push(advertId);
    };

    const handleAdvertClick = (advertId: number, isOnContextMenu?: boolean): void => {
        trackEvent('ad_click', {
            ...trackingSet,
            touch_point_button: isOnContextMenu ? 'combined_ads_list_new_tab' : 'combined_ads_list',
            ad_id: advertId,
        });
    };

    const createPaginationClickHandler = (direction: 'previous' | 'next') => (): void => {
        setChunk(direction);
        trackEvent('page_change_click', {
            touch_point_button: `combined_ads_list_${direction}_page`,
        });
    };

    return (
        <InfoContainer>
            <InfoHeader>
                <InvestmentInfoBar
                    developmentId={developmentId}
                    developmentTitle={developmentTitle}
                    developmentUrl={developmentUrl}
                    unitsCount={relatedAds.length}
                />

                {allChunks.length > 1 ? (
                    <Pagination
                        page={currentChunkIndex + 1}
                        total={allChunks.length}
                        onPreviousClick={createPaginationClickHandler('previous')}
                        onNextClick={createPaginationClickHandler('next')}
                    />
                ) : null}
            </InfoHeader>

            {currentChunk.length > 0 ? (
                <MiniAdvertsGrid
                    adverts={currentChunk}
                    onAdvertClick={handleAdvertClick}
                    onAdvertImpression={handleAdvertImpression}
                />
            ) : null}
        </InfoContainer>
    );
};
