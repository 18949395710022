import { DEFAULT_ADS_NUMBER_PER_PAGE } from '@domains/shared/consts/defaultPerPage';
import { useRouter } from 'next/router';

export const usePaginationParameters = (): { page: number; limit: number } => {
    const router = useRouter();
    const page = router.query.page ? Number(router.query.page) : 1;
    const limit = router.query.limit ? Number(router.query.limit) : Number(DEFAULT_ADS_NUMBER_PER_PAGE);

    return { page, limit };
};
