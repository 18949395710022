import { SEARCH_CONFIG } from '@config/search/searchConfig';
import { useTooltipDisplay } from '@domains/shared/hooks/useTooltipDisplay/useTooltipDisplay';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';
import { useCallback } from 'react';

import { StyledClicktip } from './SaveSearchClicktip.theme';

interface Props {
    button: JSX.Element;
    xShift?: number;
    yShift?: number;
    initialPosition?: 'top' | 'bottom';
    shouldHideOnMobile?: boolean;
    isButtonClicked?: boolean;
    fullWidth?: boolean;
}

const FIRST_HIDE_KEY = 'FIRST_TOOLTIP_HIDE_DATE';
const SECOND_HIDE_KEY = 'SECOND_TOOLTIP_HIDE_DATE';

const TOOLTIP_LEFT_BASE = -160;
const OFFSET_Y_BASE = 5;
const TRACKING_DATA = { touch_point_button: 'favourite_search_save' } as const;

export const SaveSearchClicktip = ({
    button,
    xShift = 0,
    yShift = 0,
    initialPosition,
    shouldHideOnMobile,
    isButtonClicked = false,
    fullWidth = false,
}: Props): JSX.Element => {
    const { trackEvent } = useTracking();
    const [t] = useTranslations();
    const { isSaveSearchClicktipEnabled } = SEARCH_CONFIG;
    const { updateLocalStorage, shouldDisplayTooltip } = useTooltipDisplay({
        firstHideKey: FIRST_HIDE_KEY,
        secondHideKey: SECOND_HIDE_KEY,
    });

    const handleHide = useCallback(() => {
        trackEvent('tooltip_onboarding_close', TRACKING_DATA);
        updateLocalStorage();
    }, [trackEvent, updateLocalStorage]);

    const handleShow = useCallback(() => {
        trackEvent('tooltip_onboarding_view', TRACKING_DATA);
    }, [trackEvent]);

    const shouldOpenOnStart = isSaveSearchClicktipEnabled && shouldDisplayTooltip();

    return (
        <StyledClicktip
            tooltipVariant="dark"
            shouldOpenOnStart={shouldOpenOnStart}
            targetElement={button}
            initialPosition={initialPosition}
            offsetY={OFFSET_Y_BASE + yShift}
            tooltipLeft={TOOLTIP_LEFT_BASE + xShift}
            onHide={handleHide}
            onShow={handleShow}
            shouldHideOnMobile={shouldHideOnMobile}
            didUserInteractWithTarget={isButtonClicked}
            dataCy="save-search-clicktip"
            fullWidth={fullWidth}
        >
            <span>{t('frontend.search.form.save-search-results.clicktip')}</span>
        </StyledClicktip>
    );
};
