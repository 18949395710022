export const LOCATION_TYPE = {
    urlPath: 'urlPath',
    sphere: 'sphere',
    domainId: 'domain',
} as const;

export type LocationTypeValue = ObjectValues<typeof LOCATION_TYPE>;

export interface ParsedLocation {
    type: LocationTypeValue;
    value: string | string[] | null;
}
