import styled from '@emotion/styled';
import { SCREEN_READER_ONLY } from '@lib/styles/partials/helpers';
import { theme } from '@lib/styles/themes/nexus';
import { Wrapper } from '@widgets/AdvertCard/Wrapper';
import type { CSSProperties } from 'react';

// FIXME: borders-brand-tertiary is available - change this when updated.
const CARD_BORDER_COLOR = '#D3D5D9';

export const StyledList = styled.ul<{ isOneColumn: boolean }>`
    display: grid;
    grid-template-columns: 1fr;
    gap: ${theme.sizes[16]};
    padding: 0;
    list-style: none;

    @media ${theme.mediaQueries.lgMin} {
        grid-template-columns: ${({ isOneColumn }): CSSProperties['gridTemplateColumns'] => {
            return isOneColumn ? '1fr' : '1fr 1fr';
        }};
    }
`;

export const StyledWrapper = styled(Wrapper)`
    display: flex;
    overflow: hidden;
    border: 1px solid ${CARD_BORDER_COLOR};
    border-radius: ${theme.radii[4]};
`;

export const StyledImage = styled.img`
    width: 100px;
    aspect-ratio: 1/1;
    object-fit: cover;

    @media ${theme.mediaQueries.lgMin} {
        width: 176px;
        aspect-ratio: 16/9;
    }
`;

export const HiddenTitle = styled.p`
    ${SCREEN_READER_ONLY};
`;

export const InfoBlock = styled.div`
    width: 100%;
    padding: ${theme.sizes[12]};

    @media ${theme.mediaQueries.lgMin} {
        padding: ${theme.sizes[16]};
    }
`;

export const InfoBlockTopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.space[6]};

    @media ${theme.mediaQueries.lgMin} {
        margin-bottom: ${theme.sizes[16]};
    }
`;
