import type { SearchPageVariant } from '@type/search/pageVariant';

export const getSearchPageTouchPointButtonValue = (
    viewType: SearchPageVariant,
    source: string | string[] | undefined,
    medium: string | string[] | undefined,
): string => {
    if (source === 'siren' && medium === 'email') {
        return 'saved_search_email';
    }

    if (source === 'siren' && medium === 'push_web') {
        return 'saved_search_push';
    }

    return viewType;
};
