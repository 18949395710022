import { MapDrawingContext } from '@domains/search/contexts/MapDrawingContext';
import { URL_LOCATION_FILLER } from '@domains/shared/consts/urlLocationFiller';
import { ViewTypeContext } from '@domains/shared/contexts/ViewTypeContext';
import { useExperiments } from '@lib/experiments/client/ExperimentsProvider';
import { ESTATE } from '@type/search/filters/estate';
import { getSegmentsByType } from '@widgets/search/getSegment';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';

export const useIsSaveSearchButtonVisible = (): boolean => {
    const router = useRouter();
    const [isSaveSearchButtonVisible, setIsSaveSearchButtonVisible] = useState<boolean>(false);
    const { viewType } = useContext(ViewTypeContext);
    const { customAreaPolygon } = useContext(MapDrawingContext);

    const { isVariantEnabled } = useExperiments();

    useEffect(() => {
        const [, urlEstate, location]: string[] = router?.query.searchingCriteria as string[];
        if (viewType === 'map' && !!customAreaPolygon) {
            setIsSaveSearchButtonVisible(true);
        } else if (viewType === 'listing' && !checkIsInvestmentSearch(urlEstate) && checkIsLocationDefined(location)) {
            setIsSaveSearchButtonVisible(true);
        } else {
            setIsSaveSearchButtonVisible(false);
        }
    }, [customAreaPolygon, isVariantEnabled, router, viewType]);

    return isSaveSearchButtonVisible;
};

const checkIsLocationDefined = (location: string): boolean => {
    return URL_LOCATION_FILLER.allLocations.label !== location;
};

const checkIsInvestmentSearch = (urlEstate: string): boolean => {
    let urlSegment;
    // check comma for conditions with only primary or secondary market, investments don't have this option
    if (!urlEstate.includes(',')) {
        urlSegment = getSegmentsByType('estate')?.find((segment) => segment.label === urlEstate);
        if (!urlSegment) {
            return false;
        }

        return urlSegment.value === ESTATE.investment;
    }

    return false;
};
