import { gql } from '@urql/core';
import type { TypedDocumentNode } from 'urql';

export const getMapSphereLocationsQuery = (type: string): TypedDocumentNode => gql`
    query GetMapSphereLocationsQuery($locationsToMap: ${type === 'urlPath' ? 'String!' : '[String!]!'}) {
        mapSphereLocations(${type === 'urlPath' ? 'locationsToMapByUrlPath' : 'locationsToMap'}: $locationsToMap) {
            ... on MappedLocations {
                mappedLocations {
                    sphereLocationObject {
                        id
                    }
                    locationServiceObject {
                        id
                        name
                        fullName
                        parentIds
                    }
                }
                unmappedLocations {
                    id
                }
            }
            ... on ErrorInternal {
                message
            }
        }
    }
`;
