import { gql } from '@urql/core';

export const ADVERT_GALLERY_QUERY = gql`
    query Advert($id: Int64!) {
        advert(id: $id) {
            additionalInformation {
                label
                values
                unit
            }
            advertiserType
            category {
                id
            }
            images {
                medium
                large
            }
            links {
                localPlanUrl
                walkaroundUrl
                videoUrl
                view3dUrl
            }
            location: locationDetails {
                address {
                    city {
                        name
                    }
                }
            }
            openDay {
                date
                timeFrom
                timeTo
            }
            owner: legacyOwner {
                id
                name
                type
                phones
                imageUrl
                contacts {
                    name
                    phone
                    imageURLSmall
                }
            }
            source
            topInformation {
                label
                values
                unit
            }
        }
    }
`;
