import { STICKY_HEADER_HEIGHT } from '@domains/search/consts/stickyHeaderHeight';
import styled from '@emotion/styled';
import { LAYER } from '@lib/styles/partials/layers';
import { theme } from '@lib/styles/themes/nexus';

export const CallToActionButtonsWrapper = styled.div`
    display: flex;
    gap: 16px;
`;

export const StickyBarContainer = styled.div`
    display: flex;
    position: fixed;
    z-index: ${LAYER.x8};
    align-items: center;
    height: ${STICKY_HEADER_HEIGHT};
    background-color: ${theme.colors['background-global-primary']};
    inset: 0 0 auto;
`;

export const InnerStickyBarContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1280px;
    margin: 0 auto;
    padding: 0 ${theme.space[8]};
`;

export const PageTitle = styled.p`
    margin: 0;
    margin-bottom: ${theme.space[4]};
    color: ${theme.colors['text-brand-primary']};
    font-size: ${theme.fontSizes[20]};
    font-weight: ${theme.fontWeights[700]};
    line-height: ${theme.lineHeights[22]};
`;

export const PaginationDetails = styled.p`
    margin: 0;
    color: ${theme.colors['text-global-secondary']};
    font-size: ${theme.fontSizes[16]};
    line-height: ${theme.lineHeights[20]};
`;
