import { OWNER_TYPE } from '@type/ad/ownerType';
import type { FilterLocations } from '@type/location/filterLocation';
import { ESTATE } from '@type/search/filters/estate';
import { TRANSACTION } from '@type/search/filters/transaction';
import type { SearchingFilters } from '@type/search/searchingFilters';

export const countFilters = (
    filterAttributes: Partial<Omit<SearchingFilters, 'locations' | 'page' | 'limit'>>,
    filterLocations: FilterLocations<string | string[] | { id: unknown }[]> | null,
): number => {
    const filterLocationCount = filterLocations === null ? 0 : 1;
    const defaultFilterAttributes: typeof filterAttributes = {
        market: 'ALL',
        estate: ESTATE.flat,
        transaction: TRANSACTION.sell,
        distanceRadius: 0,
        ownerTypeSingleSelect: OWNER_TYPE.all,
    };

    type FilterKeys = keyof typeof filterAttributes;

    return (
        Object.keys(filterAttributes).filter((key) => {
            return defaultFilterAttributes[key as FilterKeys] !== filterAttributes[key as FilterKeys];
        }).length + filterLocationCount
    );
};
