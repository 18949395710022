import { Button } from '@domains/shared/components/Button/Button';
import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import { TooltipIcon } from '@domains/shared/components/TooltipIcon/TooltipIcon';
import styled from '@emotion/styled';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { SIZE } from '@lib/styles/partials/typography';
import { theme } from '@lib/styles/themes/nexus';
import Info from '@nexus/lib-react/dist/core/Icon/icons/default/Info';
import type { FC } from 'react';

interface Props {
    isClickable?: boolean;
}

export const Container = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 16px;
    color: ${({ theme }): string => theme.deprecated.domains.search.searchForm.saveSearchResults.color};

    @media (min-width: ${BREAKPOINT.md}) {
        border-left: 1px solid;
        border-left-color: ${({ theme }): string =>
            theme.deprecated.domains.search.searchForm.fieldSectionSeparator.borderColor};
    }
`;

export const DarkBlueTitleButton = styled(Button)<Props>`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${SIZE.p3};
    cursor: ${({ isClickable }): string => (isClickable ? 'pointer' : 'not-allowed')};
`;

export const IconTooltip = styled(TooltipIcon)`
    display: none;

    @media (min-width: ${BREAKPOINT.md}) {
        display: block;
        margin-left: 4px;
        color: ${({ theme }): string => theme.deprecated.domains.search.searchForm.saveSearchResults.tooltip.color};
    }
`;

const StyledIconBell = styled(Icon)`
    margin-right: 6px;
`;

const StyledIconChecked = styled(Icon)`
    margin-right: 6px;
    color: ${({ theme }): string => theme.deprecated.domains.search.searchForm.saveSearchResults.checkIcon.color};
`;

export const IconBell: FC = () => <StyledIconBell icon={faBell} width={12} height={14} />;

export const IconChecked: FC = () => <StyledIconChecked icon={faCheck} />;

export const NexusSaveSearchButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${theme.sizes[8]};
`;

export const StyledInfoIcon = styled(Info)`
    cursor: pointer;
`;
