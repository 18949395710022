import { useDeferredAbortableTracking } from '@domains/shared/hooks/useDeferredAbortableTracking/useDeferredAbortableTracking';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { Tooltip } from '@nexus/lib-react/dist/core/Tooltip';
import dayjs from 'dayjs';
import type { ComponentProps, JSX } from 'react';

import { TooltipTrigger } from './PushedUpAtLabel.theme';

export const TRACKING_DELAY = 2000;

type AllowedTooltipProps = Pick<ComponentProps<typeof Tooltip>, 'placement'>;

interface Props extends AllowedTooltipProps {
    datetime: string;
}

export const PushedUpAtLabel = ({ datetime, placement }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent, abortTracking } = useDeferredAbortableTracking(TRACKING_DELAY);

    const handleShowTooltip = (): void => {
        trackEvent('tooltip_onboarding_view', {
            touch_point_button: 'bumped_offer',
        });
    };

    const content = t('frontend.shared.ad-pushed-up-tooltip.refreshed-tooltip-text', {
        date: dayjs(datetime).format('DD.MM'),
        time: dayjs(datetime).format('HH:mm'),
    });

    return (
        <Tooltip
            content={content}
            placement={placement}
            trigger="mouseenter"
            onShow={handleShowTooltip}
            onHide={abortTracking}
        >
            <TooltipTrigger>{t('frontend.shared.ad-pushed-up-tooltip.refreshed')}</TooltipTrigger>
        </Tooltip>
    );
};
