import { AdvertSlot } from '@domains/shared/components/Advertising/AdvertSlot';
import type { JSX } from 'react';

export const ADVERTISEMENT_UNIT_INDEX = 3;
export const ADVERTISEMENT_UNIT_2_INDEX = 8;
interface Props {
    position?: number;
}
export const BaxterAdvertising = ({ position }: Props): JSX.Element => {
    if (position === ADVERTISEMENT_UNIT_INDEX) {
        return (
            <>
                <AdvertSlot id="baxter-l-nat1" />
                <AdvertSlot id="baxter-l-middle-1" />
            </>
        );
    }
    if (position === ADVERTISEMENT_UNIT_2_INDEX) {
        return <AdvertSlot id="baxter-l-nat2" />;
    }

    return <></>;
};
