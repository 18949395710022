import { SaveSearchConnectedButton } from '@domains/search/components/SaveSearchConnectedButton/SaveSearchConnectedButton';
import { useSaveSearches } from '@domains/search/hooks/useSaveSearches/useSaveSearches';
import { SEARCH_STATUS } from '@domains/search/types/searchStatus';
import { TooltipIcon } from '@domains/shared/components/TooltipIcon/TooltipIcon';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { logError } from '@domains/shared/helpers/logger';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { FieldsMetadataExperimentsVariants } from '@type/search/fieldsMetadataExperimentsVariants';
import type { ReactElement } from 'react';
import { useContext } from 'react';

import { SaveSearchFloatingWrapper } from './SaveSearchFloatingButton.theme';

interface Props {
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants;
    variant?: 'SAVE' | 'SAVE_FIXED';
    size?: 'small' | 'medium';
    trackingData?: Record<string, unknown>;
    shouldShowClicktip?: boolean;
}

export const SaveSearchFloatingButton = ({
    fieldsMetadataExperimentsVariants,
    variant,
    size = 'small',
    trackingData,
    shouldShowClicktip,
}: Props): ReactElement | null => {
    const { isDesktop } = useContext(RWDContext);
    const [t] = useTranslations();
    const { searchStatus } = useSaveSearches({ fieldsMetadataExperimentsVariants });
    const saveSearchButtonTooltipTimeout = isDesktop ? 0 : 4000;
    const saveSearchButtonCommonProps = {
        fieldsMetadataExperimentsVariants: fieldsMetadataExperimentsVariants,
        trackingData: trackingData,
        shouldShowClicktip: shouldShowClicktip,
    };

    switch (variant) {
        case 'SAVE':
            if (searchStatus === SEARCH_STATUS.saved) {
                return (
                    <TooltipIcon
                        timeout={saveSearchButtonTooltipTimeout}
                        description={t('frontend.search.form.saved-search.tooltip')}
                        icon={<SaveSearchConnectedButton {...saveSearchButtonCommonProps} size={size} />}
                    />
                );
            }

            return <SaveSearchConnectedButton {...saveSearchButtonCommonProps} size={size} />;
        case 'SAVE_FIXED':
            if (searchStatus === SEARCH_STATUS.saved) {
                return null;
            }

            return (
                <SaveSearchFloatingWrapper>
                    <SaveSearchConnectedButton {...saveSearchButtonCommonProps} />
                </SaveSearchFloatingWrapper>
            );
        default:
            logError('Unknown variant SaveSearchMapButton');

            return null;
    }
};

export default SaveSearchFloatingButton;
