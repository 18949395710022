import { SITE_CONFIG } from '@config/siteConfig';
import type { ModalVariant } from '@domains/search/components/SaveSearchResults/SaveSearchModal';
import { SaveSearchModal } from '@domains/search/components/SaveSearchResults/SaveSearchModal';
import { MapDrawingContext, SAVE_SEARCH_AFTER_REDIRECT_EVENT } from '@domains/search/contexts/MapDrawingContext';
import { useSaveSearchEffects } from '@domains/search/hooks/useSaveSearchEffects';
import { useSaveSearches } from '@domains/search/hooks/useSaveSearches/useSaveSearches';
import type { SaveSearchEventData } from '@domains/search/types/search';
import { SEARCH_PAGE_EVENT } from '@domains/search/types/search';
import { SEARCH_STATUS } from '@domains/search/types/searchStatus';
import { EXPERIMENT } from '@domains/shared/consts/experiment';
import { ViewTypeContext } from '@domains/shared/contexts/ViewTypeContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { listenForPlatformEvent, unmountPlatformEvent } from '@lib/events';
import { useExperiments } from '@lib/experiments/client/ExperimentsProvider';
import { useLazyUser } from '@lib/pages/contexts/LazyUserContext/useLazyUser';
import { Tooltip } from '@nexus/lib-react/dist/core/Tooltip';
import type { FieldsMetadataExperimentsVariants } from '@type/search/fieldsMetadataExperimentsVariants';
import type { JSX } from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';

import { SaveSearchButton } from './SaveSearchButton';
import { SaveSearchClicktip } from './SaveSearchClicktip';
import { Container, IconTooltip, NexusSaveSearchButtonWrapper, StyledInfoIcon } from './SaveSearchResults.theme';

export const SAVE_SEARCH_PARAMETER = '#saveSearch';

interface Props {
    pageTitle?: string;
    shouldDisplaySaveSearchButton: boolean;
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants;
    shouldUseNexusTheme?: boolean;
}

type TrackingPoint = 'search_form' | 'sticky_bar' | 'map';

const TRACKING_DATA_FOR_SOCIAL_LOGINS = {
    touch_point_button: 'favourite_search_save',
} as const;

export const SaveSearchResults = ({
    fieldsMetadataExperimentsVariants,
    shouldDisplaySaveSearchButton,
    shouldUseNexusTheme = false,
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const { isAwaitingUserData, isUserLoggedIn } = useLazyUser();
    const { searchStatus, saveCurrentSearch, searchURL } = useSaveSearches({ fieldsMetadataExperimentsVariants });
    const { triggerSuccessEffects, triggerErrorEffects, triggerSaveAttemptEffects } = useSaveSearchEffects();
    const { viewType } = useContext(ViewTypeContext);
    const touchPointPage = viewType === 'map' ? 'map_listing' : 'listing';
    const { customAreaPolygon } = useContext(MapDrawingContext);

    const { atlasRedirects } = SITE_CONFIG;
    const { isVariantEnabled } = useExperiments();
    const isBypassLoginModalEnabled = isVariantEnabled(EXPERIMENT.bypassLoginModal, 'b');

    const [currentModal, setCurrentModal] = useState<ModalVariant>(null);
    const [isSaveSearchButtonClicked, setIsSaveSearchButtonClicked] = useState(false);
    const [searchButtonTrackingPoint, setSearchButtonTrackingPoint] = useState<TrackingPoint | null>(null);
    const tooltipTextKey =
        searchStatus === SEARCH_STATUS.saved
            ? 'frontend.search.form.saved-search-tooltip'
            : 'frontend.search.form.save-search-description';

    const saveSearchQuery = useCallback(
        (trackingPoint: TrackingPoint): void => {
            saveCurrentSearch()
                .then(() => {
                    return triggerSuccessEffects({
                        trackingExtraData: {
                            touch_point_button: trackingPoint,
                            touch_point_page: touchPointPage,
                        },
                    });
                })
                .catch(triggerErrorEffects);
        },
        [saveCurrentSearch, touchPointPage, triggerErrorEffects, triggerSuccessEffects],
    );

    const handleHashChangeEvent = useCallback((): void => {
        if (searchStatus !== SEARCH_STATUS.notSaved) {
            return;
        }

        if (typeof window !== 'undefined' && window.location.hash.includes(SAVE_SEARCH_PARAMETER)) {
            const trackingPoint = window.location.hash.includes('sticky_bar') ? 'sticky_bar' : 'search_form';

            saveSearchQuery(trackingPoint);

            window.removeEventListener('hashchange', handleHashChangeEvent, false);
            window.location.hash = '';
        }
    }, [saveSearchQuery, searchStatus]);

    const saveSearchAfterRedirect = useCallback(() => {
        if (
            typeof window !== 'undefined' &&
            window.location.hash === `${SAVE_SEARCH_PARAMETER}_map` &&
            customAreaPolygon
        ) {
            saveSearchQuery('map');
        }
    }, [customAreaPolygon, saveSearchQuery]);

    useEffect(() => {
        listenForPlatformEvent(SAVE_SEARCH_AFTER_REDIRECT_EVENT, saveSearchAfterRedirect);

        return (): void => {
            unmountPlatformEvent(SAVE_SEARCH_AFTER_REDIRECT_EVENT, saveSearchAfterRedirect);
        };
    }, [saveSearchAfterRedirect]);

    useEffect(() => {
        /* this call is needed to subscribe after page gets reloaded during email login */
        handleHashChangeEvent();

        window.addEventListener('hashchange', handleHashChangeEvent, false);

        /* listener should be removed when user leaves the page */
        return (): void => window.removeEventListener('hashchange', handleHashChangeEvent, false);
    }, [handleHashChangeEvent]);

    const saveSearchResults = useCallback(
        (trackingPoint: TrackingPoint): void => {
            setIsSaveSearchButtonClicked(true);
            triggerSaveAttemptEffects({ touch_point_button: trackingPoint, touch_point_page: touchPointPage });
            if (isUserLoggedIn) {
                saveSearchQuery(trackingPoint);
            } else {
                if (isBypassLoginModalEnabled) {
                    window.location.assign(
                        `${atlasRedirects.hciamLoginPage}/?redirect_uri=${encodeURIComponent(`${window.location.href}${SAVE_SEARCH_PARAMETER}`)}`,
                    );
                } else {
                    setCurrentModal('NotLoggedInModal');
                }
            }
        },
        [
            atlasRedirects.hciamLoginPage,
            isBypassLoginModalEnabled,
            isUserLoggedIn,
            saveSearchQuery,
            touchPointPage,
            triggerSaveAttemptEffects,
        ],
    );

    const handleSaveSearchButtonClick = useCallback(
        (trackingPoint: TrackingPoint): void => {
            setSearchButtonTrackingPoint(trackingPoint);
            saveSearchResults(trackingPoint);
        },
        [saveSearchResults],
    );

    const saveSearchButton = (
        <SaveSearchButton
            shouldUseNexusTheme={shouldUseNexusTheme}
            status={isAwaitingUserData ? 'BLOCKED' : searchStatus}
            onClick={(): void => handleSaveSearchButtonClick(viewType === 'map' ? viewType : 'search_form')}
        />
    );

    useEffect(() => {
        const searchSavedEventCallback = ({ detail }: CustomEvent<SaveSearchEventData>): void =>
            handleSaveSearchButtonClick(detail.trackingPoint);

        listenForPlatformEvent(SEARCH_PAGE_EVENT.saveSearch, searchSavedEventCallback);

        return (): void => {
            unmountPlatformEvent(SEARCH_PAGE_EVENT.saveSearch, searchSavedEventCallback);
        };
    }, [handleSaveSearchButtonClick]);

    return (
        <>
            {shouldDisplaySaveSearchButton && !shouldUseNexusTheme ? (
                <Container data-cy="dark.save-search.button" data-testid="dark.save-search.button">
                    <SaveSearchClicktip
                        button={saveSearchButton}
                        initialPosition="bottom"
                        xShift={62}
                        shouldHideOnMobile // hides the tooltip in the mobile form
                        isButtonClicked={isSaveSearchButtonClicked}
                    />
                    <IconTooltip description={t(tooltipTextKey)} />
                </Container>
            ) : null}

            {shouldDisplaySaveSearchButton && shouldUseNexusTheme ? (
                <NexusSaveSearchButtonWrapper data-testid="save-search-nexus-button">
                    {saveSearchButton}
                    <Tooltip trigger="mouseenter" placement="top" content={t(tooltipTextKey)}>
                        <StyledInfoIcon />
                    </Tooltip>
                </NexusSaveSearchButtonWrapper>
            ) : null}

            <SaveSearchModal
                currentModal={currentModal}
                afterLoginRedirectPath={`${searchURL}${SAVE_SEARCH_PARAMETER}_${searchButtonTrackingPoint}`}
                onClose={(): void => setCurrentModal(null)}
                onRegisterModalOpen={(): void => setCurrentModal('RegistrationModal')}
                extraTrackingData={TRACKING_DATA_FOR_SOCIAL_LOGINS}
                fieldsMetadataExperimentsVariants={fieldsMetadataExperimentsVariants}
            />
        </>
    );
};
