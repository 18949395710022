import { gql } from '@urql/core';

export const FILTER_ATTRIBUTES_FRAGMENT = gql`
    fragment FilterAttributesFragment on SavedFilterAttributes {
        areaMin
        areaMax
        buildYearMin
        buildYearMax
        daysSinceCreated
        distanceRadius
        isExclusiveOffer
        estate
        extras
        floorsNumberMin
        floorsNumberMax
        freeFrom
        description
        hasMovie
        hasOpenDay
        hasPhotos
        hasRemoteServices
        hasWalkaround
        advertId
        isPrivateOwner
        market
        priceMin
        priceMax
        pricePerMeterMin
        pricePerMeterMax
        roomsNumber
        isPromoted
        useTypes
        buildingType
        vicinity
        plotType
        buildingMaterial
        floors
        isForStudents
        isBungalov
        isRecreational
        terrainAreaMin
        terrainAreaMax
        roofType
        media
        peoplePerRoom
        heightMin
        heightMax
        structure
        heating
        transaction
        commodity
        condition
        divisioningType
        infrastructure
        surroundings
        main
        wcCount
        security
        ownerTypeSingleSelect
    }
`;
