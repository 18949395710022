import { SearchFormValuesContext } from '@domains/shared/contexts/SearchFormValuesContext';
import type { RoomsNumber } from '@type/search/filters/roomsNumber';
import { useContext } from 'react';

/** Tracking data properties that are taken from search filters */
export const useFiltersTrackingSet = (): Record<string, unknown> => {
    const { filteringQueryParams } = useContext(SearchFormValuesContext);

    const roomsNumbers = filteringQueryParams?.roomsNumber
        ? getTransformedRoomsNumber(filteringQueryParams.roomsNumber)
        : null;

    return {
        nb_rooms: roomsNumbers,
    };
};

const getTransformedRoomsNumber = (rooms: RoomsNumber[]): string[] => {
    const values: Record<RoomsNumber, string> = {
        /* eslint-disable @typescript-eslint/naming-convention -- RoomNumber entries are UPPERCASED */
        ONE: '1',
        TWO: '2',
        THREE: '3',
        FOUR: '4',
        FIVE: '5',
        SIX: '6',
        SIX_OR_MORE: '6+',
        SEVEN: '7',
        EIGHT: '8',
        NINE: '9',
        TEN: '10',
        TEN_OR_MORE: '10+',
        MORE: 'more',
        /* eslint-enable @typescript-eslint/naming-convention */
    };

    return rooms.map((room) => values[room]).sort((a, b) => a.localeCompare(b));
};
