import { AdvertCard } from '@domains/search/components/AdvertCard/AdvertCard';
import { PropertyPriceEvaluationBanner } from '@domains/search/components/PropertyPriceEvaluationBanner/PropertyPriceEvaluationBanner';
import type { AdvertListItem } from '@domains/search/types/advertListItem';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { SearchFormValuesContext } from '@domains/shared/contexts/SearchFormValuesContext';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { SetSimilarAdsPromptId } from '@type/favorites/similarAdsPromptId';
import { ESTATE } from '@type/search/filters/estate';
import { TRANSACTION } from '@type/search/filters/transaction';
import { SEARCH_FORM_UNIVERSAL_FIELD } from '@type/search/searchFormUniversalField';
import type { JSX, MutableRefObject } from 'react';
import { Fragment, useContext } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { Advertising } from './components/Advertising/Advertising';
import { ADVERTISEMENT_UNIT_2_INDEX, ADVERTISEMENT_UNIT_INDEX } from './components/Advertising/BaxterAdvertising';
import { Heading } from './components/Heading/Heading';
import { useListingChunks } from './hooks/useListingChunks';
import { StyledList } from './Listing.theme';

interface Props {
    title?: string;
    subtitle?: string | JSX.Element[];
    items: AdvertListItem[];
    propertyEvaluationBannerIndex?: number;
    isNearbySection?: boolean;
    isPromotedSection?: boolean;
    shouldDisableTopBorder?: boolean;
    shouldRenderAds?: boolean;
    shouldRenderGoToPromotedLink?: boolean;
    cyContainer?: string;
    cyTitle?: string;
    setSimilarAdsPromptId?: SetSimilarAdsPromptId;
    titleRef?: MutableRefObject<HTMLSpanElement | null>;
}

export const Listing = ({
    title = 'frontend.search.listing.title',
    subtitle,
    items,
    propertyEvaluationBannerIndex = 15,
    isNearbySection = false,
    isPromotedSection,
    shouldDisableTopBorder = false,
    shouldRenderAds = false,
    shouldRenderGoToPromotedLink,
    cyContainer = 'search.listing',
    cyTitle = 'search.listing.title',
    setSimilarAdsPromptId,
    titleRef,
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const { isDesktop } = useContext(RWDContext);

    const { featureFlags } = useSiteSettings();
    const { isPropertyEvaluationPageActive } = featureFlags;
    const { control } = useForm();
    const isSpecialOfferToggleOn = useWatch({ control, name: SEARCH_FORM_UNIVERSAL_FIELD.hasDiscount });

    const { filteringQueryParams, estate, transaction } = useContext(SearchFormValuesContext);
    const displayedListingPage = filteringQueryParams?.page;
    const isFirstPage = displayedListingPage === 1 || !displayedListingPage; // "page" query param is undefined on first page
    const isApartmentForSale = estate === ESTATE.flat && transaction === TRANSACTION.sell; // needed to display ppe banner

    // Chunking only happens when `!(isDesktop || !isFeaturedDevelopmentVASEnabled)`
    const { firstChunk, secondChunk, secondChunkStartIndex, resultsRenderedOnInit } = useListingChunks(items);

    const shouldPotentiallyDisplayPropertyBanner = isFirstPage && isApartmentForSale && isPropertyEvaluationPageActive;

    return (
        <div data-cy={cyContainer}>
            <Heading
                title={t(title)}
                cyTitle={cyTitle}
                subtitle={subtitle}
                shouldDisableTopBorder={shouldDisableTopBorder}
                shouldRenderGoToPromotedLink={shouldRenderGoToPromotedLink}
                titleRef={titleRef}
            />
            <StyledList>
                {firstChunk.map((item, index) => {
                    const shouldDisplayPropertyEvaluationBannerInFirstChunk =
                        shouldPotentiallyDisplayPropertyBanner && index === propertyEvaluationBannerIndex && isDesktop;

                    const shouldRenderAdvertisement =
                        shouldRenderAds && (index === ADVERTISEMENT_UNIT_INDEX || index === ADVERTISEMENT_UNIT_2_INDEX);

                    return (
                        <Fragment key={item.id}>
                            {shouldRenderAdvertisement ? (
                                <li>
                                    <Advertising position={index} />
                                </li>
                            ) : null}

                            {shouldDisplayPropertyEvaluationBannerInFirstChunk ? (
                                <li>
                                    <PropertyPriceEvaluationBanner />
                                </li>
                            ) : null}

                            <li>
                                <AdvertCard
                                    index={index}
                                    item={item}
                                    isNearbySection={isNearbySection}
                                    isPromotedSection={isPromotedSection}
                                    isSpecialOfferToggleOn={isSpecialOfferToggleOn}
                                    setSimilarAdsPromptId={setSimilarAdsPromptId}
                                    shouldLazyLoadGallery={index >= resultsRenderedOnInit}
                                />
                            </li>
                        </Fragment>
                    );
                })}
            </StyledList>

            {secondChunk?.length ? (
                <StyledList>
                    {secondChunk.map((item, index) => {
                        const shouldDisplayPropertyEvaluationBannerInSecondChunk =
                            shouldPotentiallyDisplayPropertyBanner &&
                            index === propertyEvaluationBannerIndex &&
                            !isDesktop;

                        return (
                            <Fragment key={item.id}>
                                {shouldDisplayPropertyEvaluationBannerInSecondChunk ? (
                                    <li>
                                        <PropertyPriceEvaluationBanner />
                                    </li>
                                ) : null}

                                <li>
                                    <AdvertCard
                                        index={secondChunkStartIndex + index}
                                        item={item}
                                        isNearbySection={isNearbySection}
                                        isPromotedSection={isPromotedSection}
                                        isSpecialOfferToggleOn={isSpecialOfferToggleOn}
                                        shouldLazyLoadGallery={firstChunk.length >= resultsRenderedOnInit}
                                        setSimilarAdsPromptId={setSimilarAdsPromptId}
                                    />
                                </li>
                            </Fragment>
                        );
                    })}
                </StyledList>
            ) : null}
        </div>
    );
};
