import type { ParsedUrlQuery } from 'node:querystring';

import { SEARCH_CONFIG } from '@config/search/searchConfig';
import { URL_LOCATION_FILLER } from '@domains/shared/consts/urlLocationFiller';
import { getTransactionVariant } from '@domains/shared/helpers/getTransactionVariant';
import { logWarn } from '@domains/shared/helpers/logger';
import { checkIsOwnerType } from '@type/ad/checkOwnerType';
import type { OwnerType } from '@type/ad/ownerType';
import { OWNER_TYPE } from '@type/ad/ownerType';
import type { FieldsMetadataExperimentsVariants } from '@type/search/fieldsMetadataExperimentsVariants';
import type { Estate } from '@type/search/filters/estate';
import { ESTATE } from '@type/search/filters/estate';
import type { Market } from '@type/search/filters/market';
import type { RoomsNumber } from '@type/search/filters/roomsNumber';
import type { Transaction } from '@type/search/filters/transaction';
import type { UseTypes } from '@type/search/filters/useTypes';
import type { SearchFormUniversalFieldName } from '@type/search/searchFormUniversalField';
import { SEARCH_FORM_UNIVERSAL_FIELD } from '@type/search/searchFormUniversalField';
import type { SegmentType } from '@type/search/segmentTypes';
import { SEGMENT_TYPE } from '@type/search/segmentTypes';
import type { URLSegment } from '@type/search/urlSegments';
import { findSegmentByLabel } from '@widgets/search/findSegmentByLabel';
import { getSegmentsByType } from '@widgets/search/getSegment';
import { parseQueryParamsToSearchingFilters } from '@widgets/search/parseQueryParamsToSearchingFilters';

export interface Criteria {
    transaction: Transaction | null;
    estate: Estate | null;
    location: string | null;
    market: Market;
    ownerTypeSingleSelect: OwnerType;
    roomsNumber: RoomsNumber[];
    useTypes: UseTypes[];
}

/**
 * Recognize URL segment translated value and tells what actual parameter means, eg. 'sprzedaz' -> 'SELL'
 * Also recognize wrong values, so we can monitor possible corrupted URLs or even if some user is trying do something bad.
 * @param value
 * @param segmentType
 * @private
 */
const getSegmentByLabel = <T = string>(value: string, segmentType: SegmentType): T | null => {
    // Get all possible segments
    const segmentsForPlatform = getSegmentsByType(segmentType);
    // Find the one with `label`
    const segment = segmentsForPlatform?.find((element: URLSegment) => element.label === value);
    // Validate segment value

    if (!segment) {
        logWarn(`Incorrect value in URL segment: [${segmentType}]: ${value}`);

        return null;
    }

    return segment.value as T;
};

const removeSegment = (urlSlotValue: string[], segmentType: SegmentType): string[] => {
    // Get all possible segments
    const segmentsForPlatform = getSegmentsByType(segmentType);

    if (!segmentsForPlatform) {
        return urlSlotValue;
    }

    return urlSlotValue.filter((slug) => !segmentsForPlatform.some((segment) => segment.label === slug));
};

/**
 * Checks whether a passed filter field is available for the estate-transaction pair
 *
 * @param estate
 * @param transaction
 * @param searchFormUniversalField
 */
const isFilterAvailable = (
    estate: Estate,
    transaction: Transaction,
    searchFormUniversalField: SearchFormUniversalFieldName,
): boolean => {
    const variant = getTransactionVariant({ estate, transaction });
    const availableFilters = SEARCH_CONFIG.searchBoxFieldsLayout[variant]?.default || [];

    return availableFilters.some((filters) => filters.includes(searchFormUniversalField));
};

/**
 * Recognize location parameter and construct one string from many segments.
 * Recognize when multiple location is used.
 * @param locationURLSlotValue
 * @private
 */
const determineLocation = (locationURLSlotValue: string[]): string => {
    const location = locationURLSlotValue.join('/'); // Join many segments into one string
    const multipleLocationURLFiller = URL_LOCATION_FILLER.multipleLocationsAreSpecified; // Get keyword for many locations
    const isNoLocationSelected = location === null; // Check if there is no location at all
    const isMultipleLocationSelected = location === multipleLocationURLFiller.label; // Check if location is 'many places'

    if (isNoLocationSelected) {
        return '';
    }
    if (isMultipleLocationSelected) {
        return multipleLocationURLFiller.value;
    }

    return location;
};

interface DetermineMarketResponse {
    market: Market;
    locationSlot: string[];
}
const determineMarket = (
    filtersSlotValue: string[],
    transaction: Transaction | null,
    estate: Estate | null,
    locationSlot: string[],
    marketQuery?: Market,
): DetermineMarketResponse => {
    const updatedLocationSlot = removeSegment(locationSlot, SEGMENT_TYPE.market);

    if (estate === ESTATE.investment) {
        return {
            market: 'PRIMARY',
            locationSlot: updatedLocationSlot,
        };
    }

    let market: Market | null = null;

    if (transaction && estate && isFilterAvailable(estate, transaction, SEARCH_FORM_UNIVERSAL_FIELD.market)) {
        // we want to support the old URL structure where the market type can be added after the location, e.g.: /pl/wyniki/sprzedaz/mieszkanie/mazowieckie/warszawa/warszawa/warszawa/bemowo/rynek-pierwotny
        market = findSegmentByLabel<Market>(locationSlot, SEGMENT_TYPE.market);

        // check the filters URL segment, e.g.: /pl/wyniki/sprzedaz/mieszkanie,rynek-pierwotny/mazowieckie/warszawa/warszawawarszawa/bemowo
        if (!market && filtersSlotValue) {
            market = findSegmentByLabel<Market>(filtersSlotValue, SEGMENT_TYPE.market);
        }
    }

    // we want to support the "market" query parameter (e.g. some users can have saved URLs with this query param: /pl/wyniki/sprzedaz/mieszkanie/mazowieckie/warszawa/warszawa/warszawa/bemowo?market=PRIMARY)
    if (marketQuery && !market) {
        return {
            market: marketQuery,
            locationSlot: updatedLocationSlot,
        };
    }

    return {
        market: market || 'ALL',
        locationSlot: updatedLocationSlot,
    };
};

const determineNumberOfRooms = (
    filtersSlotValue: string[],
    transaction: Transaction | null,
    estate: Estate | null,
    roomsNumberQuery?: RoomsNumber[],
): RoomsNumber[] => {
    let numberOfRooms: RoomsNumber | null = null;

    if (
        filtersSlotValue &&
        transaction &&
        estate &&
        isFilterAvailable(estate, transaction, SEARCH_FORM_UNIVERSAL_FIELD.roomsNumber)
    ) {
        numberOfRooms = findSegmentByLabel<RoomsNumber>(filtersSlotValue, SEGMENT_TYPE.roomsNumber);
    }

    // we want to support the "roomsNumber" query parameter (e.g. some users can have saved URLs with this query param)
    if (roomsNumberQuery && !numberOfRooms) {
        return roomsNumberQuery;
    }

    if (!numberOfRooms) {
        return [];
    }

    return [numberOfRooms];
};

const determineUseTypes = (
    filtersSlotValue: string[],
    transaction: Transaction | null,
    estate: Estate | null,
    useTypesQuery?: UseTypes[],
): UseTypes[] => {
    let useTypes: UseTypes | null = null;

    if (
        filtersSlotValue &&
        transaction &&
        estate &&
        isFilterAvailable(estate, transaction, SEARCH_FORM_UNIVERSAL_FIELD.useTypes)
    ) {
        useTypes = findSegmentByLabel<UseTypes>(filtersSlotValue, SEGMENT_TYPE.useTypes);
    }

    // we want to support the "useTypes" query parameter (e.g. some users can have saved URLs with this query param)
    if (useTypesQuery && !useTypes) {
        return useTypesQuery;
    }

    if (!useTypes) {
        return [];
    }

    return [useTypes];
};

const determineOwnerTypeSingleSelect = (urlQuery?: ParsedUrlQuery): OwnerType => {
    if (urlQuery?.ownerTypeSingleSelect) {
        const valueAsOwnerType = urlQuery.ownerTypeSingleSelect;

        if (checkIsOwnerType(valueAsOwnerType)) {
            return valueAsOwnerType;
        }
    }

    return OWNER_TYPE.all;
};

export const getSearchCriteria = ({
    fieldsMetadataExperimentsVariants,
    urlQuery,
    urlSegments,
}: {
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants;
    urlSegments: string[];
    urlQuery?: ParsedUrlQuery;
}): Criteria => {
    const [transactionTypeURLSlot, filtersURLSlot, ...locationURLSlots] = urlSegments; // Segments in strict order
    const [estateURLSlot, ...filters] = filtersURLSlot.split(',');
    const estate = findSegmentByLabel<Estate>([estateURLSlot], SEGMENT_TYPE.estate);
    const transaction = getSegmentByLabel<Transaction>(transactionTypeURLSlot, SEGMENT_TYPE.transaction);

    const filteringQueryParams = parseQueryParamsToSearchingFilters({
        fieldsMetadataExperimentsVariants,
        queryParams: { ...urlQuery },
        paramsScope: estate && transaction ? { estate, transaction } : undefined,
    });

    const ownerTypeSingleSelect = determineOwnerTypeSingleSelect(urlQuery);
    const { market, locationSlot: filteredLocationURLSlots } = determineMarket(
        filters,
        transaction,
        estate,
        locationURLSlots,
        filteringQueryParams?.market,
    );
    const roomsNumber = determineNumberOfRooms(filters, transaction, estate, filteringQueryParams?.roomsNumber);
    const useTypes = determineUseTypes(filters, transaction, estate, filteringQueryParams?.useTypes);
    let location = determineLocation(filteredLocationURLSlots);

    if (!estate && transaction) {
        // if estate type has not been found treat all segments after the first one as location
        location = determineLocation([filtersURLSlot, ...filteredLocationURLSlots]);
    } else if (!estate && !transaction) {
        // if no estate nor transaction segment is detected treat all segments as locations
        location = determineLocation(urlSegments);
    }

    return {
        estate,
        transaction,
        location,
        market,
        ownerTypeSingleSelect,
        roomsNumber,
        useTypes,
    };
};
