import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const SaveSearchFloatingWrapper = styled.div`
    display: flex;
    position: fixed;
    z-index: ${theme.zIndices.fixed};
    bottom: 24px;
    left: 0;
    justify-content: center;
    width: 92vw;
    margin: 0 4vw;
`;
