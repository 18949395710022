import type { FilterAttributesInput } from '@domains/search/types/searchCriteriaInput';
import { checkIsOption } from '@type/search/dropdownOption';
import type { SearchFormUniversalFieldName } from '@type/search/searchFormUniversalField';
import { SEARCH_FORM_UNIVERSAL_FIELD } from '@type/search/searchFormUniversalField';

type AvailableFilterKey = Exclude<SearchFormUniversalFieldName, 'location' | 'locations'>;

const AVAILABLE_FILTERS = new Set(
    Object.values(SEARCH_FORM_UNIVERSAL_FIELD).filter((key): key is AvailableFilterKey => {
        return key !== SEARCH_FORM_UNIVERSAL_FIELD.location && key !== SEARCH_FORM_UNIVERSAL_FIELD.locations;
    }),
);

const isSearchFormKey = (key: string): boolean => {
    let keyName = key;

    if (keyName.includes('Min')) {
        keyName = keyName.split('Min')[0];
    }

    if (keyName.includes('Max')) {
        keyName = keyName.split('Max')[0];
    }

    return AVAILABLE_FILTERS.has(keyName as AvailableFilterKey);
};

// @TODO: REPT-1778 improve types due to SearchFormUniversalField limitations
export const getFilterAttributes = (values: Record<string, unknown>): FilterAttributesInput => {
    if (!values) {
        return {};
    }

    return Object.fromEntries(
        Object.entries(values)
            .filter(([key]) => isSearchFormKey(key))
            .map(([key, value]) => {
                let extractedValue = checkIsOption(value) ? value.value : value;

                if (key === SEARCH_FORM_UNIVERSAL_FIELD.distanceRadius) {
                    return [key, Number(extractedValue)];
                }

                if (Array.isArray(extractedValue)) {
                    extractedValue = extractedValue.filter(Boolean);
                }

                return [key, extractedValue];
            })
            .filter(([key, value]) => {
                const canSkipParam =
                    (key === SEARCH_FORM_UNIVERSAL_FIELD.distanceRadius && Number(value) === 0) ||
                    /**
                     * Any value that is set to false could be threat as unused
                     * Fix error when false was set as "extras" value which should be null or array
                     */
                    value === false ||
                    value === '';

                return !canSkipParam;
            }),
    );
};
