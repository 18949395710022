import { toast } from '@domains/shared/components/Toast/toast';
import { useLocalStorageCache } from '@domains/shared/hooks/useLocalStorageCache/useLocalStorageCache';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { CurrentUserContext } from '@lib/currentUser';
import { useTracking } from '@lib/tracking/useTracking';
import { useContext, useEffect } from 'react';

export const SHORT_REGISTER_EMAIL_CACHE_PREFIX = 'SHORT_REGISTERED_EMAIL';

/**
 * This hook watch for current email and cached register email.
 * If both matches it sends a tracking data about user registering using short register flow,
 * show save search success toast
 * and cleans cached email
 */
export const useRegisterTracking = (): void => {
    const [t] = useTranslations();
    const currentUser = useContext(CurrentUserContext);
    const { trackEvent } = useTracking();

    const [getRegisterEmail, setRegisterEmail] = useLocalStorageCache(SHORT_REGISTER_EMAIL_CACHE_PREFIX);

    useEffect(() => {
        const cacheData = getRegisterEmail();
        const savedRegisterEmail = typeof cacheData === 'string' ? cacheData : undefined;

        if (savedRegisterEmail && currentUser?.email === savedRegisterEmail) {
            setRegisterEmail(null);
            trackEvent('favourite_search_save_success', {
                touch_point_page: 'listing',
                touch_point_button: 'favourite_search_save',
            });
            toast.success(t('frontend.search.save-search-results.create-search-success'));
        }
    }, [currentUser, getRegisterEmail, setRegisterEmail, t, trackEvent]);
};
