import type { SearchAdverts } from '@domains/search/types/searchAdverts';
import { logError } from '@domains/shared/helpers/logger';
import type { Feature, Properties } from '@turf/helpers';
import type { Geometry } from '@type/search/location/dataItems';

const parseAndGetGeometryFromFoundAds = (searchAds: SearchAdverts): Geometry | undefined => {
    try {
        const firstGeometry = searchAds?.geometries?.length ? searchAds.geometries[0] : undefined;

        if (firstGeometry) {
            const parsedFeatureGeometry = JSON.parse(firstGeometry) as Feature<Geometry, Properties>;

            return parsedFeatureGeometry?.geometry;
        }
    } catch (error) {
        logError('[parseAndGetGeometryFromFoundAds] error when parsing', { error });
    }
};

const LOCATION_DOMAIN_IDS_TO_EXCLUDE_FALLBACK: string[] = [];

export const useDisplayNearbyAdverts = (
    searchAds: SearchAdverts,
): {
    shouldDisplayNearbyAdverts: boolean;
    initialSearchArea: Geometry | undefined;
    locationDomainIdsToExclude: string[];
} => {
    const isLastPage = searchAds.pagination.page === searchAds.pagination.totalPages;
    const isNoResults = searchAds.pagination.totalResults === 0;

    const initialSearchArea = parseAndGetGeometryFromFoundAds(searchAds);

    const shouldDisplayNearbyAdverts = (isLastPage || isNoResults) && !!initialSearchArea;

    const locationDomainIdsToExclude =
        searchAds.locationsObjects?.map((locationObject) => locationObject.id) ??
        LOCATION_DOMAIN_IDS_TO_EXCLUDE_FALLBACK;

    return {
        shouldDisplayNearbyAdverts,
        initialSearchArea,
        locationDomainIdsToExclude,
    };
};
