import { RWDContext } from '@domains/shared/contexts/RWDContext';
import type { FC, PropsWithChildren } from 'react';
import { useContext } from 'react';

import { MobileContentWrapper } from './Mobile.theme';

export const Mobile: FC<PropsWithChildren> = ({ children }) => {
    const { isDesktop } = useContext(RWDContext);

    return isDesktop === null || isDesktop === false ? <MobileContentWrapper>{children}</MobileContentWrapper> : null;
};
