import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { SIZE } from '@lib/styles/partials/typography';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 16px 0;

    @media (min-width: ${BREAKPOINT.md}) {
        padding: 0 16px 0 0;
    }
`;

export const Title = styled.strong`
    font-size: ${SIZE.p1};
`;
