import { SaveSearchButton } from '@domains/search/components/SaveSearchResults/SaveSearchButton';
import { EXPERIMENT } from '@domains/search/consts/experiment';
import { getDisplayedAdsIndexRange } from '@domains/search/helpers/getDisplayedAdsIndexRange';
import { useIsSaveSearchButtonVisible } from '@domains/search/hooks/useIsSaveSearchButtonVisible';
import { useMapTracking } from '@domains/search/hooks/useMapTracking';
import { useSaveSearches } from '@domains/search/hooks/useSaveSearches/useSaveSearches';
import { SEARCH_PAGE_EVENT } from '@domains/search/types/search';
import { SEARCH_STATUS } from '@domains/search/types/searchStatus';
import { usePaginationParameters } from '@domains/shared/hooks/usePaginationParameters/usePaginationParameters';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { dispatchPlatformEvent } from '@lib/events';
import { useExperiments } from '@lib/experiments/client/ExperimentsProvider';
import type { FieldsMetadataExperimentsVariants } from '@type/search/fieldsMetadataExperimentsVariants';
import type { JSX } from 'react';

import { ChangeViewTypeButton } from '../ChangeViewTypeButton/ChangeViewTypeButton';
import {
    CallToActionButtonsWrapper,
    InnerStickyBarContainer,
    PageTitle,
    PaginationDetails,
    StickyBarContainer,
} from './StickyHeader.theme';

interface Props {
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants;
    totalResults: number;
    pageTitle?: string;
}

const handleSaveSearchButtonClick = (): void => {
    dispatchPlatformEvent(SEARCH_PAGE_EVENT.saveSearch, { trackingPoint: 'sticky_bar' });
};

export const StickyHeader = ({ fieldsMetadataExperimentsVariants, totalResults, pageTitle }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { trackViewChange } = useMapTracking();
    const { isVariantEnabled } = useExperiments();

    const { searchStatus } = useSaveSearches({ fieldsMetadataExperimentsVariants });
    const shouldDisplaySaveSearchButton = useIsSaveSearchButtonVisible();
    const isSaveSearchButtonVisible = shouldDisplaySaveSearchButton && searchStatus !== SEARCH_STATUS.saved;

    const shortPageTitle = pageTitle?.slice(0, Math.max(0, pageTitle.indexOf(' |')));

    const { page, limit } = usePaginationParameters();
    const displayedAdsIndexRange = getDisplayedAdsIndexRange({ page, limit, totalResults });

    const shouldDisplayShowMapButton = !isVariantEnabled(EXPERIMENT.eure27419, 'b');

    const handleTrackViewChange = (): void => {
        trackViewChange({
            listing_format: 'map',
            touch_point_button: 'map_sticky_bar',
            touch_point_page: 'map',
        });
    };

    return (
        <StickyBarContainer data-cy="sticky-dark.save-search.button" data-testid="sticky-header">
            <InnerStickyBarContainer>
                <div>
                    <PageTitle>{shortPageTitle}</PageTitle>
                    <PaginationDetails>
                        {t('frontend.search.listing-panel.ads-number', {
                            displayedAdsIndexRange,
                            totalResults: totalResults.toString(),
                        })}
                    </PaginationDetails>
                </div>

                <CallToActionButtonsWrapper>
                    {isSaveSearchButtonVisible ? (
                        <SaveSearchButton
                            size="small"
                            shouldUseNexusTheme={true}
                            status={searchStatus}
                            onClick={handleSaveSearchButtonClick}
                        />
                    ) : null}
                    {shouldDisplayShowMapButton ? (
                        <ChangeViewTypeButton renderPlace="stickyTopbar" onViewChange={handleTrackViewChange} />
                    ) : null}
                </CallToActionButtonsWrapper>
            </InnerStickyBarContainer>
        </StickyBarContainer>
    );
};
