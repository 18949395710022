import { useMapTracking } from '@domains/search/hooks/useMapTracking';
import { NexusListingSorting } from '@domains/shared/components/ListingSorting/NexusListingSorting';
import { ViewTypeContext } from '@domains/shared/contexts/ViewTypeContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { Badge } from '@nexus/lib-react/dist/core/Badge';
import FilterIcon from '@nexus/lib-react/dist/core/Icon/icons/default/Filter';
import MapViewIcon from '@nexus/lib-react/dist/core/Icon/icons/default/MapView';
import SortByIcon from '@nexus/lib-react/dist/core/Icon/icons/default/SortBy';
import type { SortingOption } from '@type/sorting/option';
import { SearchActionButtons } from '@widgets/search/SearchActionButtons';
import type { JSX } from 'react';
import { useContext, useState } from 'react';

interface Props {
    selectedFiltersNumber: number;
    sortingOption: SortingOption;
    onFiltersOpen(): void;
}

export const NexusMobileListingButtons = ({
    selectedFiltersNumber,
    sortingOption,
    onFiltersOpen,
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const { updateViewType } = useContext(ViewTypeContext);

    const { trackViewChange } = useMapTracking();

    const [isSortingModalVisible, setIsSortingModalVisible] = useState(false);

    const handleMapViewButtonClick = (): void => {
        updateViewType('map');

        trackViewChange({
            listing_format: 'map',
            touch_point_button: 'map_sticky_bar',
            touch_point_page: 'map',
        });
    };

    const showSortingModal = (): void => {
        setIsSortingModalVisible(true);
    };

    const hideSortingModal = (): void => {
        setIsSortingModalVisible(false);
    };

    return (
        <>
            <SearchActionButtons
                actions={[
                    {
                        icon: FilterIcon,
                        text: t('frontend.search.listing.filters-button'),
                        suffix: selectedFiltersNumber > 0 ? <Badge size="small" value={selectedFiltersNumber} /> : null,
                        dataCy: 'search.mobile.search.open',
                        onClick: onFiltersOpen,
                    },
                    {
                        icon: MapViewIcon,
                        text: t('frontend.search.form.map-button'),
                        onClick: handleMapViewButtonClick,
                    },
                    {
                        icon: SortByIcon,
                        text: t('frontend.search.mobile-listing-buttons.sort'),
                        onClick: showSortingModal,
                    },
                ]}
            />

            {isSortingModalVisible ? (
                <NexusListingSorting
                    sortingOption={sortingOption}
                    onCloseModalClick={hideSortingModal}
                    onSort={hideSortingModal}
                    shouldDisplayAsModal
                />
            ) : null}
        </>
    );
};
