import { AddToFavoritesWrapper } from '@domains/search/components/AddToFavoritesWrapper/AddToFavoritesWrapper';
import type { AdvertListItem } from '@domains/search/types/advertListItem';
import { generateAdvertLink } from '@domains/shared/helpers/generateAdvertLink';
import { useIntersection } from '@domains/shared/hooks/useIntersection/useIntersection';
import type { SetSimilarAdsPromptId } from '@type/favorites/similarAdsPromptId';
import { ESTATE } from '@type/search/filters/estate';
import { Anchor } from '@widgets/AdvertCard/Navigation';
import { Price } from '@widgets/AdvertCard/Price';
import { SpecsList } from '@widgets/AdvertCard/SpecsList';
import type { JSX, ReactNode } from 'react';
import { useRef } from 'react';

import {
    HiddenTitle,
    InfoBlock,
    InfoBlockTopContainer,
    StyledImage,
    StyledList,
    StyledWrapper,
} from './MiniAdvertsGrid.theme';

interface Props {
    adverts: AdvertListItem[];
    isNearbySection?: boolean;
    onAdvertClick: (advertId: number, isOnContextMenu?: boolean) => void;
    onAdvertImpression: (advertId: number) => void;
    setSimilarAdsPromptId?: SetSimilarAdsPromptId;
}

export const MiniAdvertsGrid = ({
    adverts,
    isNearbySection = false,
    onAdvertClick,
    onAdvertImpression,
    setSimilarAdsPromptId,
}: Props): JSX.Element => {
    return (
        <StyledList isOneColumn={adverts.length === 1}>
            {adverts.map((ad) => {
                const fullAdvertLink = generateAdvertLink({
                    isInvestment: ad.estate === ESTATE.investment,
                    slug: ad.slug,
                });

                const isForRent = ad.transaction === 'RENT';

                return (
                    <ConnectedListingItem
                        key={ad.id}
                        id={ad.id}
                        onImpression={onAdvertImpression}
                        onAdvertClick={onAdvertClick}
                    >
                        <StyledWrapper>
                            <StyledImage src={ad.images[0].medium} alt="" />
                            <InfoBlock>
                                <Anchor href={fullAdvertLink}>
                                    <HiddenTitle>{ad.title}</HiddenTitle>
                                </Anchor>
                                <InfoBlockTopContainer>
                                    <Price total={ad.totalPrice} direction="vertical" isHidden={!!ad.hidePrice} />
                                    <AddToFavoritesWrapper
                                        id={ad.id}
                                        isPromoted={!!ad.isPromoted}
                                        isNearbySection={isNearbySection}
                                        shouldUseNexusTheme
                                        setSimilarAdsPromptId={setSimilarAdsPromptId}
                                    />
                                </InfoBlockTopContainer>
                                <SpecsList
                                    rooms={ad.roomsNumber}
                                    area={ad.areaInSquareMeters}
                                    floor={ad.floorNumber}
                                    pricePerMeter={isForRent ? null : ad.pricePerSquareMeter}
                                    isCompact
                                />
                            </InfoBlock>
                        </StyledWrapper>
                    </ConnectedListingItem>
                );
            })}
        </StyledList>
    );
};

const ConnectedListingItem = ({
    children,
    id,
    onAdvertClick,
    onImpression,
}: {
    children: ReactNode;
    id: number;
    onAdvertClick: (advertId: number, isOnContextMenu?: boolean) => void;
    onImpression: (id: number) => void;
}): JSX.Element => {
    const containerRef = useRef<HTMLLIElement | null>(null);
    const isImpressionTrackedRef = useRef(false);

    useIntersection(containerRef, (_, observer) => {
        if (!containerRef.current || !observer) {
            return;
        }

        if (!isImpressionTrackedRef.current) {
            onImpression(id);
            isImpressionTrackedRef.current = true;
        }

        observer.unobserve(containerRef.current);
        observer.disconnect();
    });

    return (
        <li
            ref={containerRef}
            onClick={(): void => onAdvertClick(id)}
            onContextMenu={(): void => onAdvertClick(id, true)}
        >
            {children}
        </li>
    );
};
