import type { SortingOption } from '@type/sorting/option';
import type { JSX } from 'react';
import { useEffect, useRef, useState } from 'react';

import { NexusMobileListingButtons } from '../NexusMobileListingButtons/NexusMobileListingButtons';
import { StickyHeaderWrapper, Wrapper } from './StickyMobileHeader.theme';

interface Props {
    selectedFiltersNumber: number;
    sortingOption: SortingOption;
    onFiltersOpen(): void;
}

export const StickyMobileHeader = ({ selectedFiltersNumber, sortingOption, onFiltersOpen }: Props): JSX.Element => {
    const breakPointRef = useRef<HTMLDivElement>(null);
    const [stickyStartPoint, setStickyStartPoint] = useState<number | null>(null);
    const [isScrolled, setIsScrolled] = useState<boolean>(false);

    useEffect(() => {
        window.requestAnimationFrame(() => {
            if (breakPointRef.current) {
                const { top } = breakPointRef.current.getBoundingClientRect();
                setStickyStartPoint(top + window.scrollY);
            }
        });
    }, []);

    useEffect(() => {
        if (stickyStartPoint === null) {
            return;
        }

        const handleScroll = (): void => {
            setIsScrolled(window.scrollY > stickyStartPoint);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return (): void => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [stickyStartPoint]);

    return (
        <Wrapper>
            <div ref={breakPointRef}></div>
            {isScrolled ? (
                <StickyHeaderWrapper>
                    <NexusMobileListingButtons
                        selectedFiltersNumber={selectedFiltersNumber}
                        sortingOption={sortingOption}
                        onFiltersOpen={onFiltersOpen}
                    />
                </StickyHeaderWrapper>
            ) : null}
        </Wrapper>
    );
};
