import { repeatItem } from '@domains/shared/helpers/repeatItem';

import type { Image } from '../types/data';

export const createBufferedImageUrls = (
    images: Image[],
    totalPossibleImages: number,
    imageSize: 'medium' | 'large' = 'medium',
): (string | number)[] => {
    return [
        ...images.map((image) => image[imageSize]),
        ...repeatItem(totalPossibleImages - images.length, (id) => id + 1),
    ];
};
