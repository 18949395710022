import type { SearchAdvertsRandomInvestments } from '@domains/search/types/investment';
import type { Lang } from '@domains/search/types/lang';
import type { Page } from '@domains/search/types/page';
import type { SearchAdverts, SearchAdvertsPromoted } from '@domains/search/types/searchAdverts';
import type { SearchMapBoundingBoxData } from '@domains/search/types/SearchMapPins';
import type { AbstractGraphQLError, GenericGraphQLError } from '@lib/graphql/error';
import type { FilterLocations } from '@type/location/filterLocation';
import type { SearchLinksRelatedLocations } from '@type/location/locationLink';
import type { SearchingFilters } from '@type/search/searchingFilters';
import type { SortingOption } from '@type/sorting/option';

export interface ErrorInvalidSearchFilters extends AbstractGraphQLError {
    __typename: 'ErrorInvalidSearchFilters';
}

export interface SearchServerResponse {
    searchAds: SearchAdverts | ErrorInvalidSearchFilters | GenericGraphQLError;
    searchAdsRandomPromoted?: SearchAdvertsPromoted | ErrorInvalidSearchFilters | GenericGraphQLError;
    searchAdsRandomInvestments: SearchAdvertsRandomInvestments | ErrorInvalidSearchFilters | GenericGraphQLError;
    searchLinksRelatedLocations: SearchLinksRelatedLocations | ErrorInvalidSearchFilters | GenericGraphQLError;
    searchMapPins?: SearchMapBoundingBoxData | ErrorInvalidSearchFilters | GenericGraphQLError;
}

export interface PopularSearchVariables {
    input: {
        transactionType: string;
        estateType: string;
        location: string;
        page?: number;
    };
}

export interface PopularSearchResponse {
    popularSearch: {
        data: string[];
        __typename: 'PopularSearch';
    };
}

export interface Search {
    searchAds: SearchAdverts;
    searchAdsRandomPromoted: SearchAdvertsPromoted | null;
    searchAdsRandomInvestments: SearchAdvertsRandomInvestments | null;
    searchLinksRelatedLocations: SearchLinksRelatedLocations | null;
    searchMapPins: SearchMapBoundingBoxData | null;
}

export interface SearchAdsVariables {
    lang: Lang;
    page: Page;
    filterAttributes?: Partial<Omit<SearchingFilters, 'locations' | 'page' | 'limit'>>;
    filterLocations?: FilterLocations<string | string[] | { id: unknown }[]>;
    sort?: SortingOption;
    shouldExcludeRandomPromotedAds: boolean;
    shouldFetchSearchMapPins: boolean;
    fetchAdsDetails?: boolean;
}

export const SEARCH_PAGE_EVENT = {
    saveSearch: 'saveSearch',
} as const;

export interface SaveSearchEventData {
    trackingPoint: 'search_form' | 'sticky_bar';
}
