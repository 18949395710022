import type { SchemaMarkupData } from '@config/schemaMarkup/type';
import { getPrimaryImageOfPage } from '@domains/search/helpers/getPrimaryImageOfPage';
import { shouldGenerateSchemaMarkup } from '@domains/search/helpers/shouldGenerateSchemaMarkup';
import type { AdvertListItem } from '@domains/search/types/advertListItem';
import type { Breadcrumb } from '@domains/shared/components/Breadcrumbs/types';
import { generateSchemaMarkup } from '@domains/shared/helpers/schemaMarkup/generateSchemaMarkup';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { Locale } from '@lib/i18n/types/locale';
import type { Estate } from '@type/search/filters/estate';
import type { Market } from '@type/search/filters/market';
import type { Transaction } from '@type/search/filters/transaction';
import type { LocationsObjects } from '@type/search/location/dataItems';
import type { SearchingFilters } from '@type/search/searchingFilters';
import { buildSearchUrl } from '@widgets/search/buildSearchUrl';
import { useMemo } from 'react';

interface Props {
    transaction: Transaction;
    estate: Estate;
    market: Market;
    lang: Locale;
    location: string | null;
    locationsObjects: LocationsObjects[] | null;
    pageTitle: string;
    pageDescription: string;
    pageHeading: string | null;
    filterAttributes: Partial<Omit<SearchingFilters, 'locations' | 'page' | 'limit'>>;
    filteringQueryParams: Partial<SearchingFilters> | undefined;
    adverts: AdvertListItem[];
    breadcrumbItems?: Breadcrumb[] | null;
}

export const useGetSeoSchemaMarkup = ({
    transaction,
    estate,
    market,
    lang,
    location,
    locationsObjects,
    pageTitle,
    pageDescription,
    pageHeading,
    filterAttributes,
    filteringQueryParams,
    adverts,
    breadcrumbItems,
}: Props): SchemaMarkupData | null => {
    const [t] = useTranslations();

    const isSchemaMarkupAvailable = useMemo(
        () =>
            shouldGenerateSchemaMarkup({
                filterAttributes: {
                    ...filterAttributes,
                    page: filteringQueryParams?.page,
                },
                locationsObjects,
            }),
        [filterAttributes, filteringQueryParams?.page, locationsObjects],
    );

    return useMemo<SchemaMarkupData | null>(() => {
        const schemaUrlPath = buildSearchUrl({
            lang,
            transaction,
            estate,
            location,
            market,
            isInternalRoute: false,
        });

        return isSchemaMarkupAvailable
            ? generateSchemaMarkup({
                  pageSchemaData: {
                      name: pageTitle,
                      description: pageDescription,
                      urlPath: schemaUrlPath,
                      headline: pageHeading,
                      primaryImageOfPage: getPrimaryImageOfPage(adverts),
                      isPartOfDescription: t('frontend.shared.schema-markup.is-part-of.description'),
                      lang,
                  },
                  productSchemaData: {
                      businessFunction: transaction,
                      items: adverts,
                      urlPath: schemaUrlPath,
                  },
                  breadcrumbSchemaData: breadcrumbItems,
              })
            : null;
    }, [
        breadcrumbItems,
        adverts,
        estate,
        isSchemaMarkupAvailable,
        lang,
        location,
        market,
        pageDescription,
        pageHeading,
        pageTitle,
        transaction,
        t,
    ]);
};
