import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const StickyHeaderWrapper = styled.div`
    position: fixed;
    z-index: 10;
    inset: 0 0 auto;
    padding: ${theme.space[16]};
    border-bottom: 1px solid ${theme.colors['borders-global-tertiary']};
    background: ${theme.colors['background-global-primary']};
`;

export const Wrapper = styled.div`
    @media ${theme.mediaQueries.lgMin} {
        display: none;
    }
`;
