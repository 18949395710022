import { StickyHeader } from '@domains/search/components/StickyHeader/StickyHeader';
import { Desktop } from '@domains/shared/components/Responsive/Desktop';
import { useIntersection } from '@domains/shared/hooks/useIntersection/useIntersection';
import type { FieldsMetadataExperimentsVariants } from '@type/search/fieldsMetadataExperimentsVariants';
import type { SortingOption } from '@type/sorting/option';
import dynamic from 'next/dynamic';
import type { JSX } from 'react';
import { useRef, useState } from 'react';

import { SearchFormSkeleton } from './SearchForm.skeleton';
import { FormBackground, FormContainer } from './SearchForm.theme';

const LazyDesktopSearchFormUniversal = dynamic(
    () => import('@domains/search/components/SearchFormUniversal/SearchFormUniversal'),
    {
        loading: () => <SearchFormSkeleton />,
        ssr: false,
    },
);

interface Props {
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants;
    sortingOption: SortingOption;
    pageTitle: string;
    totalResults: number;
    isMapView?: boolean;
}

export const SearchForm = ({
    fieldsMetadataExperimentsVariants,
    sortingOption,
    pageTitle,
    totalResults,
    isMapView,
}: Props): JSX.Element => {
    const stickyHeaderBreakpointRef = useRef<HTMLDivElement | null>(null);
    const [shouldDisplayStickyHeader, setShouldDisplayStickyHeader] = useState(false);

    useIntersection(
        stickyHeaderBreakpointRef,
        (entry) => {
            if (!entry) {
                return;
            }

            const { boundingClientRect, isIntersecting } = entry;
            setShouldDisplayStickyHeader(!isIntersecting && boundingClientRect.bottom <= 0);
        },
        { shouldRunOnIntersectionOnly: false },
    );

    return (
        <>
            <FormBackground data-cy="search-form-background">
                <FormContainer>
                    <LazyDesktopSearchFormUniversal
                        fieldsMetadataExperimentsVariants={fieldsMetadataExperimentsVariants}
                        sortingOption={sortingOption}
                        pageTitle={pageTitle}
                    />
                </FormContainer>
            </FormBackground>

            <div ref={stickyHeaderBreakpointRef} />

            {shouldDisplayStickyHeader && !isMapView ? (
                <Desktop>
                    <StickyHeader
                        fieldsMetadataExperimentsVariants={fieldsMetadataExperimentsVariants}
                        pageTitle={pageTitle}
                        totalResults={totalResults}
                    />
                </Desktop>
            ) : null}
        </>
    );
};
