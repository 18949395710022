import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';

import { InvestmentLink, StyledParagraph } from './InvestmentInfoBar.theme';

interface Props {
    developmentId: number;
    developmentTitle: string;
    developmentUrl: string;
    unitsCount: number;
}

export const InvestmentInfoBar = ({
    developmentId,
    developmentTitle,
    developmentUrl,
    unitsCount,
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();

    return (
        <StyledParagraph>
            <span>
                {unitsCount === 1
                    ? t('frontend.global.ad.property-part-of-investment')
                    : t('frontend.global.ad.property-part-of-investment-plural')}{' '}
            </span>

            <LocalLink href={developmentUrl} passHref prefetch={false}>
                <InvestmentLink
                    onClick={(): void => {
                        trackEvent('parent_investment_link_click', {
                            touch_point_button: 'combined_ads_list',
                            params_ad_id: developmentId,
                        });
                    }}
                >
                    {developmentTitle}
                </InvestmentLink>
            </LocalLink>
        </StyledParagraph>
    );
};
