import { SearchFormValuesContext } from '@domains/shared/contexts/SearchFormValuesContext';
import { getTransactionVariant } from '@domains/shared/helpers/getTransactionVariant';
import { useContext } from 'react';

export const useInvestmentsTrackingSet = (): Record<string, unknown> | null => {
    const { estate, transaction, filteringQueryParams } = useContext(SearchFormValuesContext);

    if (!estate || !transaction) {
        return null;
    }

    const transactionVariant = getTransactionVariant({ estate, transaction });

    if (transactionVariant !== 'INVESTMENT') {
        return null;
    }

    return {
        developer_name: filteringQueryParams?.developerName ?? null,
        investment_name: filteringQueryParams?.investmentName ?? null,
        investment_status: filteringQueryParams?.investmentState ?? null,
    };
};
