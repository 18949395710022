import { getEqualColumns } from '@domains/search/helpers/styles/getEqualColumns';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { H1 } from '@nexus/lib-react/dist/core/Typography';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${theme.space[8]};
`;

export const InfoContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    gap: ${theme.space[16]};

    @media ${`${theme.mediaQueries.mdMin} and ${theme.mediaQueries.lg}`} {
        grid-template-columns: ${getEqualColumns(2)};
    }
`;

export const ListingInfo = styled.div`
    display: flex;
    flex: 1;
    align-items: start;
`;

export const ListingTextInfoWrapper = styled.div`
    margin-right: auto;
`;

export const AdditionalInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${theme.space[4]};

    @media ${theme.mediaQueries.lgMin} {
        flex-direction: row;
        gap: ${theme.space[16]};
        margin-top: ${theme.space[8]};
    }
`;

export const Heading = styled(H1)`
    color: ${theme.colors['text-brand-primary']};
    font-size: ${theme.fontSizes[24]};
    letter-spacing: 0;
    line-height: ${theme.lineHeights[26]};
`;

export const SortByWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: ${theme.space[16]};
    gap: ${theme.space[8]};
`;

export const AdsCountInfo = styled.div`
    color: ${theme.colors['text-global-secondary']};
    font-size: ${theme.fontSizes[16]};
`;

export const SortingInfoLink = styled.a`
    display: block;
    color: ${theme.colors['text-global-secondary']};
    font-size: ${theme.fontSizes[16]};
`;

export const SortLabel = styled.div`
    margin-bottom: ${theme.space[4]};
    color: ${theme.colors['text-global-secondary']};
    font-size: ${theme.fontSizes[16]};
`;
