import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { JSX } from 'react';

import { Container, Title } from './RedirectedFromInactiveAdMessage.theme';

export const RedirectedFromInactiveAdMessage = (): JSX.Element => {
    const [t] = useTranslations();

    return (
        <Container data-cy="redirectedFromInactiveAd">
            <Title>{t('frontend.search.ad-is-not-available-longer')}</Title>
            <strong>{t('frontend.search.ad-is-not-available-longer-subtitle')}</strong>
        </Container>
    );
};
