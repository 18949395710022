import { SaveSearchButton } from '@domains/search/components/SaveSearchResults/SaveSearchButton';
import { SaveSearchClicktip } from '@domains/search/components/SaveSearchResults/SaveSearchClicktip';
import type { ModalVariant } from '@domains/search/components/SaveSearchResults/SaveSearchModal';
import { SaveSearchModal } from '@domains/search/components/SaveSearchResults/SaveSearchModal';
import { SAVE_SEARCH_PARAMETER } from '@domains/search/components/SaveSearchResults/SaveSearchResults';
import { useSaveSearchEffects } from '@domains/search/hooks/useSaveSearchEffects';
import { useSaveSearches } from '@domains/search/hooks/useSaveSearches/useSaveSearches';
import { SEARCH_STATUS } from '@domains/search/types/searchStatus';
import type { Props as ButtonProps } from '@domains/shared/components/Button/Button';
import { useLazyUser } from '@lib/pages/contexts/LazyUserContext/useLazyUser';
import type { FieldsMetadataExperimentsVariants } from '@type/search/fieldsMetadataExperimentsVariants';
import type { JSX } from 'react';
import { useCallback, useEffect, useState } from 'react';

interface Props {
    fieldsMetadataExperimentsVariants: FieldsMetadataExperimentsVariants;
    size?: ButtonProps['size'];
    trackingData?: Record<string, unknown>;
    shouldShowClicktip?: boolean;
    shouldUseNexusTheme?: boolean;
    fullWidth?: boolean;
}

const TRACKING_DATA_FOR_SOCIAL_LOGINS = {
    touch_point_button: 'favourite_search_save',
} as const;

export const SaveSearchConnectedButton = ({
    fieldsMetadataExperimentsVariants,
    size,
    trackingData = {},
    shouldShowClicktip = true,
    shouldUseNexusTheme = false,
    fullWidth = false,
}: Props): JSX.Element => {
    const { saveCurrentSearch, searchStatus, searchURL } = useSaveSearches({ fieldsMetadataExperimentsVariants });
    const { isAwaitingUserData, isUserLoggedIn } = useLazyUser();
    const [currentModal, setCurrentModal] = useState<ModalVariant>(null);
    const { triggerSuccessEffects, triggerErrorEffects, triggerSaveAttemptEffects } = useSaveSearchEffects({
        trackingExtraData: {
            touch_point_button: 'floating_button',
            ...trackingData,
        },
    });
    const [isSaveSearchButtonClicked, setIsSaveSearchButtonClicked] = useState(false);

    const saveSearchQuery = useCallback((): void => {
        saveCurrentSearch()
            .then(() => triggerSuccessEffects())
            .catch(triggerErrorEffects);
    }, [saveCurrentSearch, triggerErrorEffects, triggerSuccessEffects]);

    const handleSaveSearch = (): void => {
        setIsSaveSearchButtonClicked(true);
        triggerSaveAttemptEffects();
        if (isUserLoggedIn) {
            saveSearchQuery();
        } else {
            setCurrentModal('NotLoggedInModal');
        }
    };

    const handleHashChangeEvent = useCallback((): void => {
        if (searchStatus !== SEARCH_STATUS.notSaved) {
            return;
        }

        if (typeof window !== 'undefined' && window.location.hash.includes(SAVE_SEARCH_PARAMETER)) {
            saveSearchQuery();
            window.removeEventListener('hashchange', handleHashChangeEvent, false);
            window.location.hash = '';
        }
    }, [saveSearchQuery, searchStatus]);

    useEffect(() => {
        /* this call is needed to subscribe after page gets reloaded during email login */
        handleHashChangeEvent();

        window.addEventListener('hashchange', handleHashChangeEvent, false);

        /* listener should be removed when user leaves the page */
        return (): void => window.removeEventListener('hashchange', handleHashChangeEvent, false);
    }, [handleHashChangeEvent]);

    const saveSearchButton = (
        <SaveSearchButton
            shouldUseNexusTheme={shouldUseNexusTheme}
            onClick={handleSaveSearch}
            status={isAwaitingUserData ? 'BLOCKED' : searchStatus}
            size={size}
            fullWidth={fullWidth}
        />
    );

    return (
        <>
            {shouldShowClicktip ? (
                <SaveSearchClicktip
                    button={saveSearchButton}
                    xShift={110}
                    isButtonClicked={isSaveSearchButtonClicked}
                    fullWidth={fullWidth}
                />
            ) : (
                saveSearchButton
            )}

            <SaveSearchModal
                currentModal={currentModal}
                afterLoginRedirectPath={`${searchURL}${SAVE_SEARCH_PARAMETER}`}
                onClose={(): void => setCurrentModal(null)}
                onRegisterModalOpen={(): void => setCurrentModal('RegistrationModal')}
                extraTrackingData={TRACKING_DATA_FOR_SOCIAL_LOGINS}
                fieldsMetadataExperimentsVariants={fieldsMetadataExperimentsVariants}
            />
        </>
    );
};
