import { Modal } from '@domains/shared/components/Modal/Modal';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';

import { CONTACT_FORM_WIDGET_THEME } from '../theme/[[[SITECODE]]]';

const NARROW_WIDTH = '376px';

export const ContactModalWrapper = styled(Modal)<{ shouldBeNarrow: boolean }>`
    width: 100%;
    height: 100%;
    max-height: 100%;
    background-color: ${CONTACT_FORM_WIDGET_THEME.contactFormWrapper.mobileModal.backgroundColor};

    @media (min-width: ${BREAKPOINT.md}) {
        max-width: ${({ shouldBeNarrow }): string => (shouldBeNarrow ? NARROW_WIDTH : '100%')};
        height: auto;
        max-height: auto;
    }
`;
