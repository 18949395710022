import { NexusDropdown } from '@domains/shared/components/Dropdown/NexusDropdown';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { LAYER } from '@lib/styles/partials/layers';
import { theme } from '@lib/styles/themes/nexus';
import { Pagination as NexusPagination } from '@nexus/lib-react/dist/core/Pagination';
import type { CSSProperties } from 'react';

export const Container = styled.div`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    @media (min-width: ${BREAKPOINT.md}) {
        flex-direction: row;
    }
`;

export const PageSizeContainer = styled.div`
    display: none;

    @media (min-width: ${BREAKPOINT.md}) {
        display: flex;
        position: relative;
        z-index: ${LAYER.x1};
        align-items: center;
        justify-content: center;
        margin-left: auto;
    }
`;

export const PageSizeLabel = styled.p`
    margin: 0;
    font-size: ${theme.fontSizes[16]};
    line-height: ${theme.lineHeights[20]};
`;

export const PageSizeDropdown = styled(NexusDropdown)`
    width: 90px;
    margin-left: ${theme.space[8]};
` as typeof NexusDropdown; // `as` - styled() is overriding default type of the generic component

export const StyledPagination = styled(NexusPagination)<{ disabled: boolean }>`
    & > li {
        pointer-events: ${({ disabled }): CSSProperties['pointerEvents'] => (disabled ? 'none' : 'unset')};
    }
`;
