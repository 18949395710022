export const getSeeButtonTrackEventTouchPointButton = (
    isPromotedSection: boolean,
    isNearbySection: boolean,
): string => {
    if (isPromotedSection) {
        return 'promoted_ads_see_button';
    }

    if (isNearbySection) {
        return 'nearby_ads_see_button';
    }

    return 'ads_see_button';
};
