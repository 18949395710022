import {
    DarkBlueTitleButton,
    IconBell,
    IconChecked,
} from '@domains/search/components/SaveSearchResults/SaveSearchResults.theme';
import type { SearchStatus } from '@domains/search/types/searchStatus';
import { SEARCH_STATUS } from '@domains/search/types/searchStatus';
import type { Props as ButtonProps } from '@domains/shared/components/Button/Button';
import { ButtonVariant } from '@domains/shared/components/Button/buttonVariant';
import { logError } from '@domains/shared/helpers/logger';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import NotificationIcon from '@nexus/lib-react/dist/core/Icon/icons/default/NotificationDefault';
import TickIcon from '@nexus/lib-react/dist/core/Icon/icons/default/Tick';
import type { JSX } from 'react';

import { StyledButton } from './SaveSearchButton.theme';

interface SaveSearchButtonProps {
    status: SearchStatus;
    onClick: () => void;
    size?: ButtonProps['size'];
    shouldUseNexusTheme?: boolean;
    fullWidth?: boolean;
}

export const SaveSearchButton = ({
    status,
    onClick,
    size = 'medium',
    shouldUseNexusTheme = false,
    fullWidth = false,
}: SaveSearchButtonProps): JSX.Element | null => {
    const [t] = useTranslations();

    if (!Object.values(SEARCH_STATUS).includes(status)) {
        logError('Unknown search status provided to SaveSearchButton');

        return null;
    }

    const isSaved = status === 'SAVED';
    const isClickable = status === 'NOT_SAVED' || status === 'SAVING';
    const isDisabled = status !== 'NOT_SAVED';

    const buttonText = isSaved
        ? t('frontend.search.form.save-search-results.title.saved')
        : t('frontend.search.form.save-search-results.title');

    if (shouldUseNexusTheme) {
        const buttonSize = size === 'small' ? 'small' : undefined;

        return (
            <StyledButton
                size={buttonSize}
                disabled={isDisabled}
                prefixIcon={isSaved ? TickIcon : NotificationIcon}
                variant="secondary"
                data-cy="search.form.save-search.button"
                data-saved={isSaved}
                onClick={onClick}
                fullWidth={fullWidth}
            >
                {buttonText}
            </StyledButton>
        );
    }

    const Icon = isSaved ? IconChecked : IconBell;

    return (
        <DarkBlueTitleButton
            data-cy="search.form.save-search.button"
            data-saved={isSaved}
            disabled={isDisabled}
            isClickable={isClickable}
            onClick={onClick}
            size={size}
            type="button"
            variant={ButtonVariant.Secondary}
        >
            <Icon />
            {buttonText}
        </DarkBlueTitleButton>
    );
};
