import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const TooltipTrigger = styled.button`
    padding: 0;
    border: 0;
    background: none;
    color: inherit;
    font-size: ${theme.fontSizes.p5};
`;
