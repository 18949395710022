import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { AdvertAgencyType } from '@type/ad/advertAgency';
import type { AdvertUserType } from '@type/user/userType';
import type { JSX, ReactNode } from 'react';

import { AsideInfoContainer, Container, ImageWrapper, OwnerName, OwnerType, TextWrapper } from './SellerInfo.theme';

export interface SellerInfoProps {
    name?: string;
    type?: AdvertAgencyType | AdvertUserType;
    image: {
        url?: string;
        isVisible?: boolean;
        isSmall?: boolean;
    };
    moreInfo?: ReactNode;
    asideInfo?: ReactNode;
    isPrivate: boolean;
}

export const SellerInfo = ({ name, type, image, moreInfo, asideInfo, isPrivate }: SellerInfoProps): JSX.Element => {
    const isDeveloper = type === 'DEVELOPER';
    const imageAlt = name ?? '';

    return (
        <Container>
            {image.url && image.isVisible ? (
                <ImageWrapper src={image.url} alt={imageAlt} isSmall={image.isSmall} loading="lazy" />
            ) : null}

            <TextWrapper>
                {name && !isPrivate ? <OwnerName data-testid="listing-item-owner-name">{name}</OwnerName> : null}
                <OwnerTypeWrapper isDeveloper={isDeveloper} isPrivate={isPrivate} />
                {moreInfo}
            </TextWrapper>

            {asideInfo ? <AsideInfoContainer>{asideInfo}</AsideInfoContainer> : null}
        </Container>
    );
};

const OwnerTypeWrapper = ({ isPrivate, isDeveloper }: { isPrivate: boolean; isDeveloper: boolean }): JSX.Element => {
    const [t] = useTranslations();

    if (isPrivate) {
        return <div>{t('frontend.search.item.private-offer')}</div>;
    }

    if (isDeveloper) {
        return <OwnerType>{t('frontend.search.item.estate-developer')}</OwnerType>;
    }

    return <OwnerType>{t('frontend.search.item.estate-agency')}</OwnerType>;
};
