import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import styled from '@emotion/styled';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { LAYER } from '@lib/styles/partials/layers';
import { LINE_HEIGHT, SIZE } from '@lib/styles/partials/typography';

import type { StyleVariant } from './types/styleVariant';

export const TooltipWrapper = styled.span`
    display: inline-block;
    position: relative;
    top: 2px;
    margin-left: 4px;
`;

const StyledButton = styled.button<{ iconVariant: StyleVariant }>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    outline: transparent;
    background: none;
    color: ${({ theme, iconVariant }): string => theme.deprecated.domains.shared.clicktip.button[iconVariant].color};
    font-size: ${SIZE.p3};
    cursor: pointer;

    &:hover > svg {
        color: ${({ theme, iconVariant }): string =>
            theme.deprecated.domains.shared.clicktip.icon[iconVariant].hover.color};
    }

    &:focus-visible {
        border: 1px solid
            ${({ theme, iconVariant }): string =>
                theme.deprecated.domains.shared.clicktip.button[iconVariant].focus.borderColor};
        border-radius: 2px;

        > svg {
            color: ${({ theme, iconVariant }): string =>
                theme.deprecated.domains.shared.clicktip.icon[iconVariant].focus.color};
        }
    }
`;

export const IconWrapper = styled(StyledButton)`
    width: 16px;
    height: 16px;
`;

export const TooltipBox = styled.span<{ tooltipLeft?: number; tooltipTop?: number; tooltipVariant: StyleVariant }>`
    position: absolute;
    z-index: ${LAYER.x7};
    top: ${({ tooltipTop }): string => `${tooltipTop || '-9'}px`};
    left: ${({ tooltipLeft }): string => `${tooltipLeft}px`};
    padding: 12px 28px 12px 12px;
    transform: translate(0, -100%);
    background-color: ${({ theme, tooltipVariant }): string =>
        theme.deprecated.domains.shared.clicktip.description[tooltipVariant].backgroundColor};
    box-shadow: ${({ theme }): string => theme.deprecated.domains.shared.clicktip.description.boxShadow};
    color: ${({ theme, tooltipVariant }): string =>
        theme.deprecated.domains.shared.clicktip.description[tooltipVariant].color};
    line-height: ${LINE_HEIGHT.x2};
`;

export const StyledIcon = styled(Icon, {
    shouldForwardProp: (propName): boolean => propName === 'icon',
})<{ icon: IconProp; iconVariant: StyleVariant }>`
    width: 14px;
    color: ${({ theme, iconVariant }): string => theme.deprecated.domains.shared.clicktip.icon[iconVariant].color};
`;

export const CloseButton = styled(StyledButton)`
    position: absolute;
    top: 8px;
    right: 8px;
    width: 14px;
    height: 14px;
`;

export const DescriptionContainer = styled.span`
    display: inline-block;
    width: 250px;
    white-space: normal;
`;

export const TooltipDescriptionArrow = styled.span<{ top?: number; tooltipVariant: StyleVariant }>`
    position: absolute;
    z-index: ${LAYER.x7};
    top: ${({ top }): string => `${top || '-10'}px`};
    left: 50%;
    transform: ${({ top }): string => `translate(-50%, 0) ${top ? 'scaleY(-1)' : ''}`};
    border: 6px solid transparent;
    border-bottom-width: 0;
    border-top-color: ${({ theme, tooltipVariant }): string =>
        theme.deprecated.domains.shared.clicktip.description[tooltipVariant].backgroundColor};
`;
