import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons/faQuestionCircle';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import type { JSX } from 'react';
import { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';

import {
    CloseButton,
    DescriptionContainer,
    IconWrapper,
    StyledIcon,
    TooltipBox,
    TooltipDescriptionArrow,
    TooltipWrapper,
} from './Clicktip.theme';
import type { StyleVariant } from './types/styleVariant';

interface ClicktipProps {
    dataCy?: string;
    dataTestId?: string;
    children: string | JSX.Element;
    iconVariant?: StyleVariant;
    tooltipVariant?: StyleVariant;
    className?: string;
    tooltipLeft?: number;
    shouldOpenOnStart?: boolean;
    ariaLabel?: string;
    targetElement?: 'TOOLTIP_ICON' | JSX.Element;
    initialPosition?: 'top' | 'bottom';
    offsetY?: number;
    onHide?: () => void;
    onShow?: () => void;
    shouldHideOnMobile?: boolean;
    didUserInteractWithTarget?: boolean;
}

export const Clicktip = ({
    dataCy,
    dataTestId,
    children,
    iconVariant = 'dark',
    tooltipVariant = 'light',
    className,
    tooltipLeft = -250,
    shouldOpenOnStart = false,
    ariaLabel = 'frontend.shared.clicktip.aria-label',
    targetElement = 'TOOLTIP_ICON',
    initialPosition = 'top',
    offsetY = 5,
    onHide,
    onShow,
    shouldHideOnMobile = false,
    didUserInteractWithTarget = false,
}: ClicktipProps): JSX.Element => {
    const [t] = useTranslations();
    const { isDesktop } = useContext(RWDContext);
    const [isTooltipOpen, setIsTooltipOpen] = useState(shouldOpenOnStart);

    const tooltipRef = useRef<HTMLSpanElement>(null);
    const tooltipArrowRef = useRef<HTMLSpanElement>(null);
    const tooltipTargetRef = useRef<HTMLSpanElement>(null);

    const [tooltipTop, setTooltipTop] = useState<number>();
    const [tooltipArrowTop, setTooltipArrowTop] = useState<number>();

    const targetRect = tooltipTargetRef.current && tooltipTargetRef.current?.getBoundingClientRect();

    const toggleTooltip = useCallback(() => {
        if (isTooltipOpen && onHide) {
            onHide();
        }
        if (!isTooltipOpen && onShow) {
            onShow();
        }
        setIsTooltipOpen((isOpen) => !isOpen);
    }, [isTooltipOpen, onShow, onHide]);

    useLayoutEffect(() => {
        const tooltipRect = tooltipRef.current && tooltipRef.current.getBoundingClientRect();

        if (!tooltipRect) {
            return;
        }

        const { top, height } = tooltipRect;

        if (top >= 0 && initialPosition === 'top') {
            return;
        }

        // tooltip is not fully visible
        const tooltipArrowRect = tooltipArrowRef.current && tooltipArrowRef.current.getBoundingClientRect();
        const tooltipTargetRect = tooltipTargetRef.current && tooltipTargetRef.current.getBoundingClientRect();

        setTooltipTop(
            Math.floor(height + (tooltipArrowRect?.height || 0) + offsetY + (tooltipTargetRect?.height || 0)),
        );
        setTooltipArrowTop(offsetY + (tooltipTargetRect?.height || 0));
    }, [isTooltipOpen, initialPosition, offsetY, targetRect]);

    useEffect(() => {
        if (shouldOpenOnStart && onShow) {
            onShow();
        }
    }, [shouldOpenOnStart, onShow]);

    // Close the tooltip when user interacts with the target element.
    useEffect(() => {
        if (isTooltipOpen && onHide && didUserInteractWithTarget) {
            onHide();
            setIsTooltipOpen(false);
        }
    }, [isTooltipOpen, onHide, didUserInteractWithTarget]);

    const shouldShowTooltipBox = isTooltipOpen && !(shouldHideOnMobile && !isDesktop);

    return (
        <TooltipWrapper className={className} data-cy={dataCy} data-testid={dataTestId}>
            <span ref={tooltipTargetRef}>
                {targetElement === 'TOOLTIP_ICON' ? (
                    <IconWrapper
                        type="button"
                        aria-label={t(ariaLabel)}
                        onClick={toggleTooltip}
                        iconVariant={iconVariant}
                    >
                        <StyledIcon icon={faQuestionCircle} iconVariant={iconVariant} />
                    </IconWrapper>
                ) : (
                    targetElement
                )}
            </span>
            {shouldShowTooltipBox ? (
                <span role="tooltip">
                    <TooltipBox
                        tooltipLeft={tooltipLeft}
                        ref={tooltipRef}
                        tooltipTop={tooltipTop}
                        tooltipVariant={tooltipVariant}
                    >
                        <CloseButton
                            type="button"
                            onClick={toggleTooltip}
                            iconVariant={iconVariant}
                            aria-label={t('frontend.global.label.close')}
                            data-testid={`${dataTestId}-close-button`}
                        >
                            <StyledIcon icon={faTimes} iconVariant={iconVariant} />
                        </CloseButton>
                        <DescriptionContainer>{children}</DescriptionContainer>
                    </TooltipBox>
                    <TooltipDescriptionArrow
                        ref={tooltipArrowRef}
                        top={tooltipArrowTop}
                        tooltipVariant={tooltipVariant}
                    />
                </span>
            ) : null}
        </TooltipWrapper>
    );
};
