import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import type { CSSProperties } from 'react';

export const GalleryWrapper = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100%;
`;

export const TagsWrapper = styled.div`
    display: flex;
    position: absolute;
    z-index: 1;
    flex-wrap: wrap;
    width: 100%;
    padding: ${theme.space[8]};
    pointer-events: none;
    gap: ${theme.space[8]};
`;

export const OpenDays = styled.div`
    position: relative;
    width: 100%;
    padding: ${theme.space[4]};
    background-color: ${theme.colors.$05_green_primary};
    color: ${theme.colors.$01_white};
    font-size: ${theme.fontSizes.p3};
    font-weight: ${theme.fontWeights[600]};
    text-align: center;
    pointer-events: none;
`;

export const ImageLoadingSlideWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background-color: ${theme.colors['background-brand-tertiary']};
    color: ${theme.colors['icon-global-disabled']};
`;

export const LoaderWrapper = styled.div`
    display: grid;
    position: absolute;
    inset: 0;
    place-items: center;
`;

export const StyledImage = styled.img`
    width: 100%;
    height: 100%;
`;

export const CarouselWrapper = styled.div<{ shouldSpaceOpenDays?: boolean }>`
    height: ${({ shouldSpaceOpenDays }): CSSProperties['height'] =>
        shouldSpaceOpenDays ? 'calc(100% - 28px)' : '100%'};
`;
