import type { CSSProperties } from '@emotion/serialize';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Header = styled.div<{ isCompareAdsButtonVisible: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${theme.space[16]};
    gap: ${theme.space[4]};

    @media ${theme.mediaQueries.lgMin} {
        margin-top: ${({ isCompareAdsButtonVisible }): CSSProperties['margin'] =>
            isCompareAdsButtonVisible ? `-${theme.space[8]}` : theme.space[0]};
    }
`;

export const SecondaryInfoSection = styled.div`
    margin-top: ${theme.space[4]};
    margin-bottom: ${theme.space[24]};
`;

export const DescriptionWrapper = styled.div`
    margin-bottom: ${theme.space[12]};
    padding: ${theme.space[4]} 0;
`;

export const ActionButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${theme.space['24']};
`;

export const MobileCompareAdsButtonWrapper = styled.div`
    margin-bottom: ${theme.space['8']};
`;
